export const UPDATE_LOCATION_STATE = 'reactv-redux/UPDATE_LOCATION_STATE';
export const CLEAR_LOCATION_STATE = 'reactv-redux/CLEAR_LOCATION_STATE';
export const CLEAR_ALL_LOCATION_STATES =
  'reactv-redux/CLEAR_ALL_LOCATION_STATES';

export function updateMenuState(menuid, state) {
  // deprecated, use MenusReducer reducer
  return {
    type: 'NULL',
    menuid,
    state
  };
}

export function updateLocationState(location, appNavigation, navigationMenu) {
  return {
    type: UPDATE_LOCATION_STATE,
    location,
    appNavigation,
    navigationMenu
  };
}

export function clearLocationState(location) {
  return {
    type: CLEAR_LOCATION_STATE,
    location
  };
}

export function clearAllLocationStates() {
  return { type: CLEAR_ALL_LOCATION_STATES };
}

const initialState = {};

export default function reactvReduxReducer(state = initialState, action) {
  let newstate;
  switch (action.type) {
    case UPDATE_LOCATION_STATE:
      newstate = Object.assign({}, state);
      let current = state[action.location];
      if (typeof current === 'object') {
        newstate[action.location] = {
          appNavigation: Object.assign(
            {},
            current && current.appNavigation,
            action.appNavigation
          ),
          navigationMenu: Object.assign(
            {},
            current && current.navigationMenu,
            action.navigationMenu
          )
        };
      } else {
        newstate[action.location] = {
          appNavigation: action.appNavigation,
          navigationMenu: action.navigationMenu
        };
      }
      return newstate;
    case CLEAR_LOCATION_STATE:
      newstate = Object.assign({}, state);
      newstate[action.location] = null;
      return newstate;
    case CLEAR_ALL_LOCATION_STATES:
      return initialState;
    default:
      return state;
  }
}
