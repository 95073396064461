import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import MainContainer from './components/MainContainer/MainContainer';
import { BASENAME } from './constants';

if (process.env.REACT_APP_PLATFORM === 'enseo') {
  require('react-app-polyfill/stable');
  const smoothscroll = require('smoothscroll-polyfill');
  smoothscroll.polyfill();
}

class App extends Component {
  render() {
    return (
      <Provider store={this.props.store}>
        <BrowserRouter basename={BASENAME}>
          <Route path="/" component={MainContainer} />
        </BrowserRouter>
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired
};

export default App;
