import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Touch } from '../../lib/reactv-touch';

import './BackButton.scss';

class BackButton extends Component {
  constructor(p) {
    super(p);

    this.itemRef = React.createRef();
  }

  componentDidMount() {
    this.touch = new Touch(this.itemRef.current, {
      ontap: () => {
        this.props.history.goBack();
      }
    });
  }

  componentWillUnmount() {
    this.touch && this.touch.unsubscribe();
  }

  render() {
    return (
      <div className="backButton" ref={this.itemRef}>
        <div className="backButton__arrow" />
      </div>
    );
  }
}

export default withRouter(BackButton);
