import React from 'react';
import { s } from '../../lib/screen-size';

const Icon = ({ fill }) => (
  <svg
    width={s(72)}
    height={s(72)}
    viewBox="0 0 72 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mini-Player/LiveRadio"
        transform="translate(-1752.000000, -60.000000)"
      >
        <g
          id="Button/Player/Default"
          transform="translate(1752.000000, 60.000000)"
        >
          <g
            id="Button/Player/Icon/Full-Screen/Default"
            fill={fill ? fill : '#FFFFFF'}
            fillRule="evenodd"
            strokeWidth="1"
          >
            <g id="Group" transform="translate(22.000000, 22.000000)">
              <path
                d="M1.74720242,2.9826611 L1.74720242,10.4832145 L0,10.4832145 L0,0 L1.74720242,0 L10.4832145,9.99200722e-15 L10.4832145,1.74720242 L2.9826611,1.74720242 L11.375,10.1395413 L10.1395413,11.375 L1.74720242,2.9826611 Z"
                id="Combined-Shape"
              />
              <path
                d="M1.74720242,19.6076611 L1.74720242,27.1082145 L0,27.1082145 L0,16.625 L1.74720242,16.625 L10.4832145,16.625 L10.4832145,18.3722024 L2.9826611,18.3722024 L11.375,26.7645413 L10.1395413,28 L1.74720242,19.6076611 Z"
                id="Combined-Shape-Copy"
                transform="translate(5.687500, 22.312500) rotate(-90.000000) translate(-5.687500, -22.312500) "
              />
              <path
                d="M18.3722024,19.6076611 L18.3722024,27.1082145 L16.625,27.1082145 L16.625,16.625 L18.3722024,16.625 L27.1082145,16.625 L27.1082145,18.3722024 L19.6076611,18.3722024 L28,26.7645413 L26.7645413,28 L18.3722024,19.6076611 Z"
                id="Combined-Shape-Copy-2"
                transform="translate(22.312500, 22.312500) rotate(-180.000000) translate(-22.312500, -22.312500) "
              />
              <path
                d="M18.3722024,2.9826611 L18.3722024,10.4832145 L16.625,10.4832145 L16.625,0 L18.3722024,0 L27.1082145,9.99200722e-15 L27.1082145,1.74720242 L19.6076611,1.74720242 L28,10.1395413 L26.7645413,11.375 L18.3722024,2.9826611 Z"
                id="Combined-Shape-Copy-3"
                transform="translate(22.312500, 5.687500) rotate(-270.000000) translate(-22.312500, -5.687500) "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
