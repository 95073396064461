import React, { Component } from 'react';
import cx from 'classnames';
import CustomScroll from 'react-customscroll';
import buttonComposer from '../../lib/reactv-navigation/components/Buttonizer/Buttonizer';
import { connect } from 'react-redux';
import { updateScrollablePanel } from '../../reducers/scrollablePanel';

import './ScrollableTextPanel.scss';

const ScrollableText = buttonComposer(
  ({ focused, innerHTML, hideGradient, jsxContent }) => {
    return (
      <React.Fragment>
        <CustomScroll>
          <div
            id="ScrollableText"
            className="scrollableTextPanel__scrollableText"
            dangerouslySetInnerHTML={innerHTML ? { __html: innerHTML } : null}
          >
            {jsxContent}
          </div>
          {!hideGradient && <div className="scrollableTextPanel__gradient" />}
        </CustomScroll>
      </React.Fragment>
    );
  }
);

class ScrollableTextPanel extends Component {
  componentDidMount() {
    const { scrollablePanel } = this.props;
    this.scrollTextArea = document.querySelector('.react-customscroll-holder');
    this.contentHeight = this.scrollTextArea.scrollHeight;
    this.visiblePageHeight = this.scrollTextArea.clientHeight;
    this.checkPageCount();
    this.scrollTextArea.scrollTop =
      scrollablePanel.currentPage * this.visiblePageHeight;
  }

  componentDidUpdate(prevProps, prevState) {
    const { scrollablePanel } = this.props;
    this.contentHeight = this.scrollTextArea.scrollHeight;
    this.visiblePageHeight = this.scrollTextArea.clientHeight;
    if (
      this.scrollTextArea.scrollTop !==
      scrollablePanel.currentPage * this.visiblePageHeight
    ) {
      this.scrollTextArea.scrollTop =
        scrollablePanel.currentPage * this.visiblePageHeight;
    }
    this.checkPageCount();
  }

  checkPageCount() {
    const { scrollablePanel, updateScrollablePanel } = this.props;
    const scrollablePages = Math.ceil(
      this.contentHeight / this.visiblePageHeight
    );
    if (scrollablePanel.numberOfScrollablePages !== scrollablePages) {
      updateScrollablePanel({ numberOfScrollablePages: scrollablePages });
    }
  }

  scrollUp() {
    const { scrollablePanel, updateScrollablePanel } = this.props;
    const nextPage = scrollablePanel.currentPage - 1;

    if (nextPage >= 0) {
      updateScrollablePanel({ currentPage: nextPage, atBottomOfPanel: false });
    } else {
      updateScrollablePanel({ currentPage: 0, atBottomOfPanel: false });
      this.scrollTextArea.scrollTop = 0;
      if (this.props.onUp) {
        this.props.onUp();
      }
    }
  }

  scrollDown() {
    const { scrollablePanel, updateScrollablePanel } = this.props;
    const nextPage = scrollablePanel.currentPage + 1;

    if (nextPage < scrollablePanel.numberOfScrollablePages - 1) {
      updateScrollablePanel({ currentPage: nextPage });
    } else {
      this.scrollTextArea.scrollTop =
        (scrollablePanel.numberOfScrollablePages - 1) * this.visiblePageHeight;

      if (scrollablePanel.atBottomOfPanel && this.props.onDown) {
        this.props.onDown();
      }

      updateScrollablePanel({
        currentPage: scrollablePanel.numberOfScrollablePages - 1,
        atBottomOfPanel: true
      });
    }
  }

  render() {
    const {
      focused,
      onLeft,
      onEnter,
      innerHTML,
      hideGradient,
      children,
      className,
      scrollablePanel
    } = this.props;

    return (
      <div
        className={cx('scrollableTextPanel', {
          focused,
          'scrollableTextPanel--hideScroll':
            scrollablePanel.numberOfScrollablePages <= 1,
          [className]: className
        })}
      >
        <ScrollableText
          focused={focused}
          onUp={this.scrollUp.bind(this)}
          onDown={this.scrollDown.bind(this)}
          onLeft={onLeft}
          onEnter={onEnter}
          innerHTML={innerHTML}
          hideGradient={hideGradient}
          jsxContent={children}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { scrollablePanel } = state;
  return {
    scrollablePanel
  };
};

export default connect(
  mapStateToProps,
  { updateScrollablePanel }
)(ScrollableTextPanel);
