import React from 'react';
import { s } from '../../lib/screen-size';

const Icon = ({ fill }) => (
  <svg
    width={s(72)}
    height={s(72)}
    viewBox="0 0 72 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mini-Player/PlaylistRadio"
        transform="translate(-1256.000000, -60.000000)"
      >
        <g
          id="Button/Player/Default"
          transform="translate(1256.000000, 60.000000)"
        >
          <g
            id="Button/Player/Icon/Skip/Default"
            fill={fill ? fill : '#FFFFFF'}
            fillRule="nonzero"
          >
            <path
              d="M26.5096753,46.1654243 L39.3266541,37.1086144 C40.5705896,36.2185102 40.5705896,34.3492915 39.3266541,33.4814399 L26.5096753,24.4023774 C25.0213953,23.3787576 23,24.42463 23,26.2270909 L23,44.3407107 C23,46.1431717 25.0213953,47.1890441 26.5096753,46.1654243 Z"
              id="Path"
            />
            <path
              d="M45,25.2252604 L45,47.4778646 C45,48.7017578 45.9995911,49.703125 47.2213135,49.703125 C48.4430359,49.703125 49.442627,48.7017578 49.442627,47.4778646 L49.442627,25.2252604 C49.442627,24.0013672 48.4430359,23 47.2213135,23 C45.9995911,23 45,24.0013672 45,25.2252604 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
