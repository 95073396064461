import React from 'react';
import { s } from '../../lib/screen-size';

const Icon = ({ fill }) => (
  <svg
    width={s(72)}
    height={s(72)}
    viewBox="0 0 72 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mini-Player/Live-Radio"
        transform="translate(-1560.000000, -60.000000)"
      >
        <g
          id="Button/Player/Default"
          transform="translate(1560.000000, 60.000000)"
        >
          <g
            id="Button/Player/Icon/Stop/Default"
            fill={fill ? fill : '#FFFFFF'}
            fillRule="evenodd"
          >
            <path
              d="M27.3333333,23 L44.6666667,23 C47.05,23 49,24.95 49,27.3333333 L49,44.6666667 C49,47.05 47.05,49 44.6666667,49 L27.3333333,49 C24.95,49 23,47.05 23,44.6666667 L23,27.3333333 C23,24.95 24.95,23 27.3333333,23 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
