import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Button from '../../components/Button/Button';
import { getLocalConfiguration } from '../../api/configuration';
import {
  getAuthStatus,
  loginOrCreateOauthUser,
  login3rdParty,
  getAuthCode
} from '../../api/account';
import { removeDeviceProfile, getUserProfile } from '../../api/profile';
import { getUserGenres } from '../../api/taste';

import './SettingsAccountPanel.scss';

class SettingsAccountPanel extends Component {
  constructor(p) {
    super(p);
    this.state = {
      currentFocus: 'logout/newCodeButton'
    };
    this.focusMyGenresButton = this.focusMyGenresButton.bind(this);
    this.onUpFromMyGenresButton = this.onUpFromMyGenresButton.bind(this);
    this.requestNewCode = this.requestNewCode.bind(this);
    this.doUserLogout = this.doUserLogout.bind(this);
    this.goToMyGenres = this.goToMyGenres.bind(this);
  }

  componentDidMount() {
    if (this.props.isAnonymousUser) {
      this.statusInterval = setInterval(
        this.checkIfUserHasActivated.bind(this),
        3000
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.statusInterval);
  }

  async checkIfUserHasActivated() {
    const statusResponse = await getAuthStatus();
    if (statusResponse.status === true) {
      this.switchToLoggedInUser();
    }
  }

  async switchToLoggedInUser() {
    if (this.props.isAnonymousUser) {
      clearInterval(this.statusInterval);
      await login3rdParty();
      await getUserProfile();
      await this.props.resetSearch();
      this.props.onLeft();
      this.props.clearCardPiles();
      if (
        this.props.userSession.userConfiguration.subscription
          .subscriptionType === 'PLUS'
      ) {
        this.props.addToast({ type: 'unsupported-plus' });
      }

      this.setState({
        currentFocus: 'logout/newCodeButton'
      });
    }
  }

  focusMyGenresButton() {
    this.setState({ currentFocus: 'myGenresButton' });
  }

  onUpFromMyGenresButton() {
    this.setState({
      currentFocus: 'logout/newCodeButton'
    });
  }

  goToMyGenres() {
    this.props.history.push('/genreListing');
  }

  async requestNewCode() {
    await getAuthCode();
  }

  async doUserLogout() {
    this.props.pause();
    this.props.done(true);
    this.props.resetPlayer();
    this.props.resetSearch();
    this.props.clearCardPiles();
    await removeDeviceProfile();
    await getAuthCode();
    await loginOrCreateOauthUser();
    await getLocalConfiguration();
    this.props.clearStationData();
    let newProfile = await getUserProfile();
    this.props.setUserProfile(newProfile);
    const { genreIds } = await getUserGenres(newProfile.profileId);
    let favorites = {};
    genreIds.map(id => {
      favorites[id] = true;
      return null;
    });
    this.props.setUserGenres(favorites);
    this.props.onLeft();
    this.statusInterval = setInterval(
      this.checkIfUserHasActivated.bind(this),
      3000
    );
  }

  render() {
    const { isAnonymousUser } = this.props;
    const { currentFocus } = this.state;

    const { authCode, userProfile, userConfiguration } = this.props.userSession;
    const { email, name } = userProfile;
    const { subscription } = userConfiguration;
    const loggedInAs = email || name;
    return (
      <div className="settingsAccountPanel">
        <div className="settingsAccountPanel__mainSection">
          {isAnonymousUser ? (
            <React.Fragment>
              <div className="settingsAccountPanel__title">Sign Up/Log In</div>
              <div className="settingsAccountPanel__infoWrapper settingsAccountPanel__infoWrapper--column">
                <div className="settingsAccountPanel__infoDescr">
                  Go to iHeart.com/activate on your computer or phone and enter
                  your activation code:
                </div>
                <div className="settingsAccountPanel__code">
                  {authCode.code}
                </div>
                <Button
                  focused={
                    this.props.focused &&
                    currentFocus === 'logout/newCodeButton'
                  }
                  onDown={this.focusMyGenresButton}
                  onUp={this.props.onUp}
                  onLeft={this.props.onLeft}
                  onEnter={this.requestNewCode}
                  className="settingsAccountPanel__button settingsAccountPanel__button--requestCode"
                  label={'Request New Code'}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="settingsAccountPanel__title">Account Info</div>
              <div className="settingsAccountPanel__infoWrapper">
                <div className="settingsAccountPanel__infoDescr">
                  Logged in as
                </div>
                <div className="settingsAccountPanel__infoValue">
                  {loggedInAs}
                </div>
              </div>
              <div className="settingsAccountPanel__infoWrapper">
                <div className="settingsAccountPanel__infoDescr">
                  Subscription
                </div>
                <div className="settingsAccountPanel__infoValue">
                  {subscription.subscriptionType === 'PREMIUM'
                    ? 'ALL ACCESS'
                    : subscription.subscriptionType === 'PLUS'
                    ? 'PLUS'
                    : 'FREE'}
                </div>
              </div>
              <div className="settingsAccountPanel__infoWrapper settingsAccountPanel__infoWrapper--column">
                <div className="settingsAccountPanel__infoDescr">
                  Are you sure you want to log out?
                </div>
                <Button
                  focused={
                    this.props.focused &&
                    currentFocus === 'logout/newCodeButton'
                  }
                  onUp={this.props.onUp}
                  onEnter={this.doUserLogout}
                  onLeft={this.props.onLeft}
                  onDown={this.focusMyGenresButton}
                  className="settingsAccountPanel__button"
                  label={'Log Out'}
                />
              </div>
            </React.Fragment>
          )}
        </div>

        <div className="settingsAccountPanel__genresSection">
          <div className="settingsAccountPanel__title">My Genres</div>
          <div className="settingsAccountPanel__infoWrapper settingsAccountPanel__infoWrapper--column settingsAccountPanel__infoWrapper--withoutBorder">
            <div className="settingsAccountPanel__infoDescr">
              Tell us all the genres you like. We’ll suggest stations just for
              you.
            </div>
            <Button
              focused={
                this.props.focused &&
                this.state.currentFocus === 'myGenresButton'
              }
              onUp={this.onUpFromMyGenresButton}
              onLeft={this.props.onLeft}
              onDown={this.props.onDown}
              onEnter={this.goToMyGenres}
              className="settingsAccountPanel__button settingsAccountPanel__button--genres"
              label={'Manage My Genres'}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SettingsAccountPanel);
