import { routes } from '../constants';
import ReactvPlatforms from '../lib/reactv-platforms/index';
import store from '../store';
import { getV1APIRoute, postV1APIRoute } from './utils';
import sha1 from 'crypto-js/sha1';
import {
  setAuthCode,
  loadAccountSession,
  loadAnonymousSession
} from '../reducers/session';
import { getLocalConfiguration } from './configuration';

const host = ReactvPlatforms.platform.getHostURL();

const getAuthCode = async () => {
  const externalUuid = ReactvPlatforms.platform.getDeviceId();
  const deviceType = host;
  const params = { externalUuid, deviceType };

  const data = await getV1APIRoute(routes['GET_CODE'], params);
  store.dispatch(setAuthCode(data));
  return data;
};

const getAuthStatus = async () => {
  const externalUuid = ReactvPlatforms.platform.getDeviceId();
  const code = store.getState().session.authCode.code;
  const params = { code, externalUuid };

  return getV1APIRoute(routes['GET_STATUS'], params);
};

const removeOauthCred = async accessTokenType => {
  const { sessionId, profileId } = store.getState().session;
  const params = { sessionId, profileId, accessTokenType };
  const url = routes['REMOVE_OAUTH_CRED'];

  const { data: response } = postV1APIRoute(url, params);
  return response;
};

const loginOrCreateOauthUser = async (config = {}) => {
  const { deviceName = 'iHeartRadioVizio' } = config;
  const deviceId = ReactvPlatforms.platform.getDeviceId();
  const url = routes['LOGIN_OR_CREATE_OAUTH_USER'];
  const params = new URLSearchParams();
  params.append('accessToken', 'anon');
  params.append('accessTokenType', 'anon');
  params.append('deviceId', deviceId);
  params.append('deviceName', deviceName);
  params.append('deviceType', host);
  params.append('host', host);
  params.append('oauthUuid', deviceId);
  params.append('username', 'anon' + deviceId);

  const response = await postV1APIRoute(url, params);
  const { status, data } = response;

  if (status >= 200) {
    store.dispatch(loadAnonymousSession(data.profileId, data.sessionId));
  }
};

const login3rdParty = async () => {
  const deviceName = 'iHeartRadioVizio';
  const deviceId = ReactvPlatforms.platform.getDeviceId();
  const url = routes['LOGIN_3RD_PARTY'];

  const params = new URLSearchParams();
  const timestamp = new Date() / 1; //epoch
  const salt = ReactvPlatforms.platform.getSalt();
  let hashStr = salt + deviceId + timestamp;
  const hash = sha1(hashStr);

  params.append('deviceId', deviceId);
  params.append('deviceName', deviceName);
  params.append('deviceType', host);
  params.append('host', host);
  params.append('hash', hash);
  params.append('externalUuid', deviceId);
  params.append('setCurrentStreamer', true);
  params.append('timeStamp', timestamp);
  if (process.env.REACT_APP_CHECK_USER_IN_SUPPORTED_LOCATION === 'false') {
    // TODO: Load account from env
    store.dispatch(loadAccountSession(935609989, 'Ezad6SGvjmGN5hFCHoFeQu'));
    await getLocalConfiguration();
    return;
  }
  const response = await postV1APIRoute(url, params);
  const { status, data } = response;
  if (status >= 200) {
    store.dispatch(loadAccountSession(data.profileId, data.sessionId));
    await getLocalConfiguration();
    return data;
  } else {
    const err = new Error();
    err.status = status;
    throw err;
  }
};

export {
  getAuthCode,
  getAuthStatus,
  removeOauthCred,
  loginOrCreateOauthUser,
  login3rdParty
};
