import React from 'react';
import { s } from '../../lib/screen-size';

const Icon = ({ fill }) => (
  <svg
    width={s(72)}
    height={s(72)}
    viewBox="0 0 72 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mini-Player/Podcast"
        transform="translate(-1037.000000, -60.000000)"
      >
        <g
          id="Button/Player/Default"
          transform="translate(1037.000000, 60.000000)"
        >
          <g id="Button/Player/Icon/Back-15/Default">
            <path
              d="M55.2635292,27.8250631 C52.0589837,21.2076389 45.1880575,16.63125 37.2262221,16.63125 C29.2643868,16.63125 22.3934605,21.2076389 19.1889151,27.8250631"
              id="Stroke-9"
              stroke={fill ? fill : '#FFFFFF'}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.5471913,45.3185606 C22.8861707,51.557197 29.5480489,55.8147727 37.2262221,55.8147727 C44.9050386,55.8147727 51.5662736,51.557197 54.905253,45.3185606"
              id="Stroke-11"
              stroke={fill ? fill : '#FFFFFF'}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M46.5122642,53.6333965 C52.8686106,50.35625 57.206518,43.8177399 57.206518,36.2817551 C57.206518,28.7451389 52.8686106,22.2072601 46.5122642,18.9301136"
              id="Stroke-13"
              stroke={fill ? fill : '#FFFFFF'}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <polygon
              id="Arrow-Icon"
              fill={fill ? fill : '#FFFFFF'}
              fillRule="evenodd"
              transform="translate(18.705573, 28.225430) rotate(124.000000) translate(-18.705573, -28.225430) "
              points="13.8538214 22.1648236 13.8538214 34.2860358 23.5573254 28.2254297"
            />
            <path
              d="M32.6459316,43.3029514 L30.8363797,43.3029514 L30.8363797,31.5295139 L27.2075472,32.8376736 L27.2075472,31.2335069 L32.3637972,29.3333333 L32.6459316,29.3333333 L32.6459316,43.3029514 Z M38.7458726,36.3324653 L39.4658019,29.4001736 L46.723467,29.4001736 L46.723467,31.0329861 L40.9932193,31.0329861 L40.5651533,34.8237847 C41.2591426,34.4227411 42.0471654,34.2222222 42.9292453,34.2222222 C44.2199357,34.2222222 45.2446896,34.6407655 46.0035377,35.4778646 C46.7623859,36.3149637 47.1418042,37.4464628 47.1418042,38.8723958 C47.1418042,40.3046947 46.7477929,41.4330109 45.9597583,42.2573785 C45.1717237,43.081746 44.0707618,43.4939236 42.6568396,43.4939236 C41.4050645,43.4939236 40.3835535,43.1533599 39.5922759,42.4722222 C38.8009984,41.7910846 38.3502364,40.8489643 38.2399764,39.6458333 L39.9425118,39.6458333 C40.0527718,40.4415549 40.3413892,41.04152 40.8083726,41.4457465 C41.2753561,41.8499731 41.8915056,42.0520833 42.6568396,42.0520833 C43.4935183,42.0520833 44.150204,41.7719935 44.6269163,41.2118056 C45.1036286,40.6516176 45.3419811,39.8781878 45.3419811,38.8914931 C45.3419811,37.9620903 45.0841712,37.2157146 44.5685436,36.6523437 C44.0529161,36.0889729 43.3670444,35.8072917 42.510908,35.8072917 C41.7261164,35.8072917 41.1099669,35.9759821 40.662441,36.3133681 L40.1857311,36.6953125 L38.7458726,36.3324653 Z"
              id="15"
              fill={fill ? fill : '#FFFFFF'}
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
