import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GridComposer from '../../lib/reactv-navigation/components/Grid/GridComposer';
import GridMenu from './GridMenu';
import omit from 'lodash/omit';

const ComposedMenu = GridComposer(GridMenu);

class GridMenuContainer extends Component {
  render() {
    const {
      onEnter,
      data,
      xCards,
      mid,
      label,
      hideViewAllCard,
      dataContentType
    } = this.props;

    this.onEnterCheckedForViewAll = onEnter;
    let viewAllCardData = {
      viewAllData: data,
      viewAllContentType: dataContentType,
      menuid: mid,
      label,
      _model: { type: 'VIEWALL' }
    };
    if (xCards && data.length >= xCards) {
      const viewAllCard = {
        label: 'View All',
        imagePath: null,
        kind: 'VIEWALL'
      };
      this.menuItemsCheckedForViewAll = data.slice(
        0,
        hideViewAllCard ? xCards : xCards - 1
      );

      if (!hideViewAllCard) {
        this.menuItemsCheckedForViewAll.push(viewAllCard);
        this.onEnterCheckedForViewAll = (card, idx) => {
          if (idx === xCards - 1) {
            onEnter(viewAllCardData);
          } else {
            onEnter(card, idx);
          }
        };
      }
    } else {
      this.menuItemsCheckedForViewAll = data.slice(0);
      this.onEnterCheckedForViewAll = onEnter;
    }

    const props = omit(this.props, 'onEnter');
    return (
      <ComposedMenu
        {...props}
        menuItems={this.menuItemsCheckedForViewAll}
        onEnter={this.onEnterCheckedForViewAll}
        viewAllCardData={viewAllCardData}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    viewAllMenu: state.navigation.menus['viewAllPage-mainMenu-0']
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(GridMenuContainer)
);
