import axios from 'axios';
import ReactvPlatforms from '../lib/reactv-platforms/index';
import { setApiURL, setUserConfiguration } from '../reducers/session';
import store from '../store';
import { getV3APIRoute } from './utils';

const GLOBAL_CONFIGURATION_URL = ReactvPlatforms.platform.getLocalConfigURL();
const configurationParams = {
  hostname: ReactvPlatforms.platform.getHostURLForConfig(),
  version: '1.0.0'
};

if (process.env.REACT_APP_FORCE_US === 'true') {
  configurationParams.countryCode = 'US';
}

const getGlobalConfiguration = async () => {
  try {
    const { data } = await axios.get(GLOBAL_CONFIGURATION_URL, {
      params: configurationParams
    });
    store.dispatch(setApiURL(data.config.apiUrl));
    return data;
  } catch (err) {
    console.error('Error while executing getGlobalConfiguration', err);
    throw err;
  }
};

const getLocalConfiguration = async () => {
  const { apiUrl } = store.getState().session;
  const locationConfig = '/api/v3/locationConfig';
  const url = apiUrl ? apiUrl + locationConfig : locationConfig;
  const params = { ...configurationParams };
  const data = await getV3APIRoute(url, params);
  store.dispatch(setUserConfiguration(data));
};

export { getGlobalConfiguration, getLocalConfiguration };
