import store from '../store';
import { getV1APIRoute, postV1APIRoute, getV2APIRoute } from './utils';
import ReactvPlatforms from '../lib/reactv-platforms/index';
import { setUserProfile } from '../reducers/session';
import { setIsPageLoaded, PAGINATION_PAGE_SIZE } from '../reducers/view';
import {
  setLibraryLiveStations,
  setLibraryArtistStations
} from '../reducers/library';
import { isLiveStation } from '../lib/utils.js';
import { getLiveStationContent } from './content.js';
import { getPlaylist } from './playlists.js';

const GET_USER_PROFILE = '/api/v1/profile/{profileId}/getProfile';
const REMOVE_DEVICE_PROFILE = '/api/v1/profile/removeDeviceProfile/';
const SAVE_PREFERENCES = '/api/v1/profile/savePreferences';

export const getUserProfile = async () => {
  const { sessionId, profileId } = store.getState().session;
  const url = GET_USER_PROFILE.replace('{profileId}', profileId);
  const params = {
    ownerProfileId: profileId,
    profileId,
    sessionId,
    includeFriends: false,
    includeFriendsPlays: false,
    includePreferences: true,
    includeFavorites: true
  };
  const data = await getV1APIRoute(url, params);
  store.dispatch(setUserProfile(data));

  return data;
};

export const getLibraryLiveStations = async (
  limit = PAGINATION_PAGE_SIZE,
  pageNumber,
  pageKey
) => {
  const { userProfile } = store.getState().session;
  const params = { pageKey, limit, sortBy: 'LAST_PLAYED' };
  if (pageNumber) {
    params.offset = limit * (pageNumber - 1);
  }
  const favoriteLiveRadioIds = [];
  if (userProfile && userProfile.favorites && userProfile.favorites.length) {
    userProfile.favorites.forEach(favorite => {
      if (favorite.type === 'LR') {
        favoriteLiveRadioIds.push(favorite.id);
      }
    });
  }
  let playlistType = 'LIVE';
  const favLiveRadioStations = favoriteLiveRadioIds.length
    ? await getPlaylist(playlistType, favoriteLiveRadioIds.join(','), params)
    : {};
  if (favLiveRadioStations && favLiveRadioStations.hits) {
    let data =
      (favLiveRadioStations.hits.length && favLiveRadioStations.hits) || [];
    data = data.map(item => item.content && item.content[0]);
    store.dispatch(setLibraryLiveStations(data));
    store.dispatch(
      setIsPageLoaded(
        'library-live-radio',
        pageKey ? pageNumber : 1,
        true,
        favLiveRadioStations.total <= limit ? false : true,
        data.length
      )
    );
    return data;
  }
};

export const getLibraryArtistStations = async (
  limit = PAGINATION_PAGE_SIZE,
  pageNumber,
  pageKey
) => {
  const { userProfile } = store.getState().session;
  const params = { pageKey, limit, sortBy: 'LAST_PLAYED' };
  if (pageNumber) {
    params.offset = limit * (pageNumber - 1);
  }
  const favoriteArtistRadioIds = [];
  if (userProfile && userProfile.favorites && userProfile.favorites.length) {
    userProfile.favorites.forEach(favorite => {
      if (favorite.type === 'CR') {
        favoriteArtistRadioIds.push(favorite.id);
      }
    });
  }
  let playlistType = 'ARTIST';
  const favArtistStations = favoriteArtistRadioIds.length
    ? await getPlaylist(playlistType, favoriteArtistRadioIds.join(','), params)
    : {};
  if (favArtistStations && favArtistStations.hits) {
    const data = favArtistStations.hits || [];
    store.dispatch(setLibraryArtistStations(data));
    store.dispatch(
      setIsPageLoaded(
        'library-artists',
        pageKey ? pageNumber : 1,
        true,
        favArtistStations.total <= limit ? false : true,
        data.length
      )
    );
    return data;
  }
};

export const getFavoriteLiveAndArtistRadioStationsFromUserProfile = async () => {
  const { userProfile } = store.getState().session;
  const favoriteLiveRadioIds = [];
  const favoriteArtistRadioIds = [];
  if (userProfile && userProfile.favorites && userProfile.favorites.length) {
    userProfile.favorites.forEach(favorite => {
      if (favorite.type === 'CR') {
        favoriteArtistRadioIds.push(favorite.id);
      } else if (favorite.type === 'LR') {
        favoriteLiveRadioIds.push(favorite.id);
      }
    });
  }

  const favLiveRadioStations = favoriteLiveRadioIds.length
    ? await getPlaylist('LIVE', favoriteLiveRadioIds.join(','))
    : {};
  const favArtistRadioStations = favoriteArtistRadioIds.length
    ? await getPlaylist('ARTIST', favoriteArtistRadioIds.join(','))
    : {};

  const userLibrary = {
    yourLibraryLiveStations: [],
    yourLibraryArtistStations: []
  };
  if (favLiveRadioStations.hits) {
    userLibrary.yourLibraryLiveStations = favLiveRadioStations.hits;
  }
  if (favArtistRadioStations.hits) {
    userLibrary.yourLibraryArtistStations = favArtistRadioStations.hits;
  }
  store.dispatch(setLibraryLiveStations(userLibrary.yourLibraryLiveStations));
  store.dispatch(
    setLibraryArtistStations(userLibrary.yourLibraryArtistStations)
  );
  return userLibrary;
};

export const removeDeviceProfile = async () => {
  const { sessionId, profileId } = store.getState().session;
  const deviceId = ReactvPlatforms.platform.getDeviceId();
  const url = REMOVE_DEVICE_PROFILE;

  const params = new URLSearchParams();

  params.append('profileId', profileId);
  params.append('deviceType', ReactvPlatforms.platform.getHostURL());
  params.append('sessionId', sessionId);
  params.append('uuid', deviceId);
  return await postV1APIRoute(url, params);
};

export const savePreferences = async (prefName, prefVal) => {
  const { sessionId, profileId } = store.getState().session;
  const url = SAVE_PREFERENCES;

  const params = new URLSearchParams();

  params.append('profileId', profileId);
  params.append('sessionId', sessionId);
  params.append('usePeriodDelimiterInPrefKeys', true);
  params.append('name_1', prefName);
  params.append('value_1', prefVal);
  const { data } = await postV1APIRoute(url, params);
  store.dispatch(setUserProfile(data));
  return data;
};

export const getFavoriteLiveRadio = async () => {
  const { profileId } = store.getState().session;
  const url =
    '/api/v2/profile/{profileId}/favorites'.replace('{profileId}', profileId) +
    '?limit=20';
  const data = await getV2APIRoute(url);
  let liveStations = [];
  for (const favoriteStation of data.values) {
    if (isLiveStation(favoriteStation.type)) {
      const stationData = await getLiveStationContent(favoriteStation.id);
      if (stationData && stationData.hits && stationData.hits.length) {
        liveStations.push(stationData.hits[0]);
      }
    }
  }
  return liveStations;
};
