import React, { Component } from 'react';
import cx from 'classnames';
import ExclamationIcon from '../../img/exclamationIcon';
import ReactvPlatforms from '../../lib/reactv-platforms/index';

import './Toast.scss';

class Toast extends Component {
  constructor(p) {
    super(p);

    this.requestHide = this.requestHide.bind(this);
  }

  componentDidMount() {
    ReactvPlatforms.platform.tts(this.props.toast.text);
    this.timer = setTimeout(this.requestHide, 4000);
  }

  requestHide() {
    const { onRequestHide } = this.props;
    if (onRequestHide) {
      onRequestHide();
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  render() {
    const {
      toast: { type, text }
    } = this.props;
    return (
      <div
        className={cx('toast', {
          'toast--libraryRadio': type.includes('library-radio'),
          'toast--libraryArtist': type.includes('library-artist'),
          'toast--libraryPlaylist': type.includes('library-playlist'),
          'toast--libraryPodcast': type.includes('library-podcast'),
          'toast--libraryAlbum': type.includes('library-album'),
          'toast--librarySongs': type.includes('library-songs'),
          'toast--miniplayer': type.includes('miniplayer'),
          'toast--fullplayer': type.includes('fullplayer')
        })}
      >
        <div className="toast__mark">
          <ExclamationIcon fill="#000" />
        </div>
        <div className="toast__text">{text}</div>
      </div>
    );
  }
}

export default Toast;
