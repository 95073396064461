export const loadAnonymousSession = (
  profileId,
  sessionId,
  subscriptionType,
  userProfile,
  userConfiguration
) => {
  const type = 'LOAD_ANONYMOUS_SESSION';
  return { type, profileId, sessionId, subscriptionType };
};

export const loadAccountSession = (profileId, sessionId, subscriptionType) => {
  const type = 'LOAD_ACCOUNT_SESSION';
  return { type, profileId, sessionId, subscriptionType };
};

export const setAuthCode = authCode => {
  const type = 'SET_AUTH_CODE';
  return { type, authCode };
};

export const setApiURL = apiUrl => {
  const type = 'SET_API_URL';
  return { type, apiUrl };
};

export const setUserProfile = userProfile => {
  const type = 'SET_USER_PROFILE';
  return { type, userProfile };
};

export const setUserConfiguration = userConfiguration => {
  const type = 'SET_USER_CONFIGURATION';
  return { type, userConfiguration };
};

export default function(state = {}, action) {
  const {
    type,
    apiUrl,
    sessionId,
    profileId,
    subscriptionType,
    authCode,
    userProfile,
    userConfiguration
  } = action;
  switch (type) {
    case 'LOAD_ANONYMOUS_SESSION':
      return {
        ...state,
        ...{ sessionId, profileId, subscriptionType }
      };
    case 'LOAD_ACCOUNT_SESSION':
      return {
        ...state,
        ...{ sessionId, profileId, subscriptionType }
      };
    case 'SET_AUTH_CODE':
      return { ...state, ...{ authCode } };
    case 'SET_API_URL':
      return { ...state, ...{ apiUrl } };
    case 'SET_USER_PROFILE':
      return { ...state, ...{ userProfile } };
    case 'SET_USER_CONFIGURATION':
      return {
        ...state,
        ...{ userConfiguration },
        isPremiumUser:
          userConfiguration.subscription.subscriptionType === 'PREMIUM'
      };
    default:
      return state;
  }
}
