import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import BaseButton from '../BaseButton/BaseButton.js';
import ProgressBar from '../ProgressBar/ProgressBar.js';
import { updateScrollPosition } from '../../reducers/appNavigation.js';
import { Touch } from '../../lib/reactv-touch';

import './MenuCard.scss';

class MenuCard extends BaseButton {
  constructor(p) {
    super(p);

    this.state = {
      titleExceedsWidth: false
    };

    this.itemRef = React.createRef();
    this.titleRef = React.createRef();

    this.getPixelRatio = this.getPixelRatio.bind(this);
  }

  getPixelRatio(sizes) {
    /**
     * Change pixel ratio to decrease or increase the resolution.
     * @values 0.5, 1, 1.5, 2, 3, ...
     */
    const ratio = 1;

    return sizes.map(size => size * ratio);
  }

  readTTS() {
    const { item } = this.props;
    const isSelectGenre = item.kind === 'selectGenre';
    const showSubtitle =
      ((item.subLabel || item.description || item.subtitle) && isSelectGenre) ||
      item._model.isSong;
    let read = `${item.label ||
      item.title ||
      item.genreName ||
      item.name ||
      item.stationName}`;

    if (showSubtitle) {
      read = read + ` ${item._model.subtitle || item.albumName}`;
    }

    if (item.subType === 'FAVORITES') {
      read = `${item.label} Favorites Radio`;
    }
    return read;
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.props.focused === true) {
      if (this.titleRef.current) {
        let data = this.titleRef.current.getBoundingClientRect();

        if (data.width >= this.props.maxWidthForTitle) {
          this.setState({ titleExceedsWidth: true });
        }
      }
    }

    this.touch = new Touch(this.itemRef.current, {
      ontap: e => {
        let item = this.props.item;
        if (item._model && item._model.type === 'VIEWALL') {
          item = this.props.viewAllCardData;
        }

        this.itemRef.current.classList.add('clicked');
        let rect = this.itemRef.current.getBoundingClientRect();
        let span = document.createElement('span');

        span.classList = 'touch-gesture';
        span.style.top = `${e.startY - rect.top}px`;
        span.style.left = `${e.startX - rect.left}px`;
        span.style.height = span.style.width = `${Math.max(
          rect.width,
          rect.height
        )}px`;
        this.itemRef.current.appendChild(span);

        clearTimeout(this.emit);
        this.emit = setTimeout(() => {
          this.props.onEnter(item);
        }, 800);

        setTimeout(() => {
          this.itemRef.current &&
            this.itemRef.current.classList.remove('clicked');
        }, 550);
      }
    });
  }

  componentWillUnmount() {
    clearTimeout(this.emit);
    this.touch && this.touch.unsubscribe();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    super.componentDidUpdate(prevProps, prevState);
    if (prevProps.focused === false && this.props.focused === true) {
      if (this.itemRef.current) {
        const data = this.itemRef.current.getBoundingClientRect();
        this.props.updateScrollPosition({
          left: data.left,
          right: data.right
        });
      }
      if (!this.state.titleExceedsWidth && this.titleRef.current) {
        let data = this.titleRef.current.getBoundingClientRect();

        if (data.width >= this.props.maxWidthForTitle) {
          this.setState({ titleExceedsWidth: true });
        }
      }
    }
  }

  render() {
    const {
      item,
      focused,
      wider,
      big,
      key,
      recentlyPlayed,
      isSquare,
      favorites
    } = this.props;
    const { titleExceedsWidth } = this.state;
    const {
      seedArtistId,
      albumName,
      kind,
      type,
      contentType,
      subType,
      _model: {
        isLiveRadio,
        isArtistRadio,
        isPlaylistRadio,
        isPodcast,
        isAlbum,
        isSong,
        isViewAll,
        title,
        subtitle,
        image,
        contentId: scalingID,
        type: scalingType
      }
    } = item;
    const isGenreSelected = favorites && favorites[item.id] ? true : false;
    const isSelectGenre = kind === 'selectGenre';
    const isCardAnimated = !isSelectGenre && focused && titleExceedsWidth;
    let animation;
    if (isCardAnimated) {
      animation = {
        animation: `marquee ${Math.ceil(title.length / 8) + 2}s linear infinite`
      };
    }
    const showSubtitle =
      ((item.subLabel || item.description || item.subtitle) && isSelectGenre) ||
      isSong;

    let dimensions = this.getPixelRatio([184, 235]);
    if (isSquare) {
      dimensions = this.getPixelRatio([231, 231]);
    } else if (wider) {
      dimensions = this.getPixelRatio([352, 227]);
    } else if (big) {
      dimensions = this.getPixelRatio([320, 320]);
    }

    let thumbnail = `${image}?ops=fit(${dimensions.join()})`;
    if (scalingType && (seedArtistId || scalingID)) {
      switch (scalingType) {
        case 'ARTIST':
          thumbnail = `https://iscale.iheart.com/catalog/artist/${seedArtistId ||
            scalingID}?ops=fit(${dimensions.join()})`;
          break;

        case 'SONG':
          thumbnail = `https://iscale.iheart.com/catalog/track/${scalingID}?ops=fit(${dimensions.join()})`;
          break;

        case 'ALBUM':
          thumbnail = `https://iscale.iheart.com/catalog/album/${scalingID}?ops=fit(${dimensions.join()})`;
          break;

        case 'LIVE':
          const max = Math.max(...dimensions);
          thumbnail = `https://iscale.iheart.com/catalog/live/${scalingID}?ops=fit(${max},${max})`;
          break;

        case 'PLAYLIST':
          let imageParts = image && image.split('?');
          if (!imageParts || !imageParts.length) {
            thumbnail = '';
            break;
          }
          if (image.indexOf('content.iheart.com') === -1) {
            thumbnail = imageParts[0];
          } else {
            thumbnail = `https://i.iheart.com/v3/url/${btoa(imageParts[0])}`;
          }
          thumbnail =
            thumbnail + `?ops=gravity("center"),fit(${dimensions.join()})`;
          break;

        case 'PODCAST':
          if (image.toLowerCase().indexOf('catalog/podcast') === -1) {
            // Some images don't point to /catalog/podcast - update them to do so
            thumbnail = `http://i.iheart.com/v3/catalog/podcast/${scalingID}?ops=fit(${dimensions.join()})`;
          }
          break;

        default:
          break;
      }
    }

    let iconLabel;
    if (isLiveRadio) {
      iconLabel = 'LIVE RADIO';
    } else if (isArtistRadio) {
      iconLabel = 'ARTIST RADIO';
    } else if (isPlaylistRadio) {
      iconLabel = 'PLAYLIST';
    } else if (isPodcast) {
      iconLabel = 'PODCAST';
    } else if (isSong) {
      iconLabel = 'SONG';
    } else if (isAlbum) {
      iconLabel = 'ALBUM';
    }

    return (
      <div
        className={cx('menuCard', {
          'menuCard--focused': focused,
          'menuCard--wider': wider,
          'menuCard--big': big,
          'menuCard--viewAll': isViewAll,
          'menuCard--selectGenre': isSelectGenre,
          'menuCard--genreListing': kind === 'genreListing',
          'menuCard--genreSelected': isGenreSelected,
          'menuCard--artistRadio': isArtistRadio,
          'menuCard--liveRadio': isLiveRadio,
          'menuCard--podcast': isPodcast,
          'menuCard--playlist': isPlaylistRadio,
          'menuCard--album': isAlbum,
          'menuCard--song': isSong,
          'menuCard--animate': isCardAnimated,
          'menuCard--recentlyPlayed': recentlyPlayed,
          'menuCard--square': isSquare
        })}
      >
        <div ref={this.itemRef} key={key} className="menuCard__wrapper">
          {image && (
            <img className="menuCard__thumb" src={thumbnail} alt={title} />
          )}
          {isSelectGenre && <div className="menuCard__selectGenreBackground" />}
          {isSelectGenre && <div className="menuCard__selectGenreGradient" />}
          {(contentType ||
            type ||
            isGenreSelected ||
            !isViewAll ||
            recentlyPlayed) && (
            <div className="menuCard__iconWrapper">
              <div className="menuCard__icon" />
              {iconLabel && focused && (
                <div className="menuCard__iconLabel">{iconLabel}</div>
              )}
            </div>
          )}
          {recentlyPlayed && (
            <div
              className={cx(
                'menuCard__iconWrapper menuCard__iconWrapper--recentlyPlayed',
                { 'menuCard__iconWrapper--recentlyPlayedPodcast': isPodcast }
              )}
            >
              <div className="menuCard__icon" />
            </div>
          )}
          {recentlyPlayed && isPodcast && (
            <ProgressBar
              className="menuCard__progressBar"
              hideTime
              currentTime={item.secondsPlayed}
              duration={item.duration}
            />
          )}
          {showSubtitle && (
            <label className="menuCard__subtitle">
              {subtitle || albumName}
            </label>
          )}
          <div className="menuCard__titlesWrapper">
            <div className="menuCard__titlesInnerWrapper">
              <div className="menuCard__titleWrapper">
                <span
                  className="menuCard__title"
                  style={animation}
                  ref={this.titleRef}
                >
                  {(subType === 'FAVORITES' && `${title} Favorites Radio`) ||
                    title}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { updateScrollPosition }
)(MenuCard);
