import React from 'react';
import { s } from '../lib/screen-size';

const ExclamationIcon = ({ fill }) => (
  <svg
    width={s(40)}
    height={s(40)}
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Toast-Alert"
        transform="translate(-32.000000, -24.000000)"
        fill={fill ? fill : '#FFFFFF'}
        fillRule="nonzero"
      >
        <path
          d="M52,24 C40.96,24 32,32.96 32,44 C32,55.04 40.96,64 52,64 C63.04,64 72,55.04 72,44 C72,32.96 63.04,24 52,24 Z M54,54 L50,54 L50,50 L54,50 L54,54 Z M54,46 L50,46 L50,34 L54,34 L54,46 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default ExclamationIcon;
