import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import { removeToast } from '../../reducers/toasts';
import Toast from './Toast';

class Toasts extends Component {
  constructor(p) {
    super(p);

    this.handleRequestHide = this.handleRequestHide.bind(this);
  }

  handleRequestHide(notification) {
    const { removeToast } = this.props;
    return () => {
      if (removeToast) {
        removeToast(notification);
      }
    };
  }

  render() {
    const { toasts } = this.props;

    return (
      <div>
        <CSSTransitionGroup
          transitionName="notification"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
        >
          {toasts &&
            toasts.map(toast => {
              const key = toast.id;

              return (
                <Toast
                  key={key}
                  toast={toast}
                  onRequestHide={this.handleRequestHide(toast)}
                />
              );
            })}
        </CSSTransitionGroup>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { toasts } = state;

  return {
    toasts
  };
};

export default connect(
  mapStateToProps,
  { removeToast }
)(Toasts);
