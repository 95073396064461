export const UPDATE_SETTINGS = 'settings/UPDATE_SETTINGS';

export function updateSettings(data) {
  return {
    type: UPDATE_SETTINGS,
    data
  };
}

const initialState = { currentListFocus: 0, page: 'manage-account' };

export default function cardsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SETTINGS:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
}
