import React from 'react';
import { connect } from 'react-redux';
import ProfileView from '../../components/ProfileView';
import ThreeRowCard from '../../components/ThreeRowCard';
import { getArtistProfile } from '../../api/artists';
import { getAlbumInfo } from '../../api/catalog';
import {
  setPageTitle,
  showLoadingSpinner,
  hideLoadingSpinner
} from '../../reducers/view';
import {
  toggleSaveAlbum,
  toggleSaveStation,
  isItemSavedInApp
} from '../../reducers/library';
import { playAlbum, playArtist } from '../../reducers/station';
import { addToast } from '../../reducers/toasts';
import { pause, play } from '../../reducers/audioPlayer';
import PlayIcon from '../../img/button/playIcon.js';
import PlusIcon from '../../img/button/plusIcon.js';
import PauseIcon from '../../img/button/pauseIcon.js';
import { s } from '../../lib/screen-size.js';
import toLocaleDateString from '../../lib/date';
import { checkItemIsInLibrary } from '../../reducers/library.js';

class Artist extends React.Component {
  constructor(p) {
    super(p);

    this.state = {
      view: 'SCREEN:LOADING'
    };

    this.onPlayPause = this.onPlayPause.bind(this);
    this.onToggleSave = this.onToggleSave.bind(this);
    this.getRouteData = this.getRouteData.bind(this);
    this.getAlbum = this.getAlbum.bind(this);
    this.getAllAlbums = this.getAllAlbums.bind(this);
    this.getArtist = this.getArtist.bind(this);
    this.getSong = this.getSong.bind(this);
    this.getAllSongs = this.getAllSongs.bind(this);
    this.getSimilarArtists = this.getSimilarArtists.bind(this);
  }

  addArtistInfoToAlbum(albums, params) {
    albums = albums.map(album => ({ ...album, ...params }));
    return albums;
  }

  async prepareForAlbumView(data, params) {
    const {
      albumId,
      artistName,
      releaseDate,
      totalSongs,
      tracks,
      title
    } = data;
    const menusData = [];

    if (Array.isArray(tracks) === true && tracks.length > 0) {
      const content = tracks.map(item => ({
        ...item,
        album: data,
        playlistType: 'ALBUM'
      }));
      menusData.push({
        content,
        details: {
          label: 'Songs',
          menuid: `${params.idForGrid}-artist-tracks`,
          dataContentType: 'SONG',
          threeRows: true,
          constantWidth: true,
          renderItem: ThreeRowCard
        },
        xCards: 9
      });
    }

    const infoCard = (
      <div className="profileView__infoSectionDescrWrapper">
        <div className="profileView__infoSectionDescr profileView__infoSectionDescr--bold">
          {artistName}
        </div>
        <div className="profileView__infoSectionDescr profileView__infoSectionDescr--bold">
          {`${toLocaleDateString(releaseDate)} \u2022 ${totalSongs} Song${
            totalSongs > 1 ? 's' : ''
          }`}
        </div>
      </div>
    );
    const view = {
      albumId,
      thumbnail: `https://iscale.iheart.com/catalog/album/${albumId}?ops=fit(214, 214)`,
      menusData,
      showDotsButton: false,
      infoCard,
      OFFSET_SCROLL: s(1300),
      AMOUNT_TO_MOVE_SCROLL_BY: 560,
      AMOUNT_TO_MOVE_SCROLL_BETWEEN_MENUS: 150
    };
    const { contentType, contentId } = this.props.station;
    await checkItemIsInLibrary({
      contentType: 'ALBUM',
      albumId
    });

    let newState = {
      view: 'SCREEN:PROFILEVIEW',
      album: data,
      profile: { ...view, ...params },
      isCurrentPlayerStation: contentType === 'ALBUM' && contentId === albumId
    };

    this.setState(newState, () => {
      this.props.setPageTitle(title);
      this.props.hideLoadingSpinner();
    });
  }

  async prepareForArtistView(data, params) {
    const {
      artist: { artistId: contentId, name } = {},
      bio,
      latestRelease,
      tracks,
      albums,
      relatedTo,
      popularOnStations
    } = data;

    const menusData = [];

    if (Array.isArray(tracks) === true && tracks.length > 0) {
      menusData.push({
        content: tracks,
        details: {
          label: 'Top songs',
          menuid: `${params.idForGrid}-artist-topSongs`,
          dataContentType: 'SONG',
          toastType: 'artist-topsong',
          constantWidth: true,
          isSquare: true
        },
        xCards: 4
      });
    }

    if (Array.isArray(albums) && albums.length > 0) {
      menusData.push({
        content: this.addArtistInfoToAlbum(albums, { contentId }),
        details: {
          label: 'Top albums',
          menuid: `${params.idForGrid}-artist-topAlbums`,
          dataContentType: 'ALBUM',
          constantWidth: true,
          isSquare: true
        },
        xCards: 4
      });
    }

    if (Array.isArray(relatedTo) && relatedTo.length > 0) {
      menusData.push({
        content: relatedTo,
        details: {
          label: 'Similar artists',
          menuid: `${params.idForGrid}-artist-similarArtist`,
          dataContentType: 'ARTIST'
        },
        xCards: 5
      });
    }

    if (Array.isArray(popularOnStations) && popularOnStations.length > 0) {
      menusData.push({
        content: popularOnStations,
        details: {
          label: 'Popular on',
          menuid: `${params.idForGrid}-artist-popularOn`,
          dataContentType: 'LIVE'
        },
        xCards: 4
      });
    }

    const infoCard = bio && (
      <div className="profileView__infoSectionDescr">
        {bio.length > 148 ? `${bio.slice(0, 145).trim()}...` : bio}
      </div>
    );
    const view = {
      contentId,
      thumbnail: `https://iscale.iheart.com/catalog/artist/${contentId}?ops=fit(214, 214)`,
      latestRelease,
      menusData,
      showDotsButton: process.env.REACT_APP_TOUCH !== 'true' && bio,
      infoCard,
      OFFSET_SCROLL: s(1300),
      AMOUNT_TO_MOVE_SCROLL_BY: 325,
      AMOUNT_TO_MOVE_SCROLL_BETWEEN_MENUS: 150
    };
    const { contentType, sourceId } = this.props.station;
    await checkItemIsInLibrary({
      contentType: 'ARTIST',
      artistId: data.artist.artistId
    });
    let newState = {
      view: 'SCREEN:PROFILEVIEW',
      profile: { ...view, ...params },
      artist: data,
      album: null,
      isCurrentPlayerStation:
        contentType === 'ARTIST' && sourceId === data.artist.artistId
    };

    this.setState(newState, () => {
      if (this.props.menu && this.props.menu.appNavigation) {
        document
          .querySelector('.mainContainer')
          .scrollTo(this.props.menu.appNavigation.lastScrollValue || 0, 0);
      }
      this.props.setPageTitle(name);
      this.props.hideLoadingSpinner();
    });
  }

  componentWillMount() {
    const {
      session: { sessionId }
    } = this.props;

    if (!sessionId) {
      this.props.history.replace('/');
    }

    this.getRouteData();
  }

  componentDidMount() {
    this.props.showLoadingSpinner();
    this.unlisten = this.props.history.listen(() => {
      // NOTE: hide main navigation text, ' ' can be replaced with 'Loading...'
      this.props.setPageTitle(' ');
      this.props.showLoadingSpinner();

      this.setState(
        {
          view: 'SCREEN:LOADING',
          profile: null
        },
        () => this.getRouteData()
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: { artistId, contentId }
      }
    } = this.props;

    if (
      prevProps.station.sourceId !== this.props.station.sourceId ||
      prevProps.station.contentId !== this.props.station.contentId
    ) {
      const { contentType, sourceId } = this.props.station;
      if (artistId && !contentId) {
        this.setState({
          isCurrentPlayerStation:
            contentType === 'ARTIST' && sourceId === parseInt(artistId)
        });
      } else if (artistId && contentId) {
        this.setState({
          isCurrentPlayerStation:
            contentType === 'ALBUM' &&
            +contentId === +this.props.station.contentId
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
  }

  getRouteData() {
    const {
      match: {
        params: { artistId, contentType, contentId }
      }
    } = this.props;

    // all similar artist
    if (contentType === 'similar') {
      return this.getSimilarArtists(artistId);
    }

    // all songs view
    if (contentType === 'songs' && !contentId) {
      return this.getAllSongs(artistId);
    }

    // single song view
    if (contentType === 'songs') {
      return this.getSong(contentId);
    }

    // all albums view
    if (contentType === 'albums' && !contentId) {
      return this.getAllAlbums(artistId);
    }

    // single album view
    if (contentType === 'albums') {
      return this.getAlbum(contentId);
    }

    return this.getArtist(artistId);
  }

  async getSimilarArtists(artistId) {
    // TODO: get all similar artists
    console.log('%cgetSimilarArtists', 'color:deeppink', artistId);
    this.setState({ view: 'SCREEN:MAINTENANCE' });
    this.props.hideLoadingSpinner();
  }

  async getAllSongs(artistId) {
    // TODO: get all artist's albums
    console.log('%cgetAllSongs', 'color:deeppink', artistId);
    this.setState({ view: 'SCREEN:MAINTENANCE' });
    this.props.hideLoadingSpinner();
  }

  async getSong(songId) {
    // TODO: get song
    console.log('%cgetSong', 'color:deeppink', songId);
    this.setState({ view: 'SCREEN:MAINTENANCE' });
    this.props.hideLoadingSpinner();
  }

  async getAllAlbums(artistId) {
    // TODO: get all artist's albums
    console.log('%cgetAllAlbums', 'color:deeppink', artistId);
    this.setState({ view: 'SCREEN:MAINTENANCE' });
    this.props.hideLoadingSpinner();
  }

  async getAlbum(albumId) {
    const idForGrid = 'albums';
    if (this.props.album === undefined) {
      const profile = await getAlbumInfo(albumId);
      if (profile === false) {
        this.props.hideLoadingSpinner();
        return this.setState({
          view: 'SCREEN:ERROR'
        });
      }

      this.prepareForAlbumView(profile, {
        idForGrid
      });
    }
  }

  async getArtist(artistId) {
    const idForGrid = 'artist';
    if (this.props.artist === undefined) {
      const profile = await getArtistProfile(artistId);
      if (profile === false) {
        this.props.hideLoadingSpinner();
        return this.setState({
          view: 'SCREEN:ERROR'
        });
      }

      this.prepareForArtistView(profile, {
        idForGrid
      });
    }
  }

  async onToggleSave() {
    const { album } = this.state;
    const {
      match: {
        params: { artistId, contentId }
      }
    } = this.props;

    if (artistId && !contentId) {
      toggleSaveStation('artist', artistId);
    } else if (artistId && contentId) {
      if (!this.props.session.isPremiumUser) {
        this.props.addToast({ type: 'save-album-disabled' });
        return;
      }
      toggleSaveAlbum(album);
    }
  }

  onPlayPause() {
    const {
      match: {
        params: { contentId }
      },
      playAlbum,
      playArtist,
      session,
      addToast
    } = this.props;
    const { album, artist, isCurrentPlayerStation } = this.state;
    const { DO_PLAY } = this.props.audioPlayer;
    if (isCurrentPlayerStation) {
      if (DO_PLAY) {
        this.props.pause();
      } else {
        this.props.play();
      }
    } else {
      if (contentId) {
        if (session.isPremiumUser) {
          const parsedAlbum = {
            id: contentId,
            tracks: album.tracks,
            name: album.title
          };
          playAlbum(parsedAlbum);
        } else {
          addToast({ type: 'on-demand' });
        }
      } else {
        playArtist(artist.artist.artistId);
      }
    }
  }

  render() {
    const {
      match: {
        params: { contentType, contentId, artistId }
      },
      library
    } = this.props;
    const { view, profile, isCurrentPlayerStation } = this.state;
    const { DO_PLAY } = this.props.audioPlayer;
    const isSaved = isItemSavedInApp(
      library,
      contentType || 'artist',
      contentType ? contentId : artistId
    );
    if (view === 'SCREEN:LOADING') {
      return null;
    }

    if (view === 'SCREEN:MAINTENANCE') {
      return <div className="under-maintenance">Under maintenance!</div>;
    }

    if (view === 'SCREEN:ERROR' || profile === undefined) {
      return (
        <div className="error">An error occured, please try again later.</div>
      );
    }

    return (
      <ProfileView
        {...profile}
        buttonsData={[
          {
            id: 'playBtn',
            icon: isCurrentPlayerStation && DO_PLAY ? PauseIcon : PlayIcon,
            text: isCurrentPlayerStation && DO_PLAY ? 'Pause' : 'Play'
          },
          {
            id: 'saveBtn',
            icon: PlusIcon,
            text: isSaved ? 'Remove' : 'Save',
            isSaved
          }
        ]}
        onDown={this.props.onDown}
        onPlay={this.onPlayPause}
        onToggleSave={this.onToggleSave}
        AMOUNT_TO_MOVE_SCROLL_BY={400}
        AMOUNT_TO_MOVE_SCROLL_BETWEEN_MENUS={400}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    session,
    library,
    station,
    audioPlayer,
    locationStateHistory,
    appNavigation
  } = state;

  return {
    menu: locationStateHistory[appNavigation.page],
    session,
    library,
    station,
    audioPlayer
  };
};

export default connect(
  mapStateToProps,
  {
    setPageTitle,
    playAlbum,
    playArtist,
    play,
    addToast,
    pause,
    showLoadingSpinner,
    hideLoadingSpinner
  }
)(Artist);
