import { connect } from 'react-redux';
import React, { Component } from 'react';
import ProgressBar from './ProgressBar';

const mapStateToProps = (state, ownProps) => ({
  currentTime: state.audioPlayer.currentTrackTime,
  duration: state.audioPlayer.currentTrackDuration
});

const mapDispatchToProps = () => ({});

class ProgressBarContainer extends Component {
  render() {
    return <ProgressBar {...this.props} />;
  }
}

export { ProgressBar };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgressBarContainer);
