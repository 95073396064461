import { getV2APIRoute, getV3APIRoute } from './utils';

export const getLiveStationContent = async stationId => {
  const url = '/api/v2/content/liveStations/' + stationId;
  const data = await getV2APIRoute(url);
  return data;
};

export const getStationCurrentTrack = async stationId => {
  let url = `/api/v3/live-meta/stream/${stationId}/currentTrackMeta`;
  const data = await getV3APIRoute(url);
  return data;
};
