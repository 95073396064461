import React from 'react';
import { s } from '../../lib/screen-size';

const PlusIcon = ({ fill }) => (
  <svg
    width={s(20)}
    height={s(20)}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Button/Follow/Default"
        transform="translate(-33.000000, -20.000000)"
        fill={fill ? fill : '#FFFFFF'}
      >
        <g id="Group" transform="translate(33.000000, 20.000000)">
          <polygon id="Rectangle-3" points="9 0 11 0 11 20 9 20" />
          <rect
            id="Rectangle-3-Copy"
            transform="translate(10.000000, 10.000000) rotate(-90.000000) translate(-10.000000, -10.000000) "
            x="9"
            y="0"
            width="2"
            height="20"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default PlusIcon;
