import React from 'react';
import { withRouter } from 'react-router';
import cx from 'classnames';
import { connect } from 'react-redux';
import Button, { ButtonWithoutComposer } from '../../components/Button/Button';
import OneItemMenu from '../../components/OneItemMenu/OneItemMenu';
import GridMenusWrapper from '../../components/GridMenusWrapper';
import DotIcon from '../../img/button/dot.js';
import { updateFocus } from '../../reducers/appNavigation';
import ListMenu from '../../lib/reactv-navigation/components/ListMenu';
import {
  playPodcastEpisode,
  playPlaylist,
  playSong,
  playRadio,
  playAlbum
} from '../../reducers/station';
import { addToast } from '../../reducers/toasts';
import { setPageTitle, setPageData } from '../../reducers/view';
import { handleCardSelection } from '../../lib/utils';
import { updateLocationState } from '../../lib/reactv-redux/ReacTVReduxReducer';

import './ProfileView.scss';

class ProfileView extends React.Component {
  constructor(props) {
    super(props);

    this.addToCards = this.addToCards.bind(this);
    this.focusOnGridMenu = this.focusOnGridMenu.bind(this);
    this.leftFromGridMenu = this.leftFromGridMenu.bind(this);
    this.focusOnDotsButton = this.focusOnDotsButton.bind(this);
    this.focusOnLatestRelease = this.focusOnLatestRelease.bind(this);
    this.rightFromInfoSection = this.rightFromInfoSection.bind(this);
    this.focusOnButtonsSection = this.focusOnButtonsSection.bind(this);
    this.onEnterButtonsSection = this.onEnterButtonsSection.bind(this);
    this.isLatestReleaseFocused = this.isLatestReleaseFocused.bind(this);
    this.isButtonsSectionFocused = this.isButtonsSectionFocused.bind(this);
    this.isDotsButtonFocused = this.isDotsButtonFocused.bind(this);
    this.isGridMenuFocused = this.isGridMenuFocused.bind(this);
    this.onEnter = this.onEnter.bind(this);
  }

  componentDidMount() {
    const {
      locationStateHistory,
      appNavigation: { currentFocus },
      idForGrid,
      updateFocus,
      location
    } = this.props;

    if (currentFocus !== 'container/miniPlayer') {
      if (
        locationStateHistory[location.pathname] &&
        locationStateHistory[location.pathname].appNavigation
      ) {
        updateFocus(
          locationStateHistory[location.pathname].appNavigation.currentFocus
        );
      } else {
        updateFocus(`${idForGrid}/content`);
      }
    }
  }

  focusOnLatestRelease() {
    this.props.updateFocus(`${this.props.idForGrid}/latestRelease`);
  }

  focusOnGridMenu() {
    const firstMenu = this.props.menusData[0];
    if (!firstMenu) {
      console.warn('No menu found to focus');
      return;
    }
    this.props.updateFocus(
      `${this.props.idForGrid}/gridMenus/` + firstMenu.details.menuid
    );
  }

  onEnter(card) {
    if (this.props.onEnter) {
      this.props.onEnter(card);
    } else {
      if (this.props.albumId) {
        // selecting song from album
        card.playlistType = 'ALBUM';
        card.album = {
          id: this.props.albumId,
          tracks: this.props.menusData[0].content,
          userId: this.props.session.profileId,
          name: this.props.title
        };
      }
      handleCardSelection.call(this, card);
    }
  }

  focusOnButtonsSection() {
    this.props.updateFocus(`${this.props.idForGrid}/content`);
  }

  focusOnDotsButton() {
    this.props.updateFocus(`${this.props.idForGrid}/dotsButton`);
  }

  isLatestReleaseFocused() {
    return (
      this.props.appNavigation.currentFocus ===
      `${this.props.idForGrid}/latestRelease`
    );
  }

  isGridMenuFocused() {
    return (
      this.props.appNavigation.currentFocus ===
      `${this.props.idForGrid}/gridMenu`
    );
  }

  isButtonsSectionFocused() {
    return (
      this.props.appNavigation.currentFocus ===
      `${this.props.idForGrid}/content`
    );
  }

  isDotsButtonFocused() {
    return (
      this.props.appNavigation.currentFocus ===
      `${this.props.idForGrid}/dotsButton`
    );
  }

  rightFromInfoSection() {
    if (this.props.latestRelease) {
      this.focusOnLatestRelease();
    } else {
      if (this.props.menusData.length) this.focusOnGridMenu();
    }
  }

  leftFromGridMenu() {
    let mainContainer = document.querySelector('.mainContainer');
    if (mainContainer.scrollLeft) {
      // called when in profileView and navigating left from GridMenu
      mainContainer.scrollTo(0, 0);
    }

    if (this.props.latestRelease) {
      this.focusOnLatestRelease();
    } else {
      this.focusOnButtonsSection();
    }
  }

  onEnterButtonsSection(item) {
    if (item.id === 'playBtn') this.props.onPlay && this.props.onPlay(item);
    if (item.id === 'saveBtn') {
      this.props.onToggleSave && this.props.onToggleSave(item);
    }
  }

  addToCards({ content, details, xCards, hideViewAllCard }, index) {
    const { onDown } = this.props;
    let data = content;
    if (index === 0) {
      details.onLeft = this.leftFromGridMenu;
    }

    const cardDeck = Object.assign(
      {
        data,
        hideViewAllCard,
        xCards,
        onDown,
        customMaxWidthForTitle: 255
      },
      details
    );

    return cardDeck;
  }

  render() {
    const {
      onDown,
      idForGrid,
      thumbnail,
      background,
      buttonsData,
      appNavigation,
      showDotsButton,
      latestRelease,
      gridMenusWrapperClassName,
      infoCard,
      menusData,
      OFFSET_SCROLL,
      AMOUNT_TO_MOVE_SCROLL_BY,
      AMOUNT_TO_MOVE_SCROLL_BETWEEN_MENUS
    } = this.props;

    const ButtonComponent = ({ item, focused, onEnter }) => {
      const Icon = item.icon;
      return (
        <ButtonWithoutComposer
          className={cx('profileView__button', {
            'profileView__button--isSaved': item.isSaved || item.isFollowed
          })}
          focused={focused}
          label={item.text}
          item={item}
          onEnter={onEnter}
        >
          <Icon fill={focused ? '#000' : null} />
        </ButtonWithoutComposer>
      );
    };

    return (
      <div className="profileView">
        <div className="profileView__infoSection">
          {background && (
            <div
              className="profileView__background"
              style={{
                backgroundImage: `url(${background})`,
                left: `${-68 - appNavigation.translateXMainContent}px`
              }}
            />
          )}
          {thumbnail && (
            <img
              alt="profile"
              src={thumbnail}
              className="profileView__infoImage"
            />
          )}
          <ListMenu
            className="profileView__infoButtonsWrapper"
            data={buttonsData}
            renderItem={ButtonComponent}
            horizontal
            onEnter={this.onEnterButtonsSection}
            focused={this.isButtonsSectionFocused()}
            onDown={showDotsButton ? this.focusOnDotsButton : onDown}
            onRight={this.rightFromInfoSection}
          />
          {infoCard}
          {showDotsButton && (
            <Button
              className={cx('profileView__button profileView__button--dots')}
              focused={this.isDotsButtonFocused()}
              onUp={this.focusOnButtonsSection}
              onRight={this.rightFromInfoSection}
              onDown={onDown}
              onEnter={() => {
                const {
                  match: { url },
                  history
                } = this.props;
                history.push(`/profileDetails${url}`);
              }}
              title={'dots expand button'}
            >
              <div>
                <DotIcon fill={this.isDotsButtonFocused() ? '#000' : null} />
                <DotIcon fill={this.isDotsButtonFocused() ? '#000' : null} />
                <DotIcon fill={this.isDotsButtonFocused() ? '#000' : null} />
              </div>
            </Button>
          )}
        </div>
        {latestRelease && (
          <OneItemMenu
            focused={this.isLatestReleaseFocused()}
            title="LATEST RELEASE"
            dataContentType="ALBUM"
            big
            onLeft={this.focusOnButtonsSection}
            onRight={this.focusOnGridMenu}
            onEnter={() => {
              const {
                match: {
                  params: { artistId }
                },
                history,
                addToast,
                session
              } = this.props;
              if (!session.isPremiumUser) {
                addToast({ type: 'artist-latest' });
              }
              history.push(
                `/artist/${artistId}/albums/${latestRelease.albumId}`
              );
            }}
            onDown={onDown}
            item={latestRelease}
          />
        )}
        <GridMenusWrapper
          idForGrid={idForGrid}
          onLeft={this.leftFromGridMenu}
          onEnter={this.onEnter}
          className={cx('profileView__gridMenus', gridMenusWrapperClassName)}
          menusData={menusData.map((data, ind) => this.addToCards(data, ind))}
          OFFSET_SCROLL={OFFSET_SCROLL}
          AMOUNT_TO_MOVE_SCROLL_BY={AMOUNT_TO_MOVE_SCROLL_BY}
          AMOUNT_TO_MOVE_SCROLL_BETWEEN_MENUS={
            AMOUNT_TO_MOVE_SCROLL_BETWEEN_MENUS
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    locationStateHistory,
    appNavigation,
    view: { title },
    session
  } = state;
  return {
    locationStateHistory,
    title,
    appNavigation,
    session
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      updateLocationState,
      addToast,
      playPodcastEpisode,
      playPlaylist,
      playSong,
      playRadio,
      playAlbum,
      updateFocus,
      setPageTitle,
      setPageData
    }
  )(ProfileView)
);
