export const IHR_SEARCH_RESULTS = 'search/IHR_SEARCH_RESULTS';
export const IHR_ADD_LETTER_TO_SEARCHTERM =
  'search/IHR_ADD_LETTER_TO_SEARCHTERM';
export const IHR_REMOVE_LETTER_FROM_SEARCHTERM =
  'search/IHR_REMOVE_LETTER_FROM_SEARCHTERM';
export const IHR_SET_SEARCHTERM = 'search/IHR_SET_SEARCHTERM';
export const IHR_RESET_SEARCH = 'search/IHR_RESET_SEARCH';
export const IHR_CLEAR_RESULTS = 'search/IHR_CLEAR_RESULTS';
export const LOAD_SEARCH_NODE = 'search/LOAD_SEARCH_NODE';
export const LOAD_SEARCH_LIST = 'search/LOAD_SEARCH_LIST';
export const SET_SEARCH_LOADING = 'search/SET_SEARCH_LOADING';
export const SET_SHOW_RESULT = 'search/SET_SHOW_RESULT';
export const IHR_BEST_MATCH_CARD = 'search/IHR_BEST_MATCH_CARD';

export const clearResults = () => {
  return {
    type: IHR_CLEAR_RESULTS
  };
};
export const resetSearch = () => {
  return {
    type: IHR_RESET_SEARCH
  };
};
export const loadSearchList = (path, _this, selected, enclosingPath) => {
  return {
    type: LOAD_SEARCH_LIST,
    path,
    _this,
    selected,
    enclosingPath
  };
};
export const loadSearchNode = path => {
  return {
    type: LOAD_SEARCH_NODE,
    path
  };
};
export const searchResults = payload => {
  return {
    type: IHR_SEARCH_RESULTS,
    payload
  };
};
export const setSearchTerm = term => {
  return {
    type: IHR_SET_SEARCHTERM,
    term
  };
};

export const addSingleLetter = (letter = '') => {
  return {
    type: IHR_ADD_LETTER_TO_SEARCHTERM,
    letter: letter
      .toString()
      .toLowerCase()
      .substring(0, 1) // a little validation
  };
};

export const removeLetterFromSearchTerm = () => {
  return {
    type: IHR_REMOVE_LETTER_FROM_SEARCHTERM
  };
};

export const populateBestMatch = bestMatch => {
  return {
    type: IHR_BEST_MATCH_CARD,
    bestMatch
  };
};

export const setSearchLoading = loading => {
  return {
    type: SET_SEARCH_LOADING,
    loading
  };
};

export const setShowResult = showResult => {
  return {
    type: SET_SHOW_RESULT,
    showResult
  };
};

export const addLetterToSearchTerm = letter => dispatch => {
  dispatch(addSingleLetter(letter));
};

const parseResults = (state, payload) => {
  const data = Object.assign({}, payload);
  let count = 0;
  for (const key in data.counts) {
    count += data.counts[key];
  }
  data.counts.total = count;
  return Object.assign({}, state, data, { loading: false });
};

const ACTION_HANDLERS = {
  [SET_SHOW_RESULT]: (state, action) =>
    Object.assign({}, state, { showResult: action.showResult }),
  [SET_SEARCH_LOADING]: (state, action) =>
    Object.assign({}, state, { loading: action.loading }),
  [IHR_SEARCH_RESULTS]: (state, action) => parseResults(state, action.payload),
  [IHR_ADD_LETTER_TO_SEARCHTERM]: (state, action) =>
    Object.assign({}, state, {
      term: state.term + action.letter
    }),
  [IHR_REMOVE_LETTER_FROM_SEARCHTERM]: (state, action) =>
    Object.assign({}, state, { term: state.term.slice(0, -1) }),
  [IHR_SET_SEARCHTERM]: (state, action) =>
    Object.assign({}, state, { term: action.term }),
  [IHR_RESET_SEARCH]: (state, action) => Object.assign({}, initialState),
  [IHR_CLEAR_RESULTS]: (state, action) =>
    Object.assign({}, initialState, { term: state.term }),
  [IHR_BEST_MATCH_CARD]: (state, action) =>
    Object.assign({}, state, { topResult: action.bestMatch })
};

const initialState = {
  loading: false,
  term: ''
};

export default function counterReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
