import { getV3APIRoute, putV3APIRoute, deleteV3APIRoute } from './utils';
import store from '../store';
import { getTracksInfo } from './catalog.js';
import { setIsPageLoaded, PAGINATION_PAGE_SIZE } from '../reducers/view';
import { setIsStationPageLoaded } from '../reducers/station';
import { setLibraryPlaylists, setLibraryAlbums } from '../reducers/library';

const MY_MUSIC_COLLECTION = '/api/v3/collection/user/{profileId}/mymusic';
const MY_PLAYLIST_COLLECTION = '/api/v3/collection/user/{profileId}/collection';

export const getLibraryCollectionTracks = async (
  limit = PAGINATION_PAGE_SIZE,
  pageNumber,
  pageKey,
  shouldSetPlayerPagination,
  disableSetViewAllPage
) => {
  const { profileId } = store.getState().session;
  const tracksRoute = MY_MUSIC_COLLECTION.replace('{profileId}', profileId);
  const params = { pageKey, limit };
  const tracks = await getV3APIRoute(tracksRoute, params);
  let trackIds = [];
  if (tracks && tracks.data && tracks.data.length) {
    tracks.data.forEach(favoriteTrack => {
      trackIds.push(favoriteTrack.trackId);
    });
  }
  // The mymusic collection doesn't give an album image with each track, so we need to re-retrieve the track info from this URL
  if (trackIds.length) {
    const tracksDataWithAlbumInfo = await getTracksInfo(trackIds);
    const data =
      tracksDataWithAlbumInfo.map((item, idx) => ({
        ...item,
        libraryId: 'songs',
        viewId: '/viewAllPage/Songs/library?menuid=library-tracks',
        startFrom: idx,
        contentId: item.albumId
      })) || [];
    if (shouldSetPlayerPagination) {
      store.dispatch(
        setIsStationPageLoaded(
          pageKey ? pageNumber : 1,
          true,
          tracks.links ? tracks.links.nextPageKey : false,
          data.length
        )
      );
    }
    if (!disableSetViewAllPage) {
      store.dispatch(
        setIsPageLoaded(
          'library-tracks',
          pageKey ? pageNumber : 1,
          true,
          tracks.links ? tracks.links.nextPageKey : false,
          data.length
        )
      );
    }
    return data;
  } else {
    return [];
  }
};

export const getLibraryCollectionAlbums = async (
  limit = PAGINATION_PAGE_SIZE,
  pageNumber,
  pageKey
) => {
  const { profileId } = store.getState().session;
  const tracksRoute = MY_MUSIC_COLLECTION.replace('{profileId}', profileId);
  const params = { limit, pageKey };
  const albums = await getV3APIRoute(tracksRoute + '/albums', params);
  const data = albums && albums.data;
  if (data) {
    store.dispatch(setLibraryAlbums(data));
    store.dispatch(
      setIsPageLoaded(
        'library-albums',
        pageKey ? pageNumber : 1,
        true,
        albums.links ? albums.links.nextPageKey : false,
        data.length
      )
    );
    return data;
  } else {
    return [];
  }
};

export const checkAlbumIsInLibraryCollection = async albumId => {
  const { profileId } = store.getState().session;
  const albumRoute = '/api/v3/collection/user/{profileId}/mymusic/albums/{albumId}'
    .replace('{profileId}', profileId)
    .replace('{albumId}', albumId);

  const albums = await getV3APIRoute(albumRoute);
  return albums.data;
};

export const getLibraryCollectionPlaylists = async (params = {}) => {
  const { profileId, isPremiumUser } = store.getState().session;
  const route = MY_PLAYLIST_COLLECTION.replace('{profileId}', profileId);

  const playlists = await getV3APIRoute(route, params);
  let data = (playlists && playlists.data) || [];
  data = data.filter(
    playlist =>
      playlist.tracks &&
      playlist.tracks.length &&
      !(playlist.name === 'My Playlist' && !isPremiumUser)
  );
  store.dispatch(setLibraryPlaylists(data));
  return data;
};

export const saveTracksToLibrary = async (tracks = []) => {
  const { profileId } = store.getState().session;
  const tracksRoute = MY_MUSIC_COLLECTION.replace('{profileId}', profileId);
  const params = { tracks };
  const res = await putV3APIRoute(tracksRoute, params);
  if (res.status === 200) {
    getLibraryCollectionTracks();
  }
};

export const deleteTracksFromLibrary = async (tracks = []) => {
  const { profileId } = store.getState().session;
  let tracksRoute = MY_MUSIC_COLLECTION.replace('{profileId}', profileId);
  tracksRoute = tracksRoute + '/' + tracks.join(',');
  const res = await deleteV3APIRoute(tracksRoute);
  if (res.status === 204) {
    getLibraryCollectionTracks();
  }
};

export const saveAlbumToLibrary = async album => {
  const { session } = store.getState();
  const { profileId } = session;

  if (!album || !album.tracks) {
    return;
  }

  const tracksRoute = MY_MUSIC_COLLECTION.replace('{profileId}', profileId);
  const tracks = [];

  if (album.tracks.length) {
    album.tracks.forEach(track => {
      tracks.push(track.id ? track.id : track);
    });
  }

  const params = { tracks };
  const res = await putV3APIRoute(tracksRoute, params);
  if (res.status === 200) {
    getLibraryCollectionAlbums(999999);
  }
};

export const deleteAlbumFromLibrary = async album => {
  const { session } = store.getState();
  const { profileId } = session;

  if (!album || !album.tracks) {
    return;
  }

  let tracksRoute = MY_MUSIC_COLLECTION.replace('{profileId}', profileId);
  const tracks = [];

  if (album.tracks.length) {
    album.tracks.forEach(track => {
      tracks.push(track.id ? track.id : track);
    });
  }
  tracksRoute = tracksRoute + '/' + tracks.join(',');
  const res = await deleteV3APIRoute(tracksRoute);
  if (res.status === 204) {
    getLibraryCollectionAlbums(999999);
  }
};
