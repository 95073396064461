import React, { Component } from 'react';
import { connect } from 'react-redux';
import Player from './Player';
import {
  togglePlayPause,
  scrubBack,
  scrubForward
} from '../../reducers/audioPlayer';
import { setPageTitle } from '../../reducers/view';
import { prev, skip, playArtist } from '../../reducers/station';
import { addToast } from '../../reducers/toasts';
import { updateAddPopup } from '../../reducers/appNavigation';

import './Player.scss';

class PlayerContainer extends Component {
  constructor(props) {
    super(props);

    this.onPlayerDown = this.onPlayerDown.bind(this);
    this.onUpAddPopup = this.onUpAddPopup.bind(this);
    this.getBottomLabel = this.getBottomLabel.bind(this);
    this.downFromAddMenu = this.downFromAddMenu.bind(this);
    this.onEnterIconButton = this.onEnterIconButton.bind(this);
    this.onUpFromMiniplayer = this.onUpFromMiniplayer.bind(this);
    this.focusOnAddPopupSave = this.focusOnAddPopupSave.bind(this);
    this.focusOnAddPopupFirstItem = this.focusOnAddPopupFirstItem.bind(this);
  }

  componentDidMount() {
    const { station } = this.props;
    this.props.setPageTitle(station.fullPlayerTitle || station.title);
  }

  componentDidUpdate(prevProps) {
    const { station } = this.props;
    if (
      (prevProps.station.fullPlayerTitle || prevProps.station.title) !==
      (station.fullPlayerTitle || station.title)
    ) {
      this.props.setPageTitle(station.fullPlayerTitle || station.title);
    }
  }

  componentWillUnmount() {
    if (this.props.appNavigation.addPopup.showAddPopup) {
      this.props.updateAddPopup();
    }
  }

  onUpFromMiniplayer() {
    const {
      onUp,
      appNavigation: { addPopup }
    } = this.props;

    if (addPopup.showAddPopup) {
      this.focusOnAddPopupFirstItem();
    } else {
      onUp();
    }
  }

  focusOnAddPopupFirstItem() {
    this.props.updateAddPopup(true, 'firstItem');
  }

  focusOnAddPopupSave() {
    this.props.updateAddPopup(true, 'saveSong');
  }

  downFromAddMenu() {
    const { session, station } = this.props;
    let listMenuFocusIdx;
    if (station.contentType === 'LIVE') {
      listMenuFocusIdx = 1;
    }

    if (
      station.contentType === 'ARTIST' ||
      (station.contentType === 'PLAYLIST' && !session.isPremiumUser)
    ) {
      listMenuFocusIdx = 2;
    }

    if (
      (station.contentType === 'PLAYLIST' && session.isPremiumUser) ||
      station.contentType === 'ALBUM' ||
      station.contentType === 'PODCAST' ||
      station.contentType === 'PODCAST_EPISODE'
    ) {
      listMenuFocusIdx = 3;
    }

    this.props.updateAddPopup(false, null, listMenuFocusIdx);
  }

  onUpAddPopup() {
    this.props.onUp();
    this.props.updateAddPopup();
  }

  onPlayerDown() {
    const { history, station } = this.props;

    switch (station.contentType) {
      case 'LIVE':
        history.push(`/`); // TODO navigate to personalized stations
        break;

      case 'ALBUM':
        history.push(
          `/artist/${station.currentSong.content.artistId}/albums/${
            station.contentId
          }`
        );
        break;

      case 'ARTIST':
        history.push(`/artist/${station.sourceId}`);
        break;

      case 'PLAYLIST':
        history.push(`/playlist/${station.userId}-${station.contentId}`);
        break;

      case 'PODCAST':
      case 'PODCAST_EPISODE':
        history.push(`/podcast/${station.currentSong.content.podcastId}`);
        break;

      default:
        console.log('default');
    }
  }

  onEnterIconButton(item) {
    const {
      addToast,
      prev,
      skip,
      togglePlayPause,
      scrubBack,
      scrubForward,
      station,
      session,
      playArtist,
      appNavigation: { addPopup }
    } = this.props;

    switch (item.id) {
      case 'play-pause':
        togglePlayPause();
        return;

      case 'play-stop':
        togglePlayPause();
        return;

      case 'skip-next':
        if (
          !session.isPremiumUser &&
          station.isSkipAllowed &&
          station.isSkipAllowed.daySkipsRemaining <= 0
        ) {
          addToast({ type: 'fullplayer-daylimit' });
          return;
        }

        if (
          !session.isPremiumUser &&
          station.isSkipAllowed &&
          station.isSkipAllowed.hourSkipsRemaining <= 0
        ) {
          addToast({ type: 'fullplayer-hourlimit' });
          return;
        }

        if (
          station.isOneSong &&
          station.contentType === 'ALBUM' &&
          station.currentSong.content.artistId
        ) {
          playArtist(station.currentSong.content.artistId);
          return;
        }

        if (station.nextSongId !== null || station.contentType === 'ALBUM') {
          // next song exits or loop through album after last song
          skip();
        }
        return;

      case 'skip-back':
        if (station.currentIndex > 0) {
          prev();
        } else {
          addToast({ type: 'unsupported-key' });
        }
        return;

      case 'back':
        scrubBack();
        return;

      case 'forward':
        scrubForward();
        return;

      case 'add':
        this.props.updateAddPopup(!addPopup.showAddPopup);
        return;

      default:
        console.log('default');
    }
  }

  getBottomLabel() {
    const { station } = this.props;
    switch (station.contentType) {
      case 'LIVE':
        return 'Press down for more radio stations you may like';

      case 'ALBUM':
        return 'Press down for more album details';

      case 'ARTIST':
        return 'Press down for more songs by artist';

      case 'PLAYLIST':
        return 'Press down for more playlist details';

      case 'PODCAST':
      case 'PODCAST_EPISODE':
        return 'Press down for more episodes';

      default:
        console.log('default');
    }
  }

  render() {
    return (
      <Player
        {...this.props}
        onUpAddPopup={this.onUpAddPopup}
        onUpFromMiniplayer={this.onUpFromMiniplayer}
        focusOnAddPopupFirstItem={this.focusOnAddPopupFirstItem}
        focusOnAddPopupSave={this.focusOnAddPopupSave}
        downFromAddMenu={this.downFromAddMenu}
        getBottomLabel={this.getBottomLabel}
        onEnterIconButton={this.onEnterIconButton}
        onDown={this.onPlayerDown}
      />
    );
  }
}

const mapStateToProps = state => ({
  appNavigation: state.appNavigation,
  audioPlayer: state.audioPlayer,
  session: state.session,
  station: state.station,
  library: state.library
});

const mapDispatchToProps = {
  prev,
  skip,
  playArtist,
  scrubBack,
  scrubForward,
  addToast,
  togglePlayPause,
  setPageTitle,
  updateAddPopup
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerContainer);
