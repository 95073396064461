import React from 'react';
import cx from 'classnames';
import BaseButton from '../BaseButton/BaseButton.js';
import './AtoZMenu.scss';

class KeyboardKey extends BaseButton {
  readTTS() {
    return (
      this.props.children.props.label || this.props.children.props.children
    );
  }

  render() {
    return (
      <li {...this.props} focused={this.props.focused ? 'true' : undefined} />
    );
  }
}

const AtoZMenu = ({
  menuItems,
  isFocused,
  menu: { index },
  className,
  style
}) => (
  <div className={cx('atoZMenu', { [className]: className })} style={style}>
    <ul className="atoZMenu__list">
      {menuItems.map((Item, idx) => {
        let focused = isFocused(idx);
        return Item.component ? (
          <KeyboardKey
            focused={focused}
            key={`atoz:${Item.key}:${idx}`}
            className={cx('atoZMenu__item', {
              focused,
              [Item.className]: Item.className
            })}
          >
            <Item.component
              label={Item.key}
              fill={isFocused(idx) ? '#000' : '#fff'}
            />
          </KeyboardKey>
        ) : (
          <KeyboardKey
            focused={isFocused(idx)}
            key={`atoz:${Item}:${idx}`}
            className={cx('atoZMenu__item', { focused })}
          >
            <label>{Item}</label>
          </KeyboardKey>
        );
      })}
    </ul>
  </div>
);
export default AtoZMenu;
