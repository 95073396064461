import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import GridMenuContainer from '../GridMenu';
import { s } from '../../lib/screen-size.js';
import { updateFocus } from '../../reducers/appNavigation';
import { updateMenu } from '../../lib/reactv-redux/MenusReducer';

import './GridMenusWrapper.scss';

class GridMenusWrapper extends Component {
  constructor(props) {
    super(props);

    this.menuOnLeft = this.menuOnLeft.bind(this);
    this.menuOnRight = this.menuOnRight.bind(this);
    this.focusOnMenu = this.focusOnMenu.bind(this);
    this.updateMenuPosition = this.updateMenuPosition.bind(this);
  }

  isMenuFocused(menuId) {
    return (
      this.props.appNavigation.currentFocus ===
      `${this.props.idForGrid}/gridMenus/${menuId}`
    );
  }

  focusOnMenu(menuId) {
    this.props.updateFocus(`${this.props.idForGrid}/gridMenus/${menuId}`);
  }

  componentDidUpdate(prevProps) {
    const { navigation, idForGrid, updateMenu, first4Wide } = this.props;
    const menuData = this.props.menusData && this.props.menusData[0];
    if (!menuData) {
      return;
    }
    const oldMenuData = prevProps.menusData && prevProps.menusData[0];
    const menuId = menuData ? menuData.menuid : null;
    const lengthChanged =
      menuData &&
      oldMenuData &&
      menuData.data.length !== oldMenuData.data.length;

    if (lengthChanged) {
      this.updateMenuPosition(menuId);
    }

    const menu = menuData && navigation.menus && navigation.menus[menuId];
    const oldMenu =
      oldMenuData &&
      prevProps.navigation.menus &&
      prevProps.navigation.menus[menuId];

    const rightPositionChanged =
      menu &&
      oldMenu &&
      (menu.rightPosition !== oldMenu.rightPosition ||
        menu.leftPosition !== oldMenu.leftPosition);

    if (menu && menu.id === 'viewAllPage-mainMenu-0') {
      const hideRightFrom =
        Math.ceil((menu.index + (first4Wide ? 18 : 14)) / 5) * 5;
      if (hideRightFrom !== menu.hideRightFrom) {
        updateMenu('viewAllPage-mainMenu-0', {
          hideRightFrom
        });
        this.updateMenuPosition('viewAllPage-mainMenu-0');
      }
    }

    if (
      !navigation.menus[idForGrid] ||
      (navigation.menus[idForGrid] &&
        !navigation.menus[idForGrid].MAX_ALLOWED_SCROLL) ||
      rightPositionChanged
    ) {
      const keys = Object.keys(navigation.menus).filter(
        item => item.split('-')[0] === idForGrid
      );

      if (keys.length > 0) {
        let MAX_ALLOWED_SCROLL = 0;
        keys.forEach(item => {
          if (
            !navigation.menus[item] ||
            !navigation.menus[item].rightPosition
          ) {
            return;
          }
          const leftPosition = navigation.menus[item].leftPosition;
          const rightPosition =
            leftPosition < 0
              ? navigation.menus[item].rightPosition + -leftPosition + 64
              : navigation.menus[item].rightPosition;

          MAX_ALLOWED_SCROLL =
            MAX_ALLOWED_SCROLL < rightPosition
              ? rightPosition
              : MAX_ALLOWED_SCROLL;
        });

        const screenWidth = s(1920);
        const calculatedAllowedScroll = -MAX_ALLOWED_SCROLL + screenWidth;
        if (calculatedAllowedScroll < 0) {
          updateMenu(idForGrid, {
            MAX_ALLOWED_SCROLL: calculatedAllowedScroll
          });
        } else if (
          !navigation.menus[idForGrid] ||
          (navigation.menus[idForGrid] &&
            navigation.menus[idForGrid].MAX_ALLOWED_SCROLL !== 0)
        ) {
          updateMenu(idForGrid, {
            MAX_ALLOWED_SCROLL: 0
          });
        }
      }
    }
  }

  updateMenuPosition(menuId) {
    const { updateMenu } = this.props;
    const menu = document.querySelector(`#${menuId}`);
    if (menu) {
      const menuPositionData = menu.getBoundingClientRect();
      updateMenu(menuId, {
        leftPosition: menuPositionData.left,
        rightPosition: menuPositionData.right,
        width: menuPositionData.width
      });
    }
  }

  menuOnLeft(previousMenuId) {
    const { AMOUNT_TO_MOVE_SCROLL_BETWEEN_MENUS } = this.props;
    const moveFor = AMOUNT_TO_MOVE_SCROLL_BETWEEN_MENUS || 550;

    return () => {
      // moving leftward to the prev gridMenu (new mid)
      let mainContainer = document.querySelector('.mainContainer');
      mainContainer.scrollTo(mainContainer.scrollLeft - s(moveFor), 0);
      if (previousMenuId) this.focusOnMenu(previousMenuId);
    };
  }

  menuOnRight(nextMenuId) {
    const { AMOUNT_TO_MOVE_SCROLL_BETWEEN_MENUS } = this.props;
    const moveFor = AMOUNT_TO_MOVE_SCROLL_BETWEEN_MENUS || 550;

    return () => {
      // moving rightward to the next gridMenu (new mid)
      let mainContainer = document.querySelector('.mainContainer');
      mainContainer.scrollTo(mainContainer.scrollLeft + s(moveFor), 0);
      if (nextMenuId) this.focusOnMenu(nextMenuId);
    };
  }

  render() {
    const {
      first4Wide,
      onLeft,
      onEnter,
      menusData,
      className,
      AMOUNT_TO_MOVE_SCROLL_BY,
      OFFSET_SCROLL,
      navigation,
      idForGrid,
      withoutPadding,
      showArtistNameForSong,
      CUSTOM_PAGINATION_PAGE_SIZE
    } = this.props;

    const MAX_ALLOWED_SCROLL =
      navigation.menus[idForGrid] &&
      navigation.menus[idForGrid].MAX_ALLOWED_SCROLL;

    if (menusData && menusData.length) {
      return (
        <div
          className={cx('gridMenusWrapper', {
            'gridMenusWrapper--withoutPadding': withoutPadding,
            [className]: className
          })}
        >
          {menusData.map(
            (
              { menuid, focused, CUSTOM_AMOUNT_TO_MOVE_SCROLL_BY, ...rest },
              ind
            ) => (
              <GridMenuContainer
                {...rest}
                CUSTOM_PAGINATION_PAGE_SIZE={CUSTOM_PAGINATION_PAGE_SIZE}
                showArtistNameForSong={showArtistNameForSong}
                first4Wide={first4Wide}
                key={`grid-item-${ind}`}
                mid={menuid}
                focused={this.isMenuFocused(menuid) || focused}
                onEnter={onEnter}
                onLeft={
                  (ind === 0 && onLeft) ||
                  this.menuOnLeft(
                    menusData[ind - 1] && menusData[ind - 1].menuid
                  )
                }
                onRight={this.menuOnRight(
                  menusData[ind + 1] && menusData[ind + 1].menuid
                )}
                MAX_ALLOWED_SCROLL={MAX_ALLOWED_SCROLL}
                OFFSET_SCROLL={OFFSET_SCROLL}
                AMOUNT_TO_MOVE_SCROLL_BY={
                  CUSTOM_AMOUNT_TO_MOVE_SCROLL_BY || AMOUNT_TO_MOVE_SCROLL_BY
                }
              />
            )
          )}
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => {
  const { appNavigation, navigation } = state;

  return {
    appNavigation,
    navigation
  };
};

export default connect(
  mapStateToProps,
  { updateFocus, updateMenu }
)(GridMenusWrapper);
