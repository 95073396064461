import React, { Component } from 'react';
import KeyboardComposer from '../../lib/reactv-navigation/components/Keyboard/KeyboardComposer';
import AtoZMenu from './AtoZMenu';
import BackspaceIcon from '../../img/search/backspace.js';
import ClearIcon from '../../img/search/clear.js';
import SpaceIcon from '../../img/search/space.js';

const ComposedMenu = KeyboardComposer(AtoZMenu);

export const alphabet = 'abcdefghijklmnopqrstuvwxyz0123456789'.split('');
alphabet.push({
  component: ClearIcon,
  key: 'Clear',
  className: 'atoZMenu__item--marginLeft'
});
alphabet.push({ component: SpaceIcon, key: 'Space' });
alphabet.push({ component: BackspaceIcon, key: 'Backspace' });

class AtoZContainer extends Component {
  render() {
    return <ComposedMenu {...this.props} menuItems={alphabet} />;
  }
}

export default AtoZContainer;
