import React from 'react';
import ReactvPlatforms from '../../lib/reactv-platforms/index';

class BaseButton extends React.Component {
  componentDidMount() {
    if (this.props.focused === true) {
      if (this.readTTS) {
        ReactvPlatforms.platform.tts(this.readTTS());
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      prevProps.focused === false &&
      this.props.focused === true
    ) {
      if (this.readTTS) {
        ReactvPlatforms.platform.tts(this.readTTS());
      }
    }
  }
}

export default BaseButton;
