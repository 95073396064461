import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import toLocaleDateString from '../../lib/date';
import ProgressBar from '../ProgressBar/ProgressBar.js';
import BaseButton from '../BaseButton/BaseButton.js';
import { updateScrollPosition } from '../../reducers/appNavigation.js';
import { Touch } from '../../lib/reactv-touch';

import './ThreeRowCard.scss';

class ThreeRowCard extends BaseButton {
  constructor(p) {
    super(p);

    this.itemRef = React.createRef();
  }

  componentDidMount() {
    this.touch = new Touch(this.itemRef.current, {
      ontap: e => {
        let item = this.props.item;
        if (item._model && item._model.type === 'VIEWALL') {
          item = this.props.viewAllCardData;
        }

        this.itemRef.current.classList.add('clicked');
        let rect = this.itemRef.current.getBoundingClientRect();
        let span = document.createElement('span');

        span.classList = 'touch-gesture';
        span.style.top = `${e.startY - rect.top}px`;
        span.style.left = `${e.startX - rect.left}px`;
        span.style.height = span.style.width = `${Math.max(
          rect.width,
          rect.height
        )}px`;
        this.itemRef.current.appendChild(span);

        clearTimeout(this.emit);
        this.emit = setTimeout(() => {
          this.props.onEnter(item);
        }, 800);

        setTimeout(() => {
          this.itemRef.current &&
            this.itemRef.current.classList.remove('clicked');
        }, 550);
      }
    });
  }

  componentWillUnmount() {
    clearTimeout(this.emit);
    this.touch && this.touch.unsubscribe();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    super.componentDidUpdate(prevProps, prevState);
    if (prevProps.focused === false && this.props.focused === true) {
      if (this.itemRef.current) {
        const data = this.itemRef.current.getBoundingClientRect();
        this.props.updateScrollPosition({
          left: data.left,
          right: data.right,
          isPodcast: true
        });
      }
    }
  }

  readTTS() {
    const {
      item: { title, startDate, _model: { subtitle } } = {}
    } = this.props;
    const date =
      startDate &&
      toLocaleDateString(startDate, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

    return `${title} ${date || subtitle}`;
  }

  render() {
    const {
      item: {
        title,
        label,
        startDate,
        secondsPlayed,
        duration,
        _model: { subtitle, isViewAll }
      } = {},
      focused
    } = this.props;
    const date =
      startDate &&
      toLocaleDateString(startDate, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

    let progressBarTime =
      secondsPlayed && (secondsPlayed > duration ? duration : secondsPlayed);

    return (
      <div
        className={cx('threeRowCard', {
          'threeRowCard--focused': focused,
          'threeRowCard--viewAll': isViewAll
        })}
        ref={this.itemRef}
      >
        <div className="threeRowCard__wrapper">
          <div className="threeRowCard__titlesWrapper">
            <div className="threeRowCard__title">{title || label}</div>
            <div className="threeRowCard__subtitle">{date || subtitle}</div>
          </div>
          {progressBarTime && (
            <div className="threeRowCard__progress">
              <div className="threeRowCard__iconWrapper">
                <div className="threeRowCard__icon" />
              </div>
              <ProgressBar
                className="threeRowCard__progressBar"
                hideTime
                currentTime={progressBarTime}
                duration={duration}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { updateScrollPosition }
)(ThreeRowCard);
