import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ListMenu from '../../lib/reactv-navigation/components/ListMenu';
import Button, { ButtonWithoutComposer } from '../Button/Button';
import ProgressBar from '../ProgressBar';
import {
  togglePlayPause,
  togglePlayStop,
  scrubBack,
  scrubForward
} from '../../reducers/audioPlayer';
import { addToast } from '../../reducers/toasts';
import {
  isItemSavedInApp,
  toggleSaveSong,
  toggleSaveAlbum,
  toggleSaveStation,
  toggleFollowPodcast
} from '../../reducers/library';
import { prev, skip, playArtist } from '../../reducers/station';
import { updateFocus, updateAddPopup } from '../../reducers/appNavigation';
import { updateLocationState } from '../../lib/reactv-redux/ReacTVReduxReducer';

import PlayIcon from '../../img/player/play.js';
import PauseIcon from '../../img/player/pause.js';
import FullIcon from '../../img/player/full.js';
import AddIcon from '../../img/player/add.js';
import StopIcon from '../../img/player/stop.js';
import SkipIcon from '../../img/player/skipForward.js';
import BackIcon from '../../img/player/back.js';
import ForwardIcon from '../../img/player/forward.js';

import './MiniPlayer.scss';

class MiniPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onFocusIndex: null
    };

    this.focusOnAddPopupFirstItem = this.focusOnAddPopupFirstItem.bind(this);
    this.focusOnAddPopupSave = this.focusOnAddPopupSave.bind(this);
    this.getControlItems = this.getControlItems.bind(this);
    this.focusOnControlBar = this.focusOnControlBar.bind(this);
    this.onEnterIconButton = this.onEnterIconButton.bind(this);
    this.isAddPopupFocused = this.isAddPopupFocused.bind(this);
    this.isSaveButtonFocused = this.isSaveButtonFocused.bind(this);
    this.isControlBarFocused = this.isControlBarFocused.bind(this);
    this.onUpFromMiniplayer = this.onUpFromMiniplayer.bind(this);
    this.downFromAddMenu = this.downFromAddMenu.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location.pathname === '/player' &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.setState({ onFocusIndex: this.itemsLength - 1 });
    }

    if (!prevState.onFocusIndex && this.state.onFocusIndex) {
      window.setTimeout(() => {
        this.setState({ onFocusIndex: null });
      }, 50);
    }
  }

  focusOnControlBar() {
    this.props.updateFocus('container/miniPlayer');
  }

  focusOnAddPopupFirstItem() {
    this.props.updateAddPopup(true, 'firstItem');
  }

  focusOnAddPopupSave() {
    this.props.updateAddPopup(true, 'saveSong');
  }

  isControlBarFocused() {
    const {
      appNavigation: { currentFocus, addPopup }
    } = this.props;
    return currentFocus === 'container/miniPlayer' && !addPopup.selectedItem;
  }

  isAddPopupFocused() {
    return this.props.appNavigation.addPopup.selectedItem === 'firstItem';
  }

  isSaveButtonFocused() {
    return this.props.appNavigation.addPopup.selectedItem === 'saveSong';
  }

  downFromAddMenu() {
    const { session, station } = this.props;
    let listMenuFocusIdx;
    if (station.contentType === 'LIVE') {
      listMenuFocusIdx = 1;
    }

    if (
      station.contentType === 'ARTIST' ||
      (station.contentType === 'PLAYLIST' && !session.isPremiumUser)
    ) {
      listMenuFocusIdx = 2;
    }

    if (
      (station.contentType === 'PLAYLIST' && session.isPremiumUser) ||
      station.contentType === 'ALBUM' ||
      station.contentType === 'PODCAST_EPISODE'
    ) {
      listMenuFocusIdx = 3;
    }
    this.props.updateAddPopup(false, null, listMenuFocusIdx);
  }

  getControlItems(contentType, isPremiumUser) {
    const { audioPlayer, station, session } = this.props;
    contentType = station.contentType;
    let items = [];
    const addItem = {
      id: 'add',
      className: 'miniPlayer__button miniPlayer__button--marginLeft',
      Component: ({ focused }) => (
        <AddIcon fill={this.props.focused && focused ? '#000' : null} />
      )
    };

    const fullItem = {
      id: 'full',
      className: 'miniPlayer__button',
      Component: ({ focused }) => (
        <FullIcon fill={this.props.focused && focused ? '#000' : null} />
      )
    };

    const playStop = {
      id: 'play-stop',
      className: 'miniPlayer__button',
      Component: ({ focused }) =>
        audioPlayer.DO_PLAY ? (
          <StopIcon fill={this.props.focused && focused ? '#000' : null} />
        ) : (
          <PlayIcon fill={this.props.focused && focused ? '#000' : null} />
        )
    };

    const playPause = {
      id: 'play-pause',
      className: 'miniPlayer__button',
      Component: ({ focused }) =>
        audioPlayer.DO_PLAY ? (
          <PauseIcon fill={this.props.focused && focused ? '#000' : null} />
        ) : (
          <PlayIcon fill={this.props.focused && focused ? '#000' : null} />
        )
    };

    this.skipNext = {
      id: 'skip-next',
      className: 'miniPlayer__button',
      Component: ({ focused, disabled }) => (
        <SkipIcon
          fill={disabled || (this.props.focused && focused) ? '#000' : null}
        />
      )
    };

    this.skipBack = {
      id: 'skip-back',
      className: 'miniPlayer__button miniPlayer__button--rotate',
      Component: ({ focused, disabled }) => (
        <SkipIcon
          fill={disabled || (this.props.focused && focused) ? '#000' : null}
        />
      )
    };

    const back = {
      id: 'back',
      className: 'miniPlayer__button',
      Component: ({ focused }) => (
        <BackIcon fill={this.props.focused && focused ? '#000' : null} />
      )
    };

    const forward = {
      id: 'forward',
      className: 'miniPlayer__button',
      Component: ({ focused }) => (
        <ForwardIcon fill={this.props.focused && focused ? '#000' : null} />
      )
    };

    if (
      !session.isPremiumUser &&
      !station.isOneSong &&
      ((station.nextSongId === null && contentType !== 'ALBUM') ||
        !(
          station.isSkipAllowed &&
          station.isSkipAllowed.daySkipsRemaining > 0 &&
          station.isSkipAllowed.hourSkipsRemaining > 0
        ))
    ) {
      this.skipNext.disabled = true;
    }

    if (contentType === 'LIVE') {
      addItem.className = 'miniPlayer__button';
      items.push(playStop);
    }

    if (
      contentType === 'ARTIST' ||
      contentType === 'FAVORITES' ||
      (contentType === 'PLAYLIST' && !isPremiumUser)
    ) {
      items.push(playPause, this.skipNext);
    }

    if (
      (contentType === 'PLAYLIST' && isPremiumUser) ||
      contentType === 'ALBUM'
    ) {
      // only OD are able to play album so there are no checks for user type
      items.push(this.skipBack, playPause, this.skipNext);
    }

    if (contentType === 'PODCAST_EPISODE') {
      items.push(back, playPause, forward);
    }

    items.push(addItem, fullItem);
    this.itemsLength = items.length;
    return items;
  }

  onEnterIconButton(item) {
    const {
      station,
      addToast,
      prev,
      skip,
      togglePlayPause,
      togglePlayStop,
      scrubBack,
      scrubForward,
      playArtist,
      appNavigation: { addPopup }
    } = this.props;

    switch (item.id) {
      case 'play-pause':
        togglePlayPause();
        return;

      case 'play-stop':
        togglePlayStop();
        return;

      case 'full':
        this.props.updateAddPopup();
        this.props.updateLocationState(
          this.props.location.pathname,
          Object.assign(this.props.appNavigation, {
            lastScrollValue: document.querySelector('.mainContainer').scrollLeft
          })
        );
        this.props.history.push('/player');
        return;

      case 'skip-next':
        if (this.skipNext.disabled !== true) {
          if (
            station.isOneSong &&
            station.contentType === 'ALBUM' &&
            station.currentSong.content.artistId
          ) {
            playArtist(station.currentSong.content.artistId);
            return;
          }

          skip();
        } else {
          if (
            station.isSkipAllowed &&
            station.isSkipAllowed.daySkipsRemaining <= 0
          ) {
            addToast({ type: 'miniplayer-daylimit' });
            return;
          }

          if (
            station.isSkipAllowed &&
            station.isSkipAllowed.hourSkipsRemaining <= 0
          ) {
            addToast({ type: 'miniplayer-hourlimit' });
            return;
          }
        }
        return;

      case 'skip-back':
        if (this.skipBack.disabled !== true) {
          prev();
        } else {
          addToast({ type: 'unsupported-key' });
        }
        return;

      case 'back':
        scrubBack();
        return;

      case 'forward':
        scrubForward();
        return;

      case 'add':
        this.props.updateAddPopup(!addPopup.showAddPopup);
        return;

      default:
        console.log('default');
    }
  }

  renderAddPopup(contentType, isPremiumUser) {
    const { station, onUp, addToast, appNavigation, library } = this.props;
    let id;
    switch (station.contentType) {
      case 'ARTIST':
        id = station.sourceId;
        break;
      case 'PODCAST_EPISODE':
        id =
          station.currentSong &&
          station.currentSong.content &&
          station.currentSong.content.podcastId;
        break;
      case 'PLAYLIST':
        id = `${station.userId}-${station.contentId}`;
        break;
      default:
        id = station.contentId;
    }
    let itemIsInLibrary = isItemSavedInApp(library, station.contentType, id);

    if (!appNavigation.addPopup.showAddPopup) {
      return null;
    }
    let items = [];

    let saveSong = {
      id: 'saveSong',
      title: 'Save Song',
      onEnter: () => {
        let songId =
          station.currentSong && station.currentSong.content
            ? station.currentSong.content.id
            : null;
        toggleSaveSong(songId);
        addToast({ type: 'miniplayer-save-song' });
      }
    };

    let saveAlbum = {
      id: 'saveAlbum',
      title: itemIsInLibrary ? 'Remove Album' : 'Save Album',
      onEnter: () => {
        toggleSaveAlbum({
          tracks: station.playlist,
          albumId: station.contentId
        });
      }
    };

    let saveStation = {
      id: 'saveStation',
      title:
        (station.contentType === 'LIVE' || station.contentType === 'ARTIST') &&
        itemIsInLibrary
          ? 'Remove Station'
          : 'Save Station',
      onEnter: () => {
        toggleSaveStation(station.contentType, id);
      }
    };

    let followPodcast = {
      id: 'followPodcast',
      title: itemIsInLibrary ? 'Remove Podcast' : 'Follow Podcast',
      onEnter: () => {
        toggleFollowPodcast(station.currentSong.content.podcastId);
      }
    };

    const onUpAddPopup = () => {
      onUp();
      this.props.updateAddPopup(false, null);
    };

    const onEnterDisabledSaveSong = () => {
      addToast({ type: 'miniplayer-save-disabled' });
    };

    if (contentType === 'LIVE') {
      saveStation.isFocused = this.isAddPopupFocused;
      saveStation.onUp = onUpAddPopup;
      saveStation.onDown = this.downFromAddMenu;
      saveStation.onLeft = this.downFromAddMenu;
      saveStation.onRight = this.downFromAddMenu;

      items.push(saveStation);
    } else if (contentType === 'ARTIST' || contentType === 'PLAYLIST') {
      if (
        contentType === 'PLAYLIST' &&
        station.fullPlayerTitle === 'Your Weekly Mixtape'
      ) {
        saveSong.isFocused = this.isAddPopupFocused;
        saveSong.onUp = onUpAddPopup;
        saveSong.onDown = this.downFromAddMenu;
        saveSong.onLeft = this.downFromAddMenu;
        saveSong.onRight = this.downFromAddMenu;

        if (!isPremiumUser) {
          saveSong.disabled = true;
          saveSong.onEnter = onEnterDisabledSaveSong;
        }

        items.push(saveSong);
      } else {
        saveSong.isFocused = this.isSaveButtonFocused;
        saveSong.onUp = onUpAddPopup;
        saveSong.onDown = this.focusOnAddPopupFirstItem;
        saveSong.onLeft = this.downFromAddMenu;
        saveSong.onRight = this.downFromAddMenu;
        if (contentType === 'PLAYLIST') {
          saveStation.title = itemIsInLibrary
            ? 'Remove Playlist'
            : 'Follow Playlist';
        }
        saveStation.isFocused = this.isAddPopupFocused;
        saveStation.onUp = this.focusOnAddPopupSave;
        saveStation.onDown = this.downFromAddMenu;
        saveStation.onLeft = this.downFromAddMenu;
        saveStation.onRight = this.downFromAddMenu;

        if (!isPremiumUser) {
          saveSong.disabled = true;
          saveSong.onEnter = onEnterDisabledSaveSong;
        }

        items.push(saveSong, saveStation);
      }
    } else if (contentType === 'ALBUM') {
      saveSong.isFocused = this.isSaveButtonFocused;
      saveSong.onUp = onUpAddPopup;
      saveSong.onDown = this.focusOnAddPopupFirstItem;
      saveSong.onLeft = this.downFromAddMenu;
      saveSong.onRight = this.downFromAddMenu;

      saveAlbum.isFocused = this.isAddPopupFocused;
      saveAlbum.onUp = this.focusOnAddPopupSave;
      saveAlbum.onDown = this.downFromAddMenu;
      saveAlbum.onLeft = this.downFromAddMenu;
      saveAlbum.onRight = this.downFromAddMenu;

      if (!isPremiumUser) {
        saveSong.disabled = true;
        saveSong.onEnter = onEnterDisabledSaveSong;
      }

      items.push(saveSong, saveAlbum);
    } else if (contentType === 'FAVORITES') {
      saveSong.isFocused = this.isAddPopupFocused;
      saveSong.onUp = onUpAddPopup;
      saveSong.onDown = this.downFromAddMenu;
      saveSong.onLeft = this.downFromAddMenu;
      saveSong.onRight = this.downFromAddMenu;

      if (!isPremiumUser) {
        saveSong.disabled = true;
      }

      items.push(saveSong);
    } else if (contentType === 'PODCAST_EPISODE') {
      followPodcast.isFocused = this.isAddPopupFocused;
      followPodcast.onUp = onUpAddPopup;
      followPodcast.onDown = this.downFromAddMenu;
      followPodcast.onLeft = this.downFromAddMenu;
      followPodcast.onRight = this.downFromAddMenu;
      items.push(followPodcast);
    }

    return (
      <div className="miniPlayer__addPopup">
        {items.map((item, ind) => (
          <Button
            className="miniPlayer__addPopupItem"
            item={item}
            key={`addPopup-${ind}`}
            focused={item.isFocused()}
            onDown={item.onDown}
            onUp={item.onUp}
            onLeft={item.onLeft}
            onRight={item.onRight}
            onEnter={item.onEnter}
          />
        ))}
      </div>
    );
  }

  onUpFromMiniplayer() {
    const {
      onUp,
      appNavigation: { addPopup }
    } = this.props;

    if (addPopup.showAddPopup) {
      this.focusOnAddPopupFirstItem();
    } else {
      this.props.updateAddPopup();
      onUp();
    }
  }

  render() {
    const {
      hide,
      session,
      station,
      appNavigation: { addPopup }
    } = this.props;
    const { onFocusIndex } = this.state;
    if (!station || Object.entries(station).length < 2) return null;
    const menuItems = this.getControlItems(
      station.contentType,
      session.isPremiumUser
    );

    if (hide) {
      return null;
    }

    let playerImage =
      station.image ||
      (station.liveRadioCurrentSong && station.liveRadioCurrentSong.imagePath);
    let playerTitle =
      (station.liveRadioCurrentSong && station.liveRadioCurrentSong.title) ||
      station.title;
    let playerSubtitle =
      (station.liveRadioCurrentSong && station.liveRadioCurrentSong.artist) ||
      station.subtitle;

    return (
      <div className="miniPlayer">
        {this.renderAddPopup(station.contentType, session.isPremiumUser)}
        <div className="miniPlayer__trackInfoWrapper">
          {playerImage ? (
            <img className="miniPlayer__image" alt="" src={playerImage} />
          ) : (
            <div className="miniPlayer__image" />
          )}
          <div className="miniPlayer__titlesWrapper">
            <div className="miniPlayer__title">{playerTitle}</div>
            <div className="miniPlayer__desc">{playerSubtitle}</div>
          </div>
        </div>
        <div className="miniPlayer__controls">
          <ListMenu
            data={menuItems}
            renderItem={ButtonWithoutComposer}
            className="miniPlayer__listMenu"
            horizontal
            onEnter={this.onEnterIconButton}
            focused={this.isControlBarFocused()}
            onUp={this.onUpFromMiniplayer}
            contextualState={addPopup.showAddPopup}
            onFocusIndex={
              onFocusIndex
                ? onFocusIndex
                : this.isControlBarFocused()
                ? addPopup.listMenuFocusIdx
                : -1
            }
          />
          {station.contentType !== 'LIVE' && (
            <ProgressBar className="miniPlayer__progressBar" />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    audioPlayer: state.audioPlayer,
    session: state.session,
    station: state.station,
    appNavigation: state.appNavigation,
    library: state.library
  };
};

export default connect(
  mapStateToProps,
  {
    addToast,
    playArtist,
    prev,
    skip,
    togglePlayPause,
    togglePlayStop,
    updateFocus,
    updateAddPopup,
    updateLocationState,
    scrubBack,
    scrubForward
  }
)(withRouter(MiniPlayer));
