import React from 'react';
import { s } from '../../lib/screen-size';

const PlayIcon = ({ fill }) => (
  <svg
    width={s(21)}
    height={s(26)}
    viewBox="0 0 21 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Button/FPlay"
        transform="translate(-35.000000, -17.000000)"
        fill={fill ? fill : '#FFFFFF'}
      >
        <path
          d="M35,18.9440375 L35,39.0559657 C35,40.5895988 36.8008871,41.5214264 38.1877772,40.6866641 L55.0374569,30.6307098 C56.3208477,29.8735998 56.3208477,28.1264229 55.0374569,27.3498998 L38.1877772,17.3133352 C36.8008871,16.4785729 35,17.4104045 35,18.9440375 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

export default PlayIcon;
