import React from 'react';

const Icon = ({ fill }) => (
  <svg
    width="22px"
    height="8px"
    viewBox="0 0 22 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Keyboard" transform="translate(-193.000000, -600.000000)">
        <g id="Buttons" transform="translate(108.000000, 580.000000)">
          <g id="Button/Space" transform="translate(72.000000, 0.000000)">
            <rect id="Rectangle-3" x="0" y="0" width="48" height="48" />
            <path
              d="M32.25,21.3333333 L32.25,25.3333333 L15.75,25.3333333 L15.75,21.3333333 C15.75,20.6 15.13125,20 14.375,20 C13.61875,20 13,20.6 13,21.3333333 L13,26.6666667 C13,27.4 13.61875,28 14.375,28 L33.625,28 C34.38125,28 35,27.4 35,26.6666667 L35,21.3333333 C35,20.6 34.38125,20 33.625,20 C32.86875,20 32.25,20.6 32.25,21.3333333 Z"
              id="Shape"
              fill={fill ? fill : '#FFFFFF'}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
