import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactvPlatforms from '../../lib/reactv-platforms/index';
import ListMenu from '../../lib/reactv-navigation/components/ListMenu';
import { updateFocus } from '../../reducers/appNavigation';
import { ButtonWithoutComposer } from '../Button/Button';

import './ExitModal.scss';

class ExitModal extends Component {
  constructor(p) {
    super(p);

    this.state = { isTtsTriggered: false };

    this.updateTts = this.updateTts.bind(this);
  }

  updateTts(isTtsTriggered) {
    this.setState({ isTtsTriggered });
  }

  render() {
    const { appNavigation, updateFocus } = this.props;
    const { isTtsTriggered } = this.state;
    if (appNavigation && appNavigation.currentFocus !== 'exitModal') {
      return null;
    }

    const buttons = [
      {
        id: 'confirmed',
        className: 'exitModal__button',
        title: 'Yes, I want to exit',
        tts:
          'Exit this Application. Are you sure you want to exit the application?',
        isTtsTriggered,
        updateTts: this.updateTts
      },
      {
        id: 'cancelled',
        className: 'exitModal__button',
        title: 'No, keep me here'
      }
    ];

    const onEnter = item => {
      switch (item.id) {
        case 'confirmed':
          ReactvPlatforms.platform.exit();
          break;
        case 'cancelled':
          updateFocus('container/mainMenu');
          break;
        default:
          break;
      }
    };

    return (
      <div className="exitModal">
        <div className="exitModal__inner">
          <div className="exitModal__head">Exit this Application</div>
          <div className="exitModal__subhead">
            Are you sure you want to exit the application?
          </div>
          <ListMenu
            data={buttons}
            renderItem={ButtonWithoutComposer}
            className="exitModal__buttonsWrapper"
            horizontal
            onEnter={onEnter}
            focused
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { appNavigation } = state;

  return {
    appNavigation: appNavigation
  };
};

export default connect(
  mapStateToProps,
  { updateFocus }
)(ExitModal);
