import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Search from './Search';
import { search } from '../../api/search';
import { getTracksInfo } from '../../api/catalog';
import { trending } from '../../api/trending';
import { getArtistProfile } from '../../api/artists';
import { updateCardPiles } from '../../reducers/cards';
import { playSong, playRadio, playArtist } from '../../reducers/station';
import { setPageTitle, setPageData } from '../../reducers/view';
import { updateFocus } from '../../reducers/appNavigation';
import {
  searchResults,
  addSingleLetter,
  removeLetterFromSearchTerm,
  loadSearchNode,
  populateBestMatch,
  clearResults,
  resetSearch,
  setSearchLoading
} from '../../reducers/search';
import { addToast } from '../../reducers/toasts';
import {
  clearAllPageMenus,
  updateMenu
} from '../../lib/reactv-redux/MenusReducer';
import { updateLocationState } from '../../lib/reactv-redux/ReacTVReduxReducer';

import './Search.scss';
import { handleCardSelection, prepareForSearch } from '../../lib/utils';

const CancelToken = axios.CancelToken;

class SearchContainer extends Component {
  constructor(p) {
    super(p);

    this.state = {
      isTouchFieldFocused: false
    };

    this.idForGrid = 'search'; // should match with path page
    this.keyupListener = this.keyupListener.bind(this);
    this.onLetter = this.onLetter.bind(this);
    this.handleCardOnEnter = this.handleCardOnEnter.bind(this);
    this.updateIsTouchFieldFocused = this.updateIsTouchFieldFocused.bind(this);
  }

  async componentDidMount() {
    const {
      trends,
      updateCardPiles,
      appNavigation,
      view,
      updateFocus,
      setPageTitle
    } = this.props;
    if (!trends) {
      const trends = await trending();
      updateCardPiles([{ key: 'trends', data: trends }]);
    }

    if (process.env.REACT_APP_TOUCH === 'true') {
      let elem = document.querySelector('#touchField');
      elem.oninput = e => {
        this.onLetter(e.data || '', e.inputType);
      };
    }

    if (this.props.menu && this.props.menu.appNavigation) {
      document
        .querySelector('.mainContainer')
        .scrollTo(this.props.menu.appNavigation.lastScrollValue || 0, 0);
    }

    if (!appNavigation.currentFocus) {
      updateFocus('container/mainMenu');
    }

    if (view.title) {
      setPageTitle();
    }
  }

  componentWillUnmount() {
    if (process.env.REACT_APP_TOUCH === 'true') {
      this.props.resetSearch();
      let elem = document.querySelector('#touchField');
      elem.oninput = null;
      document.removeEventListener('keyup', this.keyupListener);
    }
  }

  keyupListener(event) {
    if (document.activeElement === document.getElementById('touchField')) {
      this.onLetter(event.key);
    }
  }

  updateIsTouchFieldFocused(value) {
    this.setState({ isTouchFieldFocused: value });
  }

  async onLetter(letter, inputType) {
    if (this.searchTimeout) {
      window.clearTimeout(this.searchTimeout);
      this.searchTimeout = null;
    }

    if (letter.key === 'Space' || letter === ' ') {
      this.props.addSingleLetter(' ');
    } else if (
      letter.key === 'Backspace' ||
      letter === 'Backspace' ||
      inputType === 'deleteContentBackward'
    ) {
      this.props.removeLetterFromSearchTerm();
    } else if (letter.key === 'Clear') {
      this.props.resetSearch();
    } else if (letter.length <= 1) {
      this.props.addSingleLetter(letter);
    }

    const {
      resetSearch,
      populateBestMatch,
      clearResults,
      searchResults,
      clearAllPageMenus,
      updateMenu,
      setSearchLoading,
      search: { term }
    } = this.props;
    if (term === '') {
      resetSearch(); // clear results & search term
      updateMenu(this.idForGrid, {
        MAX_ALLOWED_SCROLL: -194
      });
      return;
    }
    if (term.length <= 1) return;
    if (this.source) {
      this.source.cancel('Operation is canceled');
    }
    this.source = CancelToken.source();
    const tocken = this.source && this.source.token;
    const params = {
      maxRows: 20,
      track: true,
      artist: true,
      bundle: true,
      station: true,
      featuredStation: true,
      talkShow: true,
      playlist: true,
      podcast: true,
      talkTheme: true
    };
    setSearchLoading(true);
    this.searchTimeout = window.setTimeout(async () => {
      const _search = await search(term, params, tocken);
      if (!_search || !_search.results) return;
      _search.results = prepareForSearch(_search.results);
      const { counts, bestMatch } = _search;
      let count = 0;
      for (let key in counts) {
        count += counts[key];
        if (count > 0) break;
      }
      if (count > 0 && bestMatch) {
        let profile;
        const { id, format } = bestMatch;
        if (format === 'ARTIST') {
          profile = await getArtistProfile(id);
        } else if (format === 'TRACK') {
          profile = await getTracksInfo(id);
        }
        if (profile) populateBestMatch(profile);
        clearResults();
        clearAllPageMenus(this.idForGrid);
        searchResults(_search);
      } else {
        clearResults(); // clear results only, not search term
      }
    }, 2000);
  }

  async handleCardOnEnter(card) {
    handleCardSelection.call(this, card);
  }

  render() {
    const { trends, search } = this.props;
    if (trends) {
      return (
        <Search
          resetSearch={resetSearch}
          isTouchFieldFocused={this.state.isTouchFieldFocused}
          updateIsTouchFieldFocused={this.updateIsTouchFieldFocused}
          onSelection={this.handleCardOnEnter}
          onLetter={this.onLetter}
          idForGrid={this.idForGrid}
          isEmpty={
            search.term.length > 1
              ? !search.counts || (search.counts && search.counts.total === 0)
              : false
          }
          {...this.props}
        />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  menu: state.locationStateHistory['/search'],
  appNavigation: state.appNavigation,
  trends: state.cards.trends,
  search: state.search,
  view: state.view,
  session: state.session
});

const mapDispatchToProps = {
  playSong,
  playRadio,
  playArtist,
  searchResults,
  updateCardPiles,
  updateFocus,
  addSingleLetter,
  removeLetterFromSearchTerm,
  loadSearchNode,
  populateBestMatch,
  clearResults,
  resetSearch,
  clearAllPageMenus,
  updateMenu,
  updateLocationState,
  setPageTitle,
  setPageData,
  setSearchLoading,
  addToast
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchContainer);
