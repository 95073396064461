import React, { Component } from 'react';
import { connect } from 'react-redux';
import GridMenusWrapper from '../../components/GridMenusWrapper';
import { ButtonWithoutComposer } from '../../components/Button/Button';
import {
  setPageTitle,
  setPageData,
  setViewAllStatus
} from '../../reducers/view';
import AddIcon from '../../img/player/add.js';
import {
  playSong,
  playFavorites,
  playRadio,
  playArtist,
  playPlaylist,
  playTracks,
  playAlbum
} from '../../reducers/station';
import { updateFocus } from '../../reducers/appNavigation';
import { addToast } from '../../reducers/toasts';
import { handleCardSelection } from '../../lib/utils';
import { clearMenu, updateMenu } from '../../lib/reactv-redux/MenusReducer';
import { updateLocationState } from '../../lib/reactv-redux/ReacTVReduxReducer';
import { s } from '../../lib/screen-size';
import querystring from 'querystring';

import './ViewAllPage.scss';

const prepareViewAllData = (data, type, onDown) => {
  if (!data) {
    return [];
  }

  let menusData = [];
  menusData = [
    {
      onDown,
      menuid: `viewAllPage-mainMenu-0`,
      data,
      dataContentType: type
    }
  ];

  return menusData;
};

class ViewAllPage extends Component {
  constructor(p) {
    super(p);

    this.idForGrid = 'viewAllPage'; // should match with path page
    this.first4Wide =
      p.viewAllSource === 'search' || p.viewAllSource === 'library';
    this.AMOUNT_TO_MOVE_SCROLL_BY = s(this.first4Wide ? 500 : 450);
  }

  componentDidMount() {
    const {
      appNavigation: { currentFocus },
      location: { search, pathname },
      setPageTitle,
      updateFocus,
      isEmpty,
      match,
      all
    } = this.props;
    const title = match.params.title;
    let { menuid: mid } = querystring.parse(
      decodeURI(search.replace(/^\?/, ''))
    );
    let contentType;

    if (
      mid.toLowerCase().includes('songs') ||
      mid.toLowerCase().includes('tracks')
    ) {
      contentType = 'SONG';
    } else if (mid.toLowerCase().includes('albums')) {
      contentType = 'ALBUM';
    } else if (mid.toLowerCase().includes('artist')) {
      contentType = 'ARTIST';
    } else if (mid.toLowerCase().includes('podcast')) {
      contentType = 'PODCAST';
    } else if (mid.toLowerCase().includes('playlist')) {
      contentType = 'PLAYLIST';
    }

    if (all && all[pathname + search]) {
      let viewAllSource = pathname.split('/')[3];
      this.first4Wide =
        viewAllSource === 'search' || viewAllSource === 'library';
      this.AMOUNT_TO_MOVE_SCROLL_BY = s(this.first4Wide ? 500 : 450);
      this.props.setPageData(
        all[pathname + search],
        contentType,
        pathname + search,
        viewAllSource
      );
    }
    if (title) {
      setPageTitle(title);
    }
    const isViewAllFocused = currentFocus.includes('viewAll');
    const isMiniPlayerFocused = currentFocus === 'container/miniPlayer';

    if (isEmpty) {
      updateFocus('container/miniPlayer');
    } else if (!isViewAllFocused && !isMiniPlayerFocused) {
      updateFocus('viewAllPage/gridMenus/viewAllPage-mainMenu-0');
    }

    if (this.props.menu && this.props.menu.appNavigation) {
      document
        .querySelector('.mainContainer')
        .scrollTo(this.props.menu.appNavigation.lastScrollValue || 0, 0);
    } else {
      document.querySelector('.mainContainer').scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    const {
      setPageTitle,
      clearMenu,
      updateMenu,
      setViewAllStatus
    } = this.props;
    setPageTitle();
    clearMenu(this.idForGrid);
    updateMenu(this.idForGrid, {
      MAX_ALLOWED_SCROLL: 0
    });
    setViewAllStatus({ isEmpty: false });
  }

  render() {
    const { onDown, viewAllContentType, viewAllData, isEmpty } = this.props;

    if (isEmpty) {
      return (
        <div className="viewAllPage viewAllPage--noData">
          You have no saved {isEmpty}. In the player, press the{' '}
          <ButtonWithoutComposer className="viewAllPage__addButton">
            <AddIcon />
          </ButtonWithoutComposer>{' '}
          icon to add a station to your library
        </div>
      );
    }

    const isFromSearch = this.props.match.params.redirectedFrom === 'search';
    return (
      <div className="viewAllPage">
        <GridMenusWrapper
          AMOUNT_TO_MOVE_SCROLL_BY={this.AMOUNT_TO_MOVE_SCROLL_BY}
          idForGrid={this.idForGrid}
          first4Wide={this.first4Wide}
          menusData={prepareViewAllData(
            viewAllData,
            viewAllContentType,
            onDown
          )}
          onEnter={card => {
            handleCardSelection.call(this, card);
          }}
          withoutPadding
          showArtistNameForSong={isFromSearch}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    view: {
      all,
      viewAllMenuId,
      viewAllData,
      viewAllContentType,
      isEmpty,
      viewAllSource
    },
    appNavigation,
    session,
    library,
    locationStateHistory
  } = state;
  return {
    menu:
      appNavigation.page.includes('viewAllPage') &&
      locationStateHistory[appNavigation.page],
    all,
    viewAllMenuId,
    viewAllData,
    viewAllSource,
    viewAllContentType,
    appNavigation,
    session,
    isEmpty,
    library,
    'library-tracks': state.view['library-tracks']
  };
};

export default connect(
  mapStateToProps,
  {
    playRadio,
    playArtist,
    playPlaylist,
    playFavorites,
    playSong,
    playTracks,
    playAlbum,
    setPageTitle,
    setPageData,
    updateFocus,
    clearMenu,
    updateMenu,
    updateLocationState,
    setViewAllStatus,
    addToast
  }
)(ViewAllPage);
