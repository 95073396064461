export const REACTV_MENU_UPDATE = 'reactv-redux/REACTV_MENU_UPDATE';
export const REACTV_CLEAR_MENUS_FOCUS = 'reactv-redux/REACTV_CLEAR_MENUS_FOCUS';
export const REACTV_CLEAR_MENU = 'reactv-redux/REACTV_CLEAR_MENU';
export const REACTV_CLEAR_ALL_PAGE_MENUS =
  'reactv-redux/REACTV_CLEAR_ALL_PAGE_MENUS';

export function updateMenu(id, payload) {
  return {
    type: REACTV_MENU_UPDATE,
    id,
    payload
  };
}

export function clearMenusFocus(page) {
  let pageToClear = page;

  if (page.includes('artist')) {
    pageToClear = 'artist';
  }

  if (page.includes('artist') && page.includes('albums')) {
    pageToClear = 'albums';
  }

  if (page.includes('podcast')) {
    pageToClear = 'podcast';
  }

  if (page.includes('playlist')) {
    pageToClear = 'playlist';
  }

  if (page.includes('viewAllPage')) {
    pageToClear = 'viewAllPage';
  }
  return {
    type: REACTV_CLEAR_MENUS_FOCUS,
    page: pageToClear
  };
}

export function clearMenu(page) {
  return {
    type: REACTV_CLEAR_MENU,
    page
  };
}

export function clearAllPageMenus(page) {
  return {
    type: REACTV_CLEAR_ALL_PAGE_MENUS,
    page
  };
}

const defaultState = {
  menus: {}
};

export default function navigationReducer(state = defaultState, action) {
  let newMenus, menusKeys;
  switch (action.type) {
    case REACTV_MENU_UPDATE:
      const menus = Object.assign({}, state.menus);
      menus[action.id] = Object.assign(
        { id: action.id },
        menus[action.id],
        action.payload
      );
      return { menus };
    case REACTV_CLEAR_MENUS_FOCUS:
      newMenus = Object.assign({}, state.menus);
      menusKeys = Object.keys(newMenus);
      menusKeys.forEach(item => {
        if (item.startsWith(action.page) && newMenus[item]) {
          newMenus[item].index = 0;
        }
      });
      return { menus: newMenus };
    case REACTV_CLEAR_MENU:
      newMenus = Object.assign({}, state.menus);
      menusKeys = Object.keys(newMenus);
      menusKeys.forEach(item => {
        if (item === action.page) newMenus[item] = null;
      });
      return { menus: newMenus };
    case REACTV_CLEAR_ALL_PAGE_MENUS:
      newMenus = Object.assign({}, state.menus);
      menusKeys = Object.keys(newMenus);
      menusKeys.forEach(item => {
        if (item.startsWith(action.page)) newMenus[item] = null;
      });
      return { menus: newMenus };
    default:
      return state;
  }
}
