import axios from 'axios';
import { getV2Headers } from './utils';
import { BASE_URL, WEB_API, LEADS, routes } from '../constants';
import store from '../store';

const trendingEndpoints = {
  LIVE_STATIONS: {
    url: BASE_URL + routes['LIVE_STATIONS'],
    contentType: 'LIVE',
    params: {
      allMarkets: false,
      countryCode: 'US',
      limit: 20,
      offset: 0
    }
  },
  TOP_ARTISTS: {
    url: BASE_URL + routes['TOP_ARTISTS'],
    contentType: 'ARTIST',
    params: {
      genreId: 102,
      limit: 10,
      offset: 0,
      template: 'CR'
    }
  },
  GRAPH_QL: {
    // TODO: Need to add query in params object
    url:
      WEB_API +
      routes['GRAPH_QL'] +
      '?query=query%20%7B%20leads(%20query%3A%20%7B%0Asubscription%3A%20%7B%20tags%3A%20%5B%22collections%2Fplaylist-directory%22%2C%20%22facets%2Ffeatured-playlists%22%5D%7D%7D%20)%20%7B%0Atitle%0Asubtitle%0Alink%20%7B%0Aname%0Adescription%0Aurls%20%7B%0Adevice%0Aweb%0A%7D%0A%7D%0Aimg_uri%0A%7D%0A%7D%0A',
    contentType: 'PLAYLIST',
    params: {}
  },
  CARDS: {
    url: LEADS + routes['CARDS'],
    contentType: 'PODCAST',
    params: {
      collection: 'collections/featured-podcasts',
      country: 'US'
    }
  }
};

const apiCall = async ({ url, params }, headers) => {
  try {
    const response = await axios.get(url, { headers, params });
    const { status, data } = response;
    if (status >= 200) {
      return (
        data.hits || data.values || data.cards || (data.data && data.data.leads)
      );
    } else {
      const err = new Error();
      err.status = status;
      throw err;
    }
  } catch (err) {
    console.error('Error while executing trending', err);
    throw err;
  }
};

const trending = async () => {
  const { sessionId, profileId } = store.getState().session;
  const headers = getV2Headers(sessionId, profileId);
  try {
    let dataObj = {},
      data = [];
    let total = 0;
    for (let endpoint in trendingEndpoints) {
      const endpointData = await apiCall(trendingEndpoints[endpoint], headers);
      if (endpointData && endpointData.length) {
        total += endpointData.length;
        dataObj[endpoint] = endpointData.map(item => {
          item.contentType = trendingEndpoints[endpoint].contentType;
          return item;
        });
      }
    }

    let i = 0,
      j;
    while (data.length < total) {
      j = i * 2; // for artists: appears twice in list
      for (let endpoint in dataObj) {
        const inc = endpoint === 'TOP_ARTISTS' ? j : i;
        if (dataObj[endpoint][inc]) {
          data.push(dataObj[endpoint][inc]);
        }
      }
      // artists are included twice per cycle: live, artist, playlist, podcast, *artist* (repeat)
      if (dataObj['TOP_ARTISTS'][j + 1]) {
        data.push(dataObj['TOP_ARTISTS'][j + 1]);
      }
      i++;
    }

    return data;
  } catch (err) {
    console.error('Error with getting trending data', err);
    throw err;
  }
};

export { trending };
