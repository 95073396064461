import React from 'react';
import { s } from '../../lib/screen-size';

const Icon = ({ fill }) => (
  <svg
    width={`${s(21)}px`}
    height={`${s(23)}px`}
    viewBox="0 0 21 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Button/FPuase"
        transform="translate(-34.000000, -18.000000)"
        fill={fill ? fill : '#FFFFFF'}
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(34.000000, 18.000000)">
          <path
            d="M4,23 C6.2,23 8,21.5214286 8,19.7142857 L8,3.28571429 C8,1.47857143 6.2,0 4,0 C1.8,0 0,1.47857143 0,3.28571429 L0,19.7142857 C0,21.5214286 1.8,23 4,23 Z"
            id="Path"
          />
          <path
            d="M13,3.28571429 L13,19.7142857 C13,21.5214286 14.8,23 17,23 C19.2,23 21,21.5214286 21,19.7142857 L21,3.28571429 C21,1.47857143 19.2,0 17,0 C14.8,0 13,1.47857143 13,3.28571429 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
