import ReactvPlatforms from '../lib/reactv-platforms/index';
import { BASE_URL } from '../constants';
import store from '../store';
import axios from 'axios';

export const getV2Headers = (
  sessionId,
  profileId,
  host = ReactvPlatforms.platform.getHostURL(),
  otherHeaders = {}
) => {
  return {
    ...{
      'X-Session-Id': sessionId,
      'X-User-Id': profileId,
      'X-hostName': ReactvPlatforms.platform.getHostURL()
    },
    ...otherHeaders
  };
};

export const getV3Headers = (sessionId, profileId, otherHeaders = {}) => {
  return {
    ...{
      'X-IHR-Session-ID': sessionId,
      'X-IHR-Profile-ID': profileId
    },
    ...otherHeaders
  };
};

const apiCall = async (
  restMethod,
  version,
  route,
  params,
  forceFormData,
  axiosParams
) => {
  const { sessionId, profileId } = store.getState().session;
  const headers =
    version === 3
      ? getV3Headers(sessionId, profileId)
      : version === 2
      ? getV2Headers(sessionId, profileId)
      : {};
  const url = /http/.test(route) ? route : BASE_URL + route;
  try {
    if (restMethod === 'GET') {
      const response = await axios.get(url, {
        headers,
        params,
        ...axiosParams
      });
      return response && response.data;
    } else if (restMethod === 'PUT') {
      headers['Content-Type'] = 'application/json';
      headers['Accept'] = 'application/json';
      const response = await axios({
        url,
        method: 'PUT',
        headers,
        data: params,
        ...axiosParams
      });
      return response;
    } else if (restMethod === 'POST') {
      headers['Content-Type'] =
        version === 1 || forceFormData
          ? 'application/x-www-form-urlencoded'
          : 'application/json';
      headers['Accept'] = 'application/json';

      if (process.env.REACT_APP_FORCE_US === 'true') {
        headers['X-GEO-COUNTRY'] = 'US';
      }

      const response = await axios({
        url,
        method: 'POST',
        headers,
        data: params,
        ...axiosParams
      });
      return response;
    } else if (restMethod === 'DELETE') {
      const response = await axios.delete(url, {
        headers,
        params,
        ...axiosParams
      });
      return response;
    }
  } catch (err) {
    if (axios.isCancel(err)) {
      // Request canceled
    } else {
      // handle error
      console.error('ERROR: calling ' + route, err);
      return err.response && err.response.data;
    }
  }
};

export const getV1APIRoute = async (route, params) => {
  return apiCall('GET', 1, route, params);
};

export const postV1APIRoute = async (route, params) => {
  return apiCall('POST', 1, route, params);
};

export const postV2APIRoute = async (
  route,
  params,
  forceFormData,
  axiosParams
) => {
  return apiCall('POST', 2, route, params, forceFormData, axiosParams);
};

export const postV3APIRoute = async (route, params) => {
  return apiCall('POST', 3, route, params);
};

export const getV2APIRoute = async (route, params) => {
  return apiCall('GET', 2, route, params);
};

export const getV3APIRoute = async (
  route,
  params,
  forceFormData,
  axiosParams
) => {
  return apiCall('GET', 3, route, params, forceFormData, axiosParams);
};

export const putV3APIRoute = async (route, params) => {
  return apiCall('PUT', 3, route, params);
};

export const deleteV2APIRoute = async (route, params) => {
  return apiCall('DELETE', 3, route, params);
};

export const deleteV3APIRoute = async (route, params) => {
  return apiCall('DELETE', 3, route, params);
};
