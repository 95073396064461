import ReactvPlatforms from '../lib/reactv-platforms/index';
import { postV2APIRoute, postV3APIRoute } from './utils';
import { BASE_URL } from '../constants';

const PLAYBACK = '/api/v2/playback/streams';
const REPORTING = '/api/v3/playback/reporting';

// Example request body from IHR Docs
// {
//   "contentIds": [], // Array of contentIds in case you know what you want to play
//   "stationId": "string-id",
//   "stationType": "string",
//   "hostName": "string",
//   "playedFrom": int,
//   "limit": int, // Optional parameter. Its only used for RADIO and will default to 3 if not provided "startStream":
//   { // Only used for RADIO, when a specific track is needed to play first
//     "contentId": 1, // Id of the track that you want amp to generate in case of SONG2START
//     "reason": "SONG2START" // Possible values: SONG2START/ARTIST2START
//   }
// }

export const getPlaybackStream = async (
  playbackRequest = {},
  axiosParams = {}
) => {
  const playbackUrl = BASE_URL + PLAYBACK;
  playbackRequest.hostName = ReactvPlatforms.platform.getHostURL();
  return postV2APIRoute(playbackUrl, playbackRequest, null, axiosParams);
};

// POST – Report the status of a playable custom and podcast item. Reports that the given user is playing the specified station. This API needs to be called at 3 points for each custom song played.
// • At the start of the song
// • 15 seconds into the song
// At the completion of the song, no matter how the song was ended.

// playerKey[required][Body} - string - Obtained from https://us.api.iheart.com/api/v2/playlists/{profileId}/{stationType}/{stationId}/content playedDate[required][Body] - integer - A milliseconds representation of the current date secondsPlayed[required][Body] – integer – The number of seconds the song has been played. status[required][Body] – string – Status of the call.
// • START - when a stream is started.
// • REPORT_15 - when a stream has played for 15 seconds.
// • STATIONCHANGE - when a stream is has been changed.
// • APPCLOSE - when a user has closed the app.
// • REPLAY – a request for a replay is made
// • SKIP - when a user has skipped the song.
// • DONE - when a song has been completed.

export const reportPlayback = async (playbackReport = {}) => {
  const url = BASE_URL + REPORTING;
  return postV3APIRoute(url, playbackReport);
};
