import { getPLSTracks } from '../api/streams.js';
import { STREAM_PREFERENCE_ORDERING } from '../constants.js';
import { getLiveStationContent, getStationCurrentTrack } from '../api/content';
import parse from 'url-parse';
import get from '../lib/get';
import { getAlbumInfo } from '../api/catalog';

const ARTIST = 'ARTIST';
const PLAYLIST = 'PLAYLIST';
const COLLECTION = 'COLLECTION';
const PODCAST = 'PODCAST';
const PODCAST_EPISODE = 'PODCAST_EPISODE';
const LIVE = 'LIVE';
const SONG = 'SONG';
const ALBUM = 'ALBUM';
const GENRE = 'GENRE';
const VIEWALL = 'VIEWALL';

const getItemType = ({
  dataContentType,
  item: {
    stationId,
    albumId,
    trackId,
    subType,
    contentType,
    responseType,
    stationType,
    kind,
    content: { streams } = {}
  }
}) => {
  if (kind === VIEWALL) {
    return VIEWALL;
  }

  if (
    subType === ARTIST ||
    contentType === ARTIST ||
    dataContentType === ARTIST ||
    responseType === ARTIST ||
    stationType === ARTIST
  ) {
    return ARTIST;
  }

  if (
    subType === PLAYLIST ||
    contentType === PLAYLIST ||
    dataContentType === PLAYLIST ||
    responseType === PLAYLIST ||
    stationType === PLAYLIST ||
    stationType === COLLECTION
  ) {
    return PLAYLIST;
  }

  if (
    subType === PODCAST ||
    contentType === PODCAST ||
    dataContentType === PODCAST ||
    responseType === PODCAST ||
    stationType === PODCAST
  ) {
    return PODCAST;
  }

  if (
    subType === PODCAST_EPISODE ||
    contentType === PODCAST_EPISODE ||
    dataContentType === PODCAST_EPISODE ||
    responseType === PODCAST_EPISODE ||
    stationType === PODCAST_EPISODE
  ) {
    return PODCAST_EPISODE;
  }

  if (
    stationId ||
    streams ||
    subType === LIVE ||
    contentType === LIVE ||
    dataContentType === LIVE ||
    responseType === LIVE ||
    stationType === LIVE
  ) {
    return LIVE;
  }

  if (trackId || dataContentType === SONG) {
    return SONG;
  }

  if (albumId || dataContentType === ALBUM) {
    return ALBUM;
  }

  if (dataContentType === GENRE) {
    return GENRE;
  }

  return;
};

const getItemContentId = item =>
  item.id ||
  item.artistId ||
  item.albumId ||
  item.trackId ||
  item.stationId ||
  item.contentId ||
  item.playlistId ||
  item.podcastId;

const getItemTitle = item =>
  item.label || item.title || item.genreName || item.name || item.stationName;

// TODO: add albumName into track object
const getItemSubtitle = (props, type) => {
  const { item, showArtistNameForSong } = props;

  if (showArtistNameForSong && type === 'SONG') {
    return (
      item.artistName ||
      item.subLabel ||
      item.description ||
      item.subtitle ||
      item.albumName
    );
  } else {
    return (
      item.subLabel ||
      item.description ||
      item.subtitle ||
      item.albumName ||
      item.artistName
    );
  }
};

// TODO: add albumImage into track object
const getItemImage = item =>
  item.imagePath ||
  item.image ||
  item.logo ||
  item.img_uri ||
  item.albumImage ||
  item.imageUrl ||
  (item.content && item.content.length && item.content[0].imagePath) ||
  (item.content && item.content.length && item.content[0].logo) ||
  (item.urls && item.urls.image) ||
  (item.subType === 'ARTIST' &&
    `https://iscale.iheart.com/catalog/artist/${item.contentId}`);

export const getItemModel = props => {
  if (!props.item.model) {
    const type = getItemType(props);

    return {
      type,
      toastType: props.toastType,
      contentId: getItemContentId(props.item),
      title: getItemTitle(props.item),
      subtitle: getItemSubtitle(props, type),
      image: getItemImage(props.item),
      isLiveRadio: type === LIVE,
      isArtistRadio: type === ARTIST,
      isPlaylistRadio: type === PLAYLIST,
      isPodcast: type === PODCAST,
      isAlbum: type === ALBUM,
      isGenre: type === GENRE,
      isSong: type === SONG,
      isViewAll: type === VIEWALL
    };
  }
};

export async function handleCardSelection(card) {
  const { _model: model } = card;
  const { session, addToast } = this.props;
  if (model.type === undefined) {
    model.type = card.type;
  }
  console.info('handleCardSelection', card, model);
  if (card.kind === 'selectGenre') {
    this.props.updateLocationState(
      this.props.location.pathname,
      Object.assign(this.props.appNavigation, {
        lastScrollValue: document.querySelector('.mainContainer').scrollLeft
      })
    );
    return this.props.history.push('/genreListing');
  }
  switch (model && model.type) {
    case 'ARTIST':
      const artistUrl = `/artist/${card.artistId ||
        card.seedArtistId ||
        model.contentId}`;

      this.props.updateLocationState(
        this.props.location.pathname,
        Object.assign(this.props.appNavigation, {
          lastScrollValue: document.querySelector('.mainContainer').scrollLeft
        })
      );
      this.props.history.push(artistUrl);
      break;

    case 'CR':
      this.props.playFavorites(card.content.stationId);
      break;

    case 'PODCAST':
      this.props.updateLocationState(
        this.props.location.pathname,
        Object.assign(this.props.appNavigation, {
          lastScrollValue: document.querySelector('.mainContainer').scrollLeft
        })
      );
      this.props.history.push(
        `/podcast/${get(card, 'catalog.id') || model.contentId}`
      );
      break;

    case 'PODCAST_EPISODE':
      this.props.playPodcastEpisode(card);
      break;

    case 'ALBUM':
      this.props.updateLocationState(
        this.props.location.pathname,
        Object.assign(this.props.appNavigation, {
          lastScrollValue: document.querySelector('.mainContainer').scrollLeft
        })
      );
      this.props.history.push(
        `/artist/${card.contentId || card.artistId}/albums/${card.albumId ||
          card.id}`
      );
      break;

    case 'SONG':
      if (!session.isPremiumUser) {
        addToast({ type: model.toastType || 'od-song' });
        if (card.rank >= 0 || card.score >= 0) {
          this.props.playArtist(card.artistId);
        }
        return;
      }

      if (
        card.libraryId &&
        this.props.library &&
        this.props.library[card.libraryId]
      ) {
        let tracks = this.props.library[card.libraryId];
        let updateStationPagination;

        if (
          this.props.all &&
          this.props.all[card.viewId] &&
          this.props.all[card.viewId].length >
            this.props.library[card.libraryId].length
        ) {
          tracks = this.props.all[card.viewId];
        }

        if (card.viewId && this.props['library-tracks']) {
          updateStationPagination = {};
          let paginationPages = this.props['library-tracks'];
          Object.keys(paginationPages).forEach((key, idx) => {
            if (idx < 1) {
              updateStationPagination[key] = paginationPages[key];
            }
          });
        }

        this.props.playTracks({
          tracks,
          startFrom: card.startFrom,
          contentId: card.contentId,
          libraryId: card.libraryId,
          viewId: card.viewId,
          updateStationPagination
        });
      } else if (Number.isInteger(card.playlistIndex)) {
        this.props.playPlaylist(
          {
            id: card.collectionId,
            userId: card.userId,
            tracks: card.playlistTrackIds,
            disablePlayOnStartup: card.disablePlayOnStartup
          },
          card.playlistIndex,
          card.contentType
        );
      } else if (card.playlistType === 'ALBUM') {
        const startIdx = card.album.tracks.findIndex(
          track => track.id === card.id
        );
        let album = {
          ...card.album,
          tracks: card.album.tracks
        };
        this.props.playAlbum(album, startIdx);
      } else {
        this.props.playSong(model.contentId);
      }

      break;

    case 'DL':
    case 'PLAYLIST':
      const device = parse(get(card, 'link.urls.device'), true);
      if (device) {
        device.catalog = {
          id: get(device, 'pathname', '')
            .split('/')
            .filter(q => q && q !== 'playlist')
            .join('::')
        };
      }
      const stationId =
        card.reportingKey ||
        get(card, 'content.reportingKey') ||
        get(card, 'catalog.id') ||
        get(device, 'catalog.id');

      // NOTE: send to dedicated playlist page
      this.props.updateLocationState(
        this.props.location.pathname,
        Object.assign(this.props.appNavigation, {
          lastScrollValue: document.querySelector('.mainContainer').scrollLeft
        })
      );
      this.props.history.push(`/playlist/${stationId.replace('::', '-')}`);
      break;

    case 'LIVE':
    case 'RADIO':
      let streams =
        card.streams ||
        (card.content && card.content.streams) ||
        (card.content && card.content.length && card.content[0].streams);
      if (!streams) {
        const { hits } = await getLiveStationContent(card.id);
        if (!hits) {
          console.error('No streams available');
          return;
        }
        streams = hits[0].streams;
      }
      // Station Card selected
      let selectedStream = getPreferredStreamType(streams);
      if (selectedStream.includes('.pls')) {
        let plsTracks = await getPLSTracks(selectedStream);
        selectedStream = plsTracks[0];
      }

      let liveRadioCurrentSong;
      if (card.id) {
        liveRadioCurrentSong = await getStationCurrentTrack(card.id);
      }
      this.props.playRadio({
        stationId:
          (card.content && card.content.id) ||
          (card.content && card.content.length && card.content[0].id) ||
          card.id ||
          model.contentId,
        image: card.imagePath || card.image || model.image,
        title:
          card.label ||
          card.stationName ||
          model.title ||
          (card.content && card.content.length && card.content[0].name),
        subtitle: card.description,
        streamUrl: selectedStream,
        callLetters:
          card.callLetters || (card.content && card.content.callLetters),
        liveRadioCurrentSong
      });
      break;
    case 'VIEWALL':
      if (!card.viewAllContentType) card.viewAllContentType = card.label;
      let viewAllDataToPass = card.viewAllData;
      let shouldClearPaginationData = false;
      if (card.menuid && this.props.view && this.props.view[card.menuid]) {
        const loaded = this.props.view[card.menuid]['1'].loaded;
        viewAllDataToPass = viewAllDataToPass.slice(0, loaded);
        shouldClearPaginationData = true;
      }
      const page =
        this.props.location.pathname &&
        this.props.location.pathname.split('/')[1];
      const id =
        this.props.match.params &&
        (this.props.match.params.artistId ||
          this.props.match.params.podcastId ||
          this.props.match.params.playlistId);
      let pushTo = `/viewAllPage/${(card._model.playlistData &&
        card._model.playlistData.name) ||
        card.label}${page ? '/' + page : ''}${id ? '/' + id : ''}?menuid=${
        card.menuid
      }`;
      this.props.setPageData(
        viewAllDataToPass,
        card.viewAllContentType,
        pushTo,
        this.idForGrid,
        shouldClearPaginationData
      );

      this.props.updateLocationState(
        this.props.location.pathname,
        Object.assign(this.props.appNavigation, {
          lastScrollValue: document.querySelector('.mainContainer').scrollLeft
        })
      );
      this.props.history.push(pushTo);
      break;

    default:
      console.error('Model type is not defined', model && model.type);
      break;
  }
}

export function getPreferredStreamType(streamsList) {
  for (let streamType of STREAM_PREFERENCE_ORDERING) {
    if (streamType in streamsList && streamsList[streamType].length > 0) {
      return streamsList[streamType];
    }
  }
}

export function isLiveStation(stationType) {
  // LR - Most Popular Live Radio
  // CR - custom radio
  // LN - Live stations nearby
  // LRRM - Live Radio Recommended in Market
  // DL - Merch or Slider content
  // Example: LR,LR,CR,LN
  const liveStationTypes = ['LR', 'LN', 'LRRM'];
  return liveStationTypes.indexOf(stationType) > -1;
}

export function fancyTimeFormat(str) {
  const sec_num = parseInt(str, 10);

  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;
  if (hours < 10) hours = '0' + hours;
  if (minutes < 10) minutes = '0' + minutes;
  if (seconds < 10) seconds = '0' + seconds;

  let out = [minutes, seconds];
  if (hours.toString() !== '00') out.unshift(hours);

  return out.join(':');
}

export function prepareForSearch(results) {
  Object.keys(results).map(key => {
    if (results[key].length) {
      let data;
      if (key === 'tracks') {
        data = results[key].map(track => {
          track.trackId = track.id;
          return track;
        });
        return null;
      }
      if (key === 'albums') {
        data = results[key].map(album => {
          getAlbumInfo(album.id).then(info => {
            album.image = info.image;
          });
          album.type = 'ALBUM';
          return album;
        });
        return null;
      }
      if (!data) data = results[key].slice(0);
    }
    return null;
  });
  return results;
}

export function calculatePaginateProps({
  fetchedCollectionPages,
  PAGINATION_PAGE_SIZE,
  index
}) {
  let keys = Object.keys(fetchedCollectionPages);

  const currentPage = keys.length;
  let loadedItems = 0;

  keys.forEach(item => {
    if (fetchedCollectionPages[item].loaded) {
      loadedItems += fetchedCollectionPages[item].loaded;
    } else {
      loadedItems += PAGINATION_PAGE_SIZE;
    }
  });

  const nextPageBegins = currentPage * (loadedItems / currentPage);

  const overThreshold =
    nextPageBegins - index < Math.round(loadedItems / currentPage / 1.2);

  const isPageNotLoaded =
    !fetchedCollectionPages ||
    !fetchedCollectionPages[currentPage + 1] ||
    !fetchedCollectionPages[currentPage + 1].value;

  return { overThreshold, isPageNotLoaded, currentPage };
}
