import React from 'react';
import { s } from '../../lib/screen-size';

const Icon = ({ fill }) => (
  <svg
    width={s(72)}
    height={s(72)}
    viewBox="0 0 72 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mini-Player/LiveRadio"
        transform="translate(-1656.000000, -60.000000)"
      >
        <g
          id="Button/Player/Default"
          transform="translate(1656.000000, 60.000000)"
        >
          <g
            id="Button/Player/Icon/Save/Default"
            fill={fill ? fill : '#FFFFFF'}
            fillRule="evenodd"
            strokeWidth="1"
          >
            <g id="add" transform="translate(20.000000, 20.000000)">
              <path
                d="M29.7142857,18.2857143 L18.2857143,18.2857143 L18.2857143,29.7142857 C18.2857143,30.9714286 17.2571429,32 16,32 C14.7428571,32 13.7142857,30.9714286 13.7142857,29.7142857 L13.7142857,18.2857143 L2.28571429,18.2857143 C1.02857143,18.2857143 0,17.2571429 0,16 C0,14.7428571 1.02857143,13.7142857 2.28571429,13.7142857 L13.7142857,13.7142857 L13.7142857,2.28571429 C13.7142857,1.02857143 14.7428571,0 16,0 C17.2571429,0 18.2857143,1.02857143 18.2857143,2.28571429 L18.2857143,13.7142857 L29.7142857,13.7142857 C30.9714286,13.7142857 32,14.7428571 32,16 C32,17.2571429 30.9714286,18.2857143 29.7142857,18.2857143 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
