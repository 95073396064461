import React from 'react';
import {
  toggleSaveSong,
  toggleSaveAlbum,
  toggleSaveStation,
  toggleFollowPodcast,
  isItemSavedInApp
} from '../../reducers/library';
import toLocaleDateString from '../../lib/date';
import ListMenu from '../../lib/reactv-navigation/components/ListMenu';
import Button, { ButtonWithoutComposer } from '../../components/Button/Button';
import ProgressBar from '../../components/ProgressBar';
import PlayIcon from '../../img/player/play.js';
import PauseIcon from '../../img/player/pause.js';
import AddIcon from '../../img/player/add.js';
import BackIcon from '../../img/player/back.js';
import ForwardIcon from '../../img/player/forward.js';
import SkipIcon from '../../img/player/skipForward.js';
import StopIcon from '../../img/player/stop.js';

const Player = ({
  onDown,
  onEnterIconButton,
  audioPlayer,
  onUpAddPopup,
  focusOnAddPopupFirstItem,
  focusOnAddPopupSave,
  downFromAddMenu,
  onUpFromMiniplayer,
  session,
  station,
  getBottomLabel,
  appNavigation: { addPopup },
  addToast,
  listMenuFocusIdx,
  library
}) => {
  const getControlItems = (contentType, isPremiumUser) => {
    let items = [];
    contentType = station.contentType;
    const addItem = {
      id: 'add',
      className: 'player__button',
      Component: ({ focused }) => <AddIcon fill={focused ? '#000' : null} />
    };

    const playStop = {
      id: 'play-stop',
      className: 'player__button',
      Component: ({ focused }) =>
        audioPlayer.DO_PLAY ? (
          <StopIcon fill={focused ? '#000' : null} />
        ) : (
          <PlayIcon fill={focused ? '#000' : null} />
        )
    };

    const playPause = {
      id: 'play-pause',
      className: 'player__button',
      Component: ({ focused }) =>
        audioPlayer.DO_PLAY ? (
          <PauseIcon fill={focused ? '#000' : null} />
        ) : (
          <PlayIcon fill={focused ? '#000' : null} />
        )
    };

    const back = {
      id: 'back',
      className: 'player__button',
      Component: ({ focused }) => <BackIcon fill={focused ? '#000' : null} />
    };

    const forward = {
      id: 'forward',
      className: 'player__button',
      Component: ({ focused }) => <ForwardIcon fill={focused ? '#000' : null} />
    };

    const skipNext = {
      id: 'skip-next',
      className: 'player__button',
      Component: ({ focused, disabled }) => (
        <SkipIcon fill={disabled || focused ? '#000' : null} />
      )
    };

    const skipBack = {
      id: 'skip-back',
      className: 'player__button player__button--rotate',
      Component: ({ focused, disabled }) => (
        <SkipIcon fill={disabled || focused ? '#000' : null} />
      )
    };

    if (
      !isPremiumUser &&
      !station.isOneSong &&
      ((station.nextSongId === null && contentType !== 'ALBUM') ||
        !(
          station.isSkipAllowed &&
          station.isSkipAllowed.daySkipsRemaining > 0 &&
          station.isSkipAllowed.hourSkipsRemaining > 0
        ))
    ) {
      skipNext.disabled = true;
    }

    if (contentType === 'LIVE') {
      addItem.className = 'miniPlayer__button';
      items.push(playStop);
    }

    if (
      contentType === 'ARTIST' ||
      contentType === 'FAVORITES' ||
      (contentType === 'PLAYLIST' && !isPremiumUser)
    ) {
      items.push(playPause, skipNext);
    }

    if (
      (contentType === 'PLAYLIST' && isPremiumUser) ||
      contentType === 'ALBUM'
    ) {
      // only OD are able to play album so there are no checks for user type
      items.push(skipBack, playPause, skipNext);
    }

    if (contentType === 'PODCAST_EPISODE') {
      items.push(back, playPause, forward);
    }

    items.push(addItem);

    return items;
  };

  const renderAddPopup = (contentType, isPremiumUser) => {
    if (!addPopup.showAddPopup) {
      return null;
    }
    let id;
    switch (station.contentType) {
      case 'ARTIST':
        id = station.sourceId;
        break;
      case 'PODCAST_EPISODE':
        id =
          station.currentSong &&
          station.currentSong.content &&
          station.currentSong.content.podcastId;
        break;
      case 'PLAYLIST':
        id = `${station.userId}-${station.contentId}`;
        break;
      default:
        id = station.contentId;
    }
    let itemIsInLibrary = isItemSavedInApp(library, station.contentType, id);

    let items = [];

    let saveSong = {
      id: 'saveSong',
      title: 'Save Song',
      onEnter: () => {
        let songId =
          station.currentSong && station.currentSong.content
            ? station.currentSong.content.id
            : null;
        toggleSaveSong(songId);
        addToast({ type: 'fullplayer-save-song' });
      }
    };

    let saveAlbum = {
      id: 'saveAlbum',
      title: itemIsInLibrary ? 'Remove Album' : 'Save Album',
      onEnter: () => {
        toggleSaveAlbum({
          tracks: station.playlist,
          albumId: station.contentId
        });
      }
    };

    let saveStation = {
      id: 'saveStation',
      title:
        (station.contentType === 'LIVE' || station.contentType === 'ARTIST') &&
        itemIsInLibrary
          ? 'Remove Station'
          : 'Save Station',
      onEnter: () => {
        toggleSaveStation(station.contentType, id);
      }
    };

    let followPodcast = {
      id: 'followPodcast',
      title: itemIsInLibrary ? 'Remove Podcast' : 'Follow Podcast',
      onEnter: () => {
        toggleFollowPodcast(station.currentSong.content.podcastId);
      }
    };

    const onEnterDisabledSaveSong = () => {
      addToast({ type: 'fullplayer-save' });
    };

    if (contentType === 'LIVE') {
      saveStation.isFocused = () => {
        return addPopup.selectedItem === 'firstItem';
      };
      saveStation.onUp = onUpAddPopup;
      saveStation.onDown = downFromAddMenu;
      saveStation.onLeft = downFromAddMenu;
      saveStation.onRight = downFromAddMenu;

      items.push(saveStation);
    } else if (contentType === 'ARTIST' || contentType === 'PLAYLIST') {
      if (
        contentType === 'PLAYLIST' &&
        station.fullPlayerTitle === 'Your Weekly Mixtape'
      ) {
        saveSong.isFocused = () => {
          return addPopup.selectedItem === 'firstItem';
        };
        saveSong.onUp = onUpAddPopup;
        saveSong.onDown = downFromAddMenu;
        saveSong.onLeft = downFromAddMenu;
        saveSong.onRight = downFromAddMenu;

        if (!isPremiumUser) {
          saveSong.disabled = true;
          saveSong.onEnter = onEnterDisabledSaveSong;
        }

        items.push(saveSong);
      } else {
        saveSong.isFocused = () => {
          return addPopup.selectedItem === 'saveSong';
        };
        saveSong.onUp = onUpAddPopup;
        saveSong.onDown = focusOnAddPopupFirstItem;
        saveSong.onLeft = downFromAddMenu;
        saveSong.onRight = downFromAddMenu;

        if (contentType === 'PLAYLIST') {
          saveStation.title = itemIsInLibrary
            ? 'Remove Playlist'
            : 'Follow Playlist';
        }

        saveStation.isFocused = () => {
          return addPopup.selectedItem === 'firstItem';
        };
        saveStation.onUp = focusOnAddPopupSave;
        saveStation.onDown = downFromAddMenu;
        saveStation.onLeft = downFromAddMenu;
        saveStation.onRight = downFromAddMenu;

        if (!isPremiumUser) {
          saveSong.disabled = true;
          saveSong.onEnter = onEnterDisabledSaveSong;
        }

        items.push(saveSong, saveStation);
      }
    } else if (contentType === 'ALBUM') {
      saveSong.isFocused = () => {
        return addPopup.selectedItem === 'saveSong';
      };
      saveSong.onUp = onUpAddPopup;
      saveSong.onDown = focusOnAddPopupFirstItem;
      saveSong.onLeft = downFromAddMenu;
      saveSong.onRight = downFromAddMenu;

      saveAlbum.isFocused = () => {
        return addPopup.selectedItem === 'firstItem';
      };
      saveAlbum.onUp = focusOnAddPopupSave;
      saveAlbum.onDown = downFromAddMenu;
      saveAlbum.onLeft = downFromAddMenu;
      saveAlbum.onRight = downFromAddMenu;

      if (!isPremiumUser) {
        saveSong.disabled = true;
        saveSong.onEnter = onEnterDisabledSaveSong;
      }

      items.push(saveSong, saveAlbum);
    } else if (contentType === 'FAVORITES') {
      saveSong.isFocused = () => {
        return addPopup.selectedItem === 'firstItem';
      };
      saveSong.onUp = onUpAddPopup;
      saveSong.onDown = downFromAddMenu;
      saveSong.onLeft = downFromAddMenu;
      saveSong.onRight = downFromAddMenu;

      if (!isPremiumUser) {
        saveSong.disabled = true;
      }

      items.push(saveSong);
    } else if (contentType === 'PODCAST_EPISODE') {
      followPodcast.isFocused = () => {
        return addPopup.selectedItem === 'firstItem';
      };
      followPodcast.onUp = onUpAddPopup;
      followPodcast.onDown = downFromAddMenu;
      followPodcast.onLeft = downFromAddMenu;
      followPodcast.onRight = downFromAddMenu;
      items.push(followPodcast);
    }

    return (
      <div className="player__addPopup">
        {items.map((item, ind) => (
          <Button
            className="player__addPopupItem"
            item={item}
            key={`addPopup-${ind}`}
            focused={item.isFocused()}
            onDown={item.onDown}
            onUp={item.onUp}
            onLeft={item.onLeft}
            onRight={item.onRight}
            onEnter={item.onEnter}
          />
        ))}
      </div>
    );
  };

  const menuItems = getControlItems(station.contentType, session.isPremiumUser);
  const date =
    station.date &&
    toLocaleDateString(station.date, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

  let playerImage =
    (station.liveRadioCurrentSong && station.liveRadioCurrentSong.imagePath) ||
    station.image;
  let playerUppertitle =
    (station.liveRadioCurrentSong && station.liveRadioCurrentSong.artist) ||
    station.upperTitle;
  let playerTitle =
    (station.liveRadioCurrentSong && station.liveRadioCurrentSong.title) ||
    station.title;
  let playerSubtitle =
    date ||
    (station.liveRadioCurrentSong && station.liveRadioCurrentSong.artist) ||
    station.subtitle;

  return (
    <div className="player">
      <div
        className="player__background"
        style={{ backgroundImage: `url(${playerImage})` }}
      />
      <div
        className="player__image"
        style={{ backgroundImage: `url(${playerImage})` }}
      />
      <div className="player__rightWrapper">
        <div className="player__trackInfo">
          {station.contentType === 'LIVE' && station.liveRadioCurrentSong ? (
            <div
              className="player__stationLogo"
              style={{ backgroundImage: `url(${station.image})` }}
            />
          ) : (
            <div className="player__upperTitle">{playerUppertitle}</div>
          )}
          <div className="player__title">{playerTitle}</div>
          <div className="player__subtitle">{playerSubtitle}</div>
          {station.contentType !== 'LIVE' && (
            <ProgressBar className="player__progressBar" />
          )}
        </div>
        <div className="player__controls">
          {renderAddPopup(station.contentType, session.isPremiumUser)}
          <ListMenu
            data={menuItems}
            renderItem={ButtonWithoutComposer}
            className="player__listMenu"
            horizontal
            onEnter={onEnterIconButton}
            focused={!addPopup.selectedItem}
            onUp={onUpFromMiniplayer}
            onDown={onDown}
            onFocusIndex={addPopup.listMenuFocusIdx}
          />
        </div>
      </div>
      {process.env.REACT_APP_TOUCH !== 'true' && (
        <div className="player__bottomInfo">
          <div className="player__bottomInfoWrapper">
            <div className="player__bottomInfoLabel">{getBottomLabel()}</div>
            <div className="player__bottomInfoArrow" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Player;
