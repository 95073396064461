import React from 'react';
import './GridMenu.scss';
import cx from 'classnames';
import MenuCard from '../MenuCard';
import { s } from '../../lib/screen-size.js';

const generateCard = ({ key, RenderItem, ...rest }, isFocused) => (
  <RenderItem {...rest} focused={isFocused(key)} key={`GridMenu:${key}`} />
);

const GridMenu = ({
  first4Wide,
  label,
  menuItems,
  isFocused,
  className,
  style,
  renderItem,
  constantWidth,
  wrapperClassName,
  dataContentType,
  toastType,
  isSquare,
  customMaxWidthForTitle,
  mid,
  threeRows,
  allWider,
  recentlyPlayed,
  viewAllMenu,
  showArtistNameForSong,
  favorites,
  onEnter,
  viewAllCardData
}) => {
  let firstRow = [],
    secondRow = [],
    thirdRow = [];
  const maxWidthForTitleWide = s(430);
  const maxWidthForTitleNormal = customMaxWidthForTitle
    ? s(customMaxWidthForTitle)
    : s(203);

  let hideRightFrom = false;
  let hideLeftTill = false;
  let paddingLeft = 0;

  if (mid === 'viewAllPage-mainMenu-0') {
    hideRightFrom =
      Math.ceil((viewAllMenu.index + (first4Wide ? 18 : 14)) / 5) * 5;
    hideLeftTill =
      viewAllMenu.index > 19
        ? Math.ceil((viewAllMenu.index - 19) / 5) * 5
        : false;
    paddingLeft = (hideLeftTill && (hideLeftTill / 5) * 504) || 0;
  }

  const isWide = idx => {
    if (first4Wide) {
      return idx < 4 || idx % 10 === 4 || idx % 10 === 1;
    } else {
      return allWider || (!constantWidth && (idx % 10 === 0 || idx % 10 === 7));
    }
  };

  menuItems.forEach((item, idx) => {
    if (
      (hideRightFrom && idx >= hideRightFrom) ||
      (hideLeftTill && first4Wide
        ? idx - 4 < hideLeftTill && idx > 3
        : idx < hideLeftTill)
    ) {
      return null;
    }

    const card = generateCard(
      {
        viewAllCardData,
        onEnter,
        showArtistNameForSong,
        dataContentType,
        toastType,
        isSquare,
        recentlyPlayed,
        RenderItem: renderItem || MenuCard,
        key: idx,
        item,
        favorites,
        wider: isWide(idx),
        maxWidthForTitle:
          (!constantWidth && (idx % 10 === 0 || idx % 10 === 7)) ||
          recentlyPlayed
            ? maxWidthForTitleWide
            : maxWidthForTitleNormal
      },
      isFocused
    );
    if (constantWidth) {
      if (threeRows) {
        if ((idx + 1) % 3 === 0) {
          thirdRow.push(card);
        } else if ((idx + 2) % 3 === 0) {
          secondRow.push(card);
        } else {
          firstRow.push(card);
        }
      } else {
        if (idx % 2 === 0) firstRow.push(card);
        else secondRow.push(card);
      }
    } else if (first4Wide) {
      if (
        idx === 0 ||
        idx % 10 === 4 ||
        idx % 10 === 7 ||
        idx % 10 === 9 ||
        (idx % 10 === 0 && menuItems.length > 11) ||
        idx % 10 === 2
      )
        firstRow.push(card);
      else secondRow.push(card);
    } else {
      if (
        idx % 10 === 0 ||
        idx % 10 === 3 ||
        idx % 10 === 5 ||
        (idx % 10 === 6 && menuItems.length > 7) ||
        idx % 10 === 8
      )
        firstRow.push(card);
      else secondRow.push(card);
    }
  });

  return (
    <div
      className={cx('gridMenu', { [wrapperClassName]: wrapperClassName })}
      id={mid}
      style={{ paddingLeft: `${paddingLeft}px` }}
    >
      {label && <h2 className="gridMenu__label">{label}</h2>}
      <div
        style={style}
        className={cx('gridMenu__row', { [className]: className })}
      >
        {firstRow}
      </div>
      <div
        style={style}
        className={cx('gridMenu__row', { [className]: className })}
      >
        {secondRow}
      </div>
      {threeRows && (
        <div
          style={style}
          className={cx('gridMenu__row', { [className]: className })}
        >
          {thirdRow}
        </div>
      )}
    </div>
  );
};

export default GridMenu;
