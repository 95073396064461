import React from 'react';
import './Button.scss';
import cx from 'classnames';
import buttonComposer from '../../lib/reactv-navigation/components/Buttonizer/Buttonizer';
import { Touch } from '../../lib/reactv-touch';
import BaseButton from '../BaseButton/BaseButton.js';

class Button extends BaseButton {
  constructor(p) {
    super(p);

    this.itemRef = React.createRef();
    this.deregisterTouch = this.deregisterTouch.bind(this);
    this.registerTouch = this.registerTouch.bind(this);
  }
  componentDidMount() {
    this.registerTouch();
  }
  componentDidUpdate(prevProps) {
    if (this.props.item && this.props.item.id !== prevProps.item.id) {
      console.info(
        'Component did update, id changed',
        this.props.item.id,
        prevProps.item.id
      );
      this.deregisterTouch();
      this.registerTouch();
    }
  }
  componentWillUnmount() {
    this.deregisterTouch();
  }
  deregisterTouch() {
    if (!this.touch) return;
    this.touch.unsubscribe();
    this.touch = false;
  }
  registerTouch() {
    if (this.touch) return;
    this.touch = new Touch(this.itemRef.current, {
      ontap: e => {
        this.itemRef.current.classList.add('clicked');
        let rect = this.itemRef.current.getBoundingClientRect();
        let span = document.createElement('span');

        span.classList = 'touch-gesture';
        span.style.top = `${e.startY - rect.top}px`;
        span.style.left = `${e.startX - rect.left}px`;
        span.style.height = span.style.width = `${Math.max(
          rect.width,
          rect.height
        )}px`;
        this.itemRef.current.appendChild(span);

        clearTimeout(this.emit);
        this.emit = setTimeout(() => {
          try {
            this.props.onEnter(this.props.item);
          } catch (error) {
            console.error(
              "Can't trigger button action: ",
              this.itemRef.current
            );
          }
        }, 800);

        setTimeout(() => {
          this.itemRef.current &&
            this.itemRef.current.classList.remove('clicked');
        }, 550);
      }
    });
  }
  readTTS() {
    const { item, label, title } = this.props;

    if (item && !item.isTtsTriggered && item.tts) {
      item.updateTts(true);
      return `${item.tts} ${label ||
        title ||
        (item && (item.title || item.id.replace('-', ' ')))}`;
    }

    return (
      label || title || (item && (item.title || item.id.replace('-', ' ')))
    );
  }
  render() {
    const {
      focused,
      style,
      children,
      className,
      disabled,
      item,
      label,
      dataTest
    } = this.props;
    let Component = item && item.Component;

    return (
      <div
        key={`${item && item.id}`}
        className={cx('button', className, {
          disabled: disabled || (item && item.disabled),
          focused,
          [item && item.className]: item && item.className,
          'dark-gesture': focused || disabled || (item && item.disabled)
        })}
        style={style}
        data-test={dataTest}
        ref={this.itemRef}
      >
        {children ||
          (item &&
            (item.title ||
              (Component && (
                <Component
                  {...this.props}
                  disabled={disabled || (item && item.disabled)}
                />
              ))))}
        {label && <label>{label}</label>}
      </div>
    );
  }
}

export const ButtonWithoutComposer = props => {
  return <Button {...props} />;
};

export default buttonComposer(ButtonWithoutComposer);
