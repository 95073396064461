import axios from 'axios';
import { getV2Headers, getV2APIRoute } from './utils';
import { BASE_URL } from '../constants';
import store from '../store';

const saveGenreSelections = async (
  genreIds = [],
  { sessionId, profileId, apiUrl = BASE_URL }
) => {
  const headers = getV2Headers(sessionId, profileId);
  const tasteGenreRoute = '/api/v2/taste/{profileId}/genre'.replace(
    '{profileId}',
    profileId
  );
  const url = apiUrl + tasteGenreRoute;
  try {
    const response = await axios({
      url,
      method: 'POST',
      headers,
      data: { genreIds }
    });
    const { status, data } = response;
    if (status >= 200) {
      return data;
    } else {
      const err = new Error();
      err.status = status;
      throw err;
    }
  } catch (err) {
    console.error('Error while executing saveGenreSelections', err);
    throw err;
  }
};

const getUserGenres = async id => {
  const { profileId } = store.getState().session;
  if (!(id || profileId)) return;
  const route = `/api/v2/taste/{profileId}`.replace(
    '{profileId}',
    id || profileId
  );

  const data = await getV2APIRoute(route);
  return data && data.value ? data.value : { genreIds: [] };
};

export { saveGenreSelections, getUserGenres };
