export const setUserGenres = favorites => {
  const type = 'SET_USER_GENRES';
  return { type, favorites };
};

const initialState = {
  favorites: {}
};

export default function(state = initialState, action) {
  const { type, favorites } = action;
  switch (type) {
    case 'SET_USER_GENRES':
      return Object.assign({}, state, { favorites });
    default:
      return state;
  }
}
