export const UPDATE_CARD_PILES = 'cards/UPDATE_CARD_PILES';
export const SELECT_CARD = 'cards/SELECT_CARD';
export const CLEAR_CARD_PILES = 'cards/CLEAR_CARD_PILES';

export function updateCardPiles(data) {
  return {
    type: UPDATE_CARD_PILES,
    cards: data
  };
}

export function selectCard(card) {
  return {
    type: SELECT_CARD,
    card
  };
}

export function clearCardPiles() {
  return {
    type: CLEAR_CARD_PILES
  };
}

const addCards = (state, { cards }) => {
  let newCards = {};
  cards.forEach(item => {
    newCards[item.key] = item.data;
  });
  return Object.assign({}, state, newCards);
};

const initialState = {};

export default function cardsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CARD_PILES:
      return addCards(state, action);
    case SELECT_CARD:
      return Object.assign({}, state, { selection: action.card });
    case CLEAR_CARD_PILES:
      return initialState;
    default:
      return state;
  }
}
