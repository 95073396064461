import axios from 'axios';
import { getV3Headers } from './utils';
import { getTracksInfo } from './catalog';
import { BASE_URL, routes } from '../constants';
import store from '../store';

const getArtistBio = async artistId => {
  const { sessionId, profileId } = store.getState().session;
  const headers = getV3Headers(sessionId, profileId);
  const getArtistRoute = routes['ARTIST_BIO'].replace('{artistId}', artistId);
  const url = BASE_URL + getArtistRoute;

  try {
    const { data = {} } = await axios.get(url, { headers });
    return data.bio;
  } catch (err) {
    console.log(`Error calling getArtistProfile for artistId ${artistId}`, err);
    throw err;
  }
};

const getArtistProfile = async (artistId, requestProperty = '') => {
  const { sessionId, profileId } = store.getState().session;
  const headers = getV3Headers(sessionId, profileId);
  const getArtistRoute = routes['ARTIST_PROFILE'].replace(
    '{artistId}',
    artistId
  );
  const url = BASE_URL + getArtistRoute;

  try {
    const { data: artistProfile } = await axios.get(url, { headers });
    requestProperty = requestProperty.replace(' ', '');
    if (
      requestProperty === 'relatedTo' ||
      requestProperty === 'similar' ||
      requestProperty === 'similarArtists'
    ) {
      return artistProfile.relatedTo;
    } else if (
      requestProperty === 'popularOn' ||
      requestProperty === 'popularOnStations'
    ) {
      return artistProfile.popularOnStations;
    }

    const { hasBio } = artistProfile;

    if (hasBio) {
      const bio = await getArtistBio(artistId);
      artistProfile.bio = bio;
    }

    // add track details to each track object in Top Songs
    const detailedTracks = artistProfile.tracks.map(async track => {
      const trackInfo = await getTracksInfo(track.trackId).then(
        data => data[0]
      );
      return Object.assign({}, track, trackInfo);
    });
    await Promise.all(detailedTracks).then(data => {
      artistProfile.tracks = data;
    });

    return artistProfile;
  } catch (err) {
    console.log(`Error calling getArtistProfile for artistId ${artistId}`, err);
    return false;
  }
};

const getArtistTopTracks = async artistId => {
  const { sessionId, profileId } = store.getState().session;
  const headers = getV3Headers(sessionId, profileId);
  const artistTopTracksRoute = routes['ARTIST_TOP_TRACKS'].replace(
    '{artistId}',
    artistId
  );
  const url = BASE_URL + artistTopTracksRoute;

  try {
    const response = await axios.get(url, { headers });
    const {
      data: { tracks }
    } = response;
    return tracks;
  } catch (err) {
    console.log(
      `Error calling getArtistTopTracks for artistId ${artistId}`,
      err
    );
    throw err;
  }
};

const getArtistAlbums = async artistId => {
  const { sessionId, profileId } = store.getState().session;
  const headers = getV3Headers(sessionId, profileId);
  const artistAlbumsRoute = routes['ARTIST_ALBUMS'].replace(
    '{artistId}',
    artistId
  );
  const url = BASE_URL + artistAlbumsRoute;

  try {
    const {
      data: { data: albums }
    } = await axios.get(url, { headers });
    return albums;
  } catch (err) {
    console.log(`Error calling getArtistAlbums for artistId ${artistId}`, err);
    throw err;
  }
};

export { getArtistBio, getArtistProfile, getArtistTopTracks, getArtistAlbums };
