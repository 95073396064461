import React from 'react';
import { s } from '../../lib/screen-size';

const Icon = ({ fill }) => (
  <svg
    width={s(72)}
    height={s(72)}
    viewBox="0 0 72 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="x-Cut-Ups"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Dark"
        transform="translate(-628.000000, -308.000000)"
        fill={fill ? fill : '#FFFFFF'}
      >
        <g
          id="Player-Buttons/Default"
          transform="translate(100.000000, 308.000000)"
        >
          <g
            id="Button/Player/Default"
            transform="translate(528.000000, 0.000000)"
          >
            <g id="Button/Player/Icon/Play/Default">
              <path
                d="M23,23.5110485 L23,49.4889557 C23,51.4698984 25.3154263,52.6735092 27.0985707,51.5952745 L48.7624446,38.6063335 C50.4125185,37.6283997 50.4125185,35.3716295 48.7624446,34.3686206 L27.0985707,21.4047246 C25.3154263,20.32649 23,21.5301058 23,23.5110485 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
