import store from '../store';
import { getV1APIRoute, getV2APIRoute } from './utils';
import { setIsPageLoaded, PAGINATION_PAGE_SIZE } from '../reducers/view';

const forYou = async (offset = 0, limit = PAGINATION_PAGE_SIZE) => {
  const { profileId } = store.getState().session;
  const params = {
    campaignId: 'foryou_mixtape_collections',
    limit,
    offset
  };

  const url = '/api/v2/recs/' + profileId;
  const data = await getV2APIRoute(url, params);
  if (data & data.values) {
    data.values = data.values.filter(rec => rec.subType !== 'LINK');
  }

  data.meta = { limit, offset };
  const pageNumber = offset / limit + 1;
  if (pageNumber === 1 && data && data.values && data.values.length) {
    const favsIndex = data.values.findIndex(
      item => item.subType === 'FAVORITES'
    );
    data.values.push(...data.values.splice(0, favsIndex));
  }
  store.dispatch(
    setIsPageLoaded('home-main-recommendations', pageNumber, true)
  );
  return data;
};

const getTopArtists = async () => {
  const params = {
    formatSize: 10,
    rankStart: 1,
    rankEnd: 1
  };

  return getV1APIRoute('/api/v1/recs/getTopArtists', params);
};

export { forYou, getTopArtists };
