import { getV2APIRoute } from './utils';
import { WEB_API, routes } from '../constants';

const getActivity = async url => {
  try {
    const data = await getV2APIRoute(url);
    if (data.cards && data.cards) {
      return data.cards[0];
    } else {
      const err = new Error();
      throw err;
    }
  } catch (err) {
    console.error(
      `Error while executing getting activity for url: ${url}`,
      err
    );
    throw err;
  }
};

const getActivities = async () => {
  // TODO: ask IHR for cleaner URL we can get params from to send params object
  const fullQuery =
    '?query=query%20%7Bleads%28query%3A%20%7B%0Asubscription%3A%20%7B%0Atags%3A%20%5B%22collections%2Fplaylist-directory%22%2C%20%22facets%2Fperfect-for%22%5D%0A%7D%7D%29%20%7B%0Atitle%0Asubtitle%0Alink%20%7B%0Aname%0Adescription%0Aurls%20%7B%0Adevice%0Aweb%0A%7D%0A%7D%0Aimg_uri%0A%7D%0A%7D%0A';
  const recsRoute = routes['GRAPH_QL'];
  const url = WEB_API + recsRoute + fullQuery;
  try {
    const data = await getV2APIRoute(url);
    const contentType = 'PLAYLIST';

    if (data && data.data && data.data.leads) {
      const activities = data.data.leads.map(async item => {
        const url = item.link.urls.device;
        const firstCard = await getActivity(url);
        firstCard.contentType = contentType;
        return firstCard;
      });
      return await Promise.all(activities).catch(err => console.log(err));
    } else {
      const err = new Error();
      throw err;
    }
  } catch (err) {
    console.error('Error while executing getting activities', err);
    throw err;
  }
};

export { getActivities };
