import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import './LoadingSpinner.scss';

class LoadingSpinner extends Component {
  render() {
    const { showLoadingSpinner } = this.props;
    return showLoadingSpinner ? (
      <div className={cx('loadingSpinner')} data-test="loadingSpinner">
        <div className="loadingSpinner__title">LOADING</div>
        <div className="loadingSpinner__spinWrapper">
          <div className="loadingSpinner__spinner" />
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = state => {
  const { view: { showLoadingSpinner } = {} } = state;
  return { showLoadingSpinner };
};

export default connect(mapStateToProps)(LoadingSpinner);
