import BasePlatform from './base.js';

export default class VizioPlatform extends BasePlatform {
  constructor(p) {
    super(p);
    this.ttsEnabled = false;
  }
  init(initCallback) {
    var _this = this;
    document.addEventListener('VIZIO_LIBRARY_DID_LOAD', function(e) {
      window.VIZIO.getDeviceId(function(deviceId) {
        console.info('Unique Device Id: ' + deviceId);
        if (!deviceId) {
          deviceId = _this.uuid();
        }
        _this.deviceId = deviceId;
        window.VIZIO.getFirmwareVersion(function(firmwareVersion) {
          // TODO: Find out why this callback isn't being fired
          console.info('Device Firmware Version: ' + firmwareVersion);
          _this.firmwareVersion = firmwareVersion;
        });
        if (initCallback) initCallback();
      });
    });
    document.addEventListener('VIZIO_TTS_ENABLED', function(e) {
      console.info('TTS Enabled');
      _this.ttsEnabled = true;
    });

    document.addEventListener('VIZIO_TTS_DISABLED', function(e) {
      console.info('TTS Disabled');
      _this.ttsEnabled = false;
    });

    // TODO: Should probably put this into index.html somehow
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'http://localhost:12345/scfs/cl/js/vizio-companion-lib.js';
    document.head.appendChild(script);
  }

  exit() {
    window.VIZIO.exitApplication();
  }

  getDeviceId() {
    return this.deviceId;
  }

  firmware() {
    return this.firmwareVersion;
  }

  model() {
    return window.VIZIO.deviceModel;
  }

  platformId() {
    return 'VIZIO';
  }

  tts(textToRead) {
    if (this.ttsEnabled) {
      window.VIZIO.Chromevox.play(textToRead || '');
    }
  }

  getHostURL() {
    return 'viziosecure.appliance.us';
  }

  getLocalConfigURL() {
    return 'https://global.api.iheart.com/api/v3/locationConfig';
  }

  getHostURLForConfig() {
    return 'viziosecure.appliance';
  }

  getSalt() {
    return '64225';
  }

  getTerminalId() {
    return '362';
  }

  getClientType() {
    return 'viziosecure';
  }

  getDeviceName() {
    return 'viziosecure';
  }

  getPlatformName() {
    return 'Vizio';
  }

  getAdobeAnaliticsName() {
    return 'vizio.appliance.us';
  }

  getTritonDevVariable() {
    return 'vizio';
  }
}
