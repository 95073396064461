import axios from 'axios';

const getPLSTracks = async plsUrl => {
  try {
    const { data } = await axios.get(plsUrl);

    let individualLines = data.split('\n');
    let tracks = [];
    individualLines.forEach(line => {
      if (line.includes('File')) {
        tracks.push(line.split('=')[1]);
      }
    });
    return tracks;
  } catch (err) {
    console.error('Error while executing getPlsTracks', err);
    throw err;
  }
};

export { getPLSTracks };
