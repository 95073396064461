import React, { Component } from 'react';
import { fancyTimeFormat } from '../../lib/utils';
import isNumber from 'lodash/isNumber';
import cx from 'classnames';

import './ProgressBar.scss';

export default class ProgressBar extends Component {
  render() {
    const { currentTime, duration, focused, className, hideTime } = this.props;

    let progressBarTime =
      currentTime && (currentTime > duration ? duration : currentTime);

    const progress =
      isNumber(duration) && isNumber(progressBarTime)
        ? progressBarTime / duration
        : 0;

    let fancyCurrentTime =
      isNumber(progressBarTime) && progressBarTime > 0
        ? fancyTimeFormat(progressBarTime)
        : '00:00';
    let fancyDuration = isNumber(duration)
      ? fancyTimeFormat(duration)
      : '--:--';

    return (
      <div className={cx('progressBar', { [className]: className })}>
        {!hideTime && (
          <div className="progressBar__playTimeDisplay">{fancyCurrentTime}</div>
        )}
        <div className="progressBar__barWrapper">
          <div className="progressBar__bar" />
          <div
            className={cx({ focused }, 'progressBar__indicator')}
            style={{ width: `${(progress || 0) * 100}%` }}
          />
        </div>
        {!hideTime && (
          <div className="progressBar__playTimeDisplay progressBar__playTimeDisplay--duration">
            {fancyDuration}
          </div>
        )}
      </div>
    );
  }
}
