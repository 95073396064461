export const INITIALIZE = 'appNavigation/INITIALIZE';
export const SET_CURRENT_PAGE = 'appNavigation/SET_CURRENT_PAGE';
export const UPDATE_FOCUS = 'appNavigation/UPDATE_FOCUS';
export const UPDATE_SCROLL = 'appNavigation/UPDATE_SCROLL';
export const UPDATE_SCROLL_POSITION = 'appNavigation/UPDATE_SCROLL_POSITION';
export const UPDATE_ADD_POPUP = 'appNavigation/UPDATE_ADD_POPUP';
export const SET_APP_NAVIGATION = 'appNavigation/SET_APP_NAVIGATION';

export function initializeNav() {
  return { type: INITIALIZE };
}

export function setAppNavigation(appNavigation) {
  return { type: SET_APP_NAVIGATION, appNavigation };
}

export function setCurrentPage(page) {
  return { type: SET_CURRENT_PAGE, page };
}

export function updateFocus(changeFocusTo) {
  if (process.env.REACT_APP_TOUCH === 'true')
    return { type: 'appNavigation/NO_UPDATE_FOCUS' };
  return {
    type: UPDATE_FOCUS,
    data: { changeFocusTo }
  };
}

export function updateScrollPosition(currentPosition) {
  return {
    type: UPDATE_SCROLL_POSITION,
    currentPosition
  };
}

export function updateAddPopup(showAddPopup, selectedItem, listMenuFocusIdx) {
  return {
    type: UPDATE_ADD_POPUP,
    addPopup: {
      showAddPopup,
      selectedItem,
      listMenuFocusIdx
    }
  };
}

const determineNewFocus = (state, action) => {
  let newFocusObject = Object.assign({}, state, {
    currentFocus: action.data.changeFocusTo
  });

  if (
    state.currentFocus !== 'exitModal' &&
    state.currentFocus !== 'loadingSpinner'
  ) {
    newFocusObject.lastFocus = state.currentFocus;
  }

  return newFocusObject;
};

const initialState = {
  currentFocus:
    process.env.REACT_APP_TOUCH === 'true' ? '' : 'container/mainMenu',
  translateXMainContent: 0,
  havePassed: 0,
  currentPosition: {
    left: 0,
    right: 0
  },
  lastFocus: null,
  addPopup: {
    showAddPopup: false,
    selectedItem: null,
    listMenuFocusIdx: undefined
  }
};

export default function navigationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_APP_NAVIGATION:
      return Object.assign({}, action.appNavigation);
    case SET_CURRENT_PAGE:
      return Object.assign({}, state, { page: action.page });
    case UPDATE_FOCUS:
      return determineNewFocus(state, action);
    case UPDATE_SCROLL_POSITION:
      return { ...state, currentPosition: action.currentPosition };
    case UPDATE_ADD_POPUP:
      return { ...state, addPopup: action.addPopup };
    case INITIALIZE:
      return initialState;
    default:
      return state;
  }
}
