import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import ListMenu from '../../lib/reactv-navigation/components/ListMenu';
import MenuItem from '../MenuItem/MenuItem';
import BackButton from '../BackButton';
import logo from '../../img/mainMenu/logo.svg';

import './MainMenu.scss';

class MainMenu extends Component {
  constructor(p) {
    super(p);
    this.menuItems = [
      {
        title: 'Home',
        path: '/'
      },
      {
        title: 'Your Library',
        path: '/library'
      },
      {
        title: 'Search',
        path: '/search'
      },
      {
        title: 'Settings',
        path: '/settings'
      }
    ];
  }

  render() {
    const {
      pageTitle,
      withoutGradient,
      onEnter,
      focused,
      onDown,
      hideMenu,
      location,
      lastFocus,
      shouldShowTitle
    } = this.props;
    let onFocusIndex = 0;

    if (lastFocus) {
      if (lastFocus.includes('library')) {
        onFocusIndex = 1;
      } else if (lastFocus.includes('search')) {
        onFocusIndex = 2;
      } else if (lastFocus.includes('settings')) {
        onFocusIndex = 3;
      }
    }

    if (location.pathname === '/library' && onFocusIndex !== 1)
      onFocusIndex = 1;

    return hideMenu ? null : (
      <div
        id="mainMenu"
        data-test="mainMenu"
        className={cx('mainMenu', {
          'mainMenu--withoutGradient': pageTitle || withoutGradient,
          'mainMenu--backButton':
            process.env.REACT_APP_TOUCH === 'true' && location.pathname !== '/'
        })}
      >
        {process.env.REACT_APP_TOUCH === 'true' &&
          location.pathname !== '/' && <BackButton />}
        <img className="mainMenu__logo" src={logo} alt="logo" />
        {shouldShowTitle && pageTitle ? (
          <div className="mainMenu__title">{pageTitle}</div>
        ) : (
          <ListMenu
            data={this.menuItems}
            renderItem={MenuItem}
            className="mainMenu__items"
            horizontal
            onEnter={onEnter}
            focused={focused}
            onDown={onDown}
            onFocusIndex={onFocusIndex}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    view: { title: pageTitle },
    appNavigation: { lastFocus }
  } = state;
  return { pageTitle, lastFocus };
};

export default withRouter(connect(mapStateToProps)(MainMenu));
