import axios from 'axios';
import {
  getV3Headers,
  getV2APIRoute,
  postV2APIRoute,
  putV3APIRoute,
  deleteV3APIRoute
} from './utils';
import { getTracksInfo } from './catalog';
import { BASE_URL } from '../constants';
import {
  getUserProfile,
  getFavoriteLiveAndArtistRadioStationsFromUserProfile
} from './profile.js';
import { getLibraryCollectionPlaylists } from '../api/collection.js';

import store from '../store';

const FOLLOW_PLAYLIST =
  '/api/v3/collection/user/{originalUserId}/collection/{collectionId}/followers';
const GET_PLAYLIST =
  '/api/v2/playlists/{profileId}/{playlistType}/{contentIds}';

export const getPlaylist = async (contentType, contentIds, params) => {
  const { profileId } = store.getState().session;
  let playlistType =
    contentType === 'LRRM' || contentType === 'LIVE' ? 'LIVE' : 'ARTIST'; // TODO
  const playlistRoute = GET_PLAYLIST.replace('{profileId}', profileId)
    .replace('{playlistType}', playlistType)
    .replace('{contentIds}', contentIds);
  return getV2APIRoute(playlistRoute, params);
};

export const getPlaylistInfo = async ({ userId, collectionId }) => {
  let data = await getPlaylistTracks({ userId, collectionId }, {});
  if (!data) {
    return false;
  }
  const trackIds = data.tracks.map(track => track.trackId);
  const tracksURL = encodeURIComponent(trackIds);

  data.tracks = await getTracksInfo(tracksURL);
  if (data.tracks && data.tracks.length) {
    data.tracks.forEach((track, idx) => {
      track.playlistIndex = idx;
      track.collectionId = collectionId;
      track.userId = userId;
      // HACK - this is so that Playlist cards on the ViewAll page know which playlist they're part of
      // Adding all the tracks to each track might cause errors - try to remove this line
      track.playlistTrackIds = data.tracks;
    });
  }
  return data;
};

export const saveRadioStation = async (contentType, contentId, notFavorite) => {
  const { session, audioPlayer } = store.getState();
  const { profileId } = session;
  const { stationId, currentAsset, type, artistId } = audioPlayer;
  if (!contentType) {
    contentType = type;
  }
  if (!contentId) {
    contentId =
      contentType === 'LIVE'
        ? stationId
        : contentType === 'ARTIST'
          ? artistId
          : currentAsset.id;
  }
  const getPlaylistRoute = GET_PLAYLIST.replace('{profileId}', profileId)
    .replace('{playlistType}', contentType)
    .replace('{contentIds}', contentId);
  const params = new URLSearchParams();
  params.append('addToFavorites', notFavorite ? false : true);
  params.append('contentId', contentId);

  const response = await postV2APIRoute(getPlaylistRoute, params, true);
  await getUserProfile();
  getFavoriteLiveAndArtistRadioStationsFromUserProfile();
  return response.data;
};

export const unsaveRadioStation = async (stationType, stationId) => {
  const { session } = store.getState();
  const { profileId, sessionId } = session;

  const deleteStationRoute = '/api/v2/profile/{profileId}/favorites/station/{stationType}/{stationId}/delete'
    .replace('{profileId}', profileId)
    .replace('{stationType}', stationType)
    .replace('{stationId}', stationId);

  const params = { profileId, sessionId, stationId, stationType };

  await postV2APIRoute(deleteStationRoute, params);
  // Refresh library after removing artist

  await getUserProfile();
  getFavoriteLiveAndArtistRadioStationsFromUserProfile();
};

export const followPlaylist = async idString => {
  let [userId, id] = idString.split('-');

  const followUrl = FOLLOW_PLAYLIST.replace('{originalUserId}', userId).replace(
    '{collectionId}',
    id
  );
  const res = await putV3APIRoute(followUrl);
  if (res.status === 200) {
    getLibraryCollectionPlaylists();
  }
};

export const removePlaylist = async idString => {
  let [userId, id] = idString.split('-');

  const followUrl = FOLLOW_PLAYLIST.replace('{originalUserId}', userId).replace(
    '{collectionId}',
    id
  );
  const res = await deleteV3APIRoute(followUrl);
  if (res.status === 204) {
    getLibraryCollectionPlaylists();
  }
};

export const getPlaylistTracks = async (
  { userId, collectionId },
  params = {}
) => {
  const { sessionId, profileId } = store.getState().session;
  const headers = getV3Headers(sessionId, profileId);
  const collectionURL = '/api/v3/collection/user/{userId}/collection/{collectionId}'
    .replace('{userId}', userId)
    .replace('{collectionId}', collectionId);
  const url = BASE_URL + collectionURL;
  try {
    const { data: response } = await axios.get(url, { headers, params });
    return response;
  } catch (err) {
    console.log(`Error calling followPlaylist for user ${profileId}`, err);
  }
};

export const checkIfArtistRadioExists = async seedArtistId => {
  const { profileId } = store.getState().session;
  const artistRadioRoute = '/api/v2/playlists/{profileId}/ARTIST/seedId/{seedArtistId}'
    .replace('{profileId}', profileId)
    .replace('{seedArtistId}', seedArtistId);
  const artistStation = await getV2APIRoute(artistRadioRoute);
  return artistStation.value;
};
