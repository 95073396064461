/**
 * Scale input size based on current screen size ratio and round a number downward to its nearest integer
 * @param {number} input - Input number to be recalculated for current screen resolution
 * @returns {number} Recalculated number for current screen resolution
 */
export function s(input, offset = 0) {
  let screenSizeFactor;

  if (process.env.REACT_APP_SCREENSIZE === '1080p') {
    screenSizeFactor = 1;
    return input;
  } else {
    screenSizeFactor = 1.5;
  }

  let outputNumber = Math.floor(input / screenSizeFactor);

  return outputNumber + offset;
}
