import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import isNumber from 'lodash/isNumber';
import toLocaleDateString from '../../lib/date';
import { fancyTimeFormat } from '../../lib/utils';
import { Touch } from '../../lib/reactv-touch';

import './Screensaver.scss';

class Screensaver extends Component {
  constructor(props) {
    super(props);
    this.itemRef = React.createRef();
  }
  componentDidMount() {
    this.touch = new Touch(this.itemRef.current, {
      ontap: this.props.dismiss
    });
  }
  componentWillUnmount() {
    this.touch && this.touch.unsubscribe();
  }
  render() {
    const { station, currentTime, duration } = this.props;
    if (!station.image) return null;
    const date =
      station.date &&
      toLocaleDateString(station.date, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    const infoImage = station.contentType === 'LIVE' && station.image;
    const showUpperTitle =
      station.contentType === 'ARTIST' || station.contentType === 'ALBUM';
    const playerImage =
      (station.liveRadioCurrentSong &&
        station.liveRadioCurrentSong.imagePath) ||
      station.image;
    const playerUppertitle =
      station.contentType === 'ARTIST'
        ? station.fullPlayerTitle
        : (station.liveRadioCurrentSong &&
            station.liveRadioCurrentSong.artist) ||
          station.upperTitle;
    const playerTitle =
      (station.liveRadioCurrentSong && station.liveRadioCurrentSong.title) ||
      station.title;
    const playerSubtitle =
      date ||
      (station.liveRadioCurrentSong && station.liveRadioCurrentSong.artist) ||
      station.subtitle;

    const fancyCurrentTime =
      isNumber(currentTime) && currentTime > 0
        ? fancyTimeFormat(currentTime)
        : '00:00';
    const fancyDuration = isNumber(duration)
      ? fancyTimeFormat(duration)
      : '--:--';
    const progress =
      station.contentType !== 'LIVE' &&
      `${fancyCurrentTime} \u00a0 / \u00a0 ${fancyDuration}`;

    return (
      <div className="screensaver" ref={this.itemRef}>
        <div
          className="screensaver__background"
          style={{ backgroundImage: `url(${playerImage})` }}
        />
        <div
          className="screensaver__image"
          style={{ backgroundImage: `url(${playerImage})` }}
        />
        <div className="screensaver__info">
          <div
            className={cx('screensaver__infoShadow', {
              'screensaver__infoShadow--withUpperTitle': showUpperTitle
            })}
          />
          <div
            className={cx('screensaver__trackInfoWrapper', {
              'screensaver__trackInfoWrapper--infoImage': infoImage
            })}
          >
            {infoImage && (
              <img className="screensaver__infoImage" alt="" src={infoImage} />
            )}
            <div className="screensaver__titlesWrapper">
              {showUpperTitle && (
                <div className="screensaver__upperTitle">
                  {playerUppertitle}
                </div>
              )}
              <div className="screensaver__title">{playerTitle}</div>
              <div className="screensaver__desc">{playerSubtitle}</div>
            </div>
          </div>
          <div className="screensaver__progress">{progress}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  station: state.station,
  currentTime: state.audioPlayer.currentTrackTime,
  duration: state.audioPlayer.currentTrackDuration
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Screensaver);
