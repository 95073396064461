import Browser from './platforms/base.js';
import Vizio from './platforms/vizio.js';
import Enseo from './platforms/enseo.js';

const isVizio = process.env.REACT_APP_PLATFORM === 'vizio';
const isEnseo = process.env.REACT_APP_PLATFORM === 'enseo';

var ReactvPlatforms = {
  platform: isVizio ? new Vizio() : isEnseo ? new Enseo() : new Browser(),
  isVizio,
  isEnseo
};

export default ReactvPlatforms;
