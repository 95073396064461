import ReactvPlatforms from '../lib/reactv-platforms/index';
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

const calculateText = type => {
  switch (type) {
    case 'home-anon':
      return 'To sync Your Library across all devices, go to Settings to sign up or login';
    case 'od-song':
      return 'You’ve discovered an All Access feature! To play a song on demand, sign up in Settings.';
    case 'artist-latest':
      return "You've discovered an All Access feature! Please enjoy this artist radio or upgrade in Settings.";
    case 'artist-topsong':
      return 'Only All Access users can select songs. Upgrade in Settings to play any song!';
    case 'on-demand':
      return 'Only All Access users can listen on demand. Upgrade in Settings to play any song!';
    case 'library-album-restricted':
      return 'Only All Access users can listen to albums. Upgrade in Settings to play any album!';
    case 'library-songs-restricted':
      return 'Only All Access users can listen to songs. Upgrade in Settings to play any song!';
    case 'save-album-disabled':
      return 'You’ve discovered an All Access feature! Upgrade in Settings to save this album.';
    case 'miniplayer-save-disabled':
      return 'You’ve discovered an All Access feature! Upgrade in Settings to save this song.';
    case 'miniplayer-save-song':
      return 'Song saved!';
    case 'miniplayer-hourlimit':
      return 'You’ve reached the skip limit for this station. Try another one!';
    case 'miniplayer-daylimit':
      return 'Oh no, you’ve run out of skips! Get unlimited skips by upgrading to All Access.';
    case 'fullplayer-hourlimit':
      return 'You’ve reached the skip limit for this station. Try another one!';
    case 'fullplayer-daylimit':
      return 'Oh no, you’ve run out of skips! Get unlimited skips by upgrading to All Access.';
    case 'fullplayer-save':
      return 'You’ve discovered an All Access feature! Upgrade in Settings to save this song.';
    case 'fullplayer-save-song':
      return 'Song saved!';
    case 'unsupported-key':
      return 'This button is not supported by this app';
    case 'unsupported-plus':
      return `We do not support Plus accounts on ${ReactvPlatforms.platform.getPlatformName()}, but please enjoy our free experience!`;
    case 'empty-playlist':
      return 'You have no saved songs in this playlist.';
    default:
      break;
  }
};

export function addToast(data) {
  return function(dispatch, getState) {
    let toasts = getState().toasts;
    if (toasts.find(item => item.type === data.type)) return;
    if (
      process.env.REACT_APP_TOUCH === 'true' &&
      data.type === 'unsupported-key'
    )
      return;
    data.id = new Date().getTime();
    data.text = calculateText(data.type);
    dispatch({
      type: ADD_TOAST,
      toast: data
    });
  };
}

export function removeToast(toast) {
  return {
    type: REMOVE_TOAST,
    toast
  };
}

const removeToastFromReducer = (state, toast) => {
  let newState = [...state];
  newState = newState.filter(item => item.type !== toast.type);

  return newState;
};

const initialState = [];

export default function toastsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TOAST:
      return [...state, action.toast];
    case REMOVE_TOAST:
      return removeToastFromReducer(state, action.toast);
    default:
      return state;
  }
}
