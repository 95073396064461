import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import GridMenusWrapper from '../../components/GridMenusWrapper';
import Button from '../../components/Button/Button';
import { listGenres } from '../../api/catalog';
import { saveGenreSelections } from '../../api/taste';
import { forYou } from '../../api/recommendations';
import { updateCardPiles } from '../../reducers/cards';
import { updateFocus } from '../../reducers/appNavigation';
import { setUserGenres } from '../../reducers/taste';
import './GenreListing.scss';

class GenreListing extends Component {
  constructor(props) {
    super(props);

    this.idForGrid = 'genreListing'; // should match with path page
    this.AMOUNT_TO_MOVE_SCROLL_BY = 325;
    this.onSave = this.onSave.bind(this);
    this.toggleGenreSelection = this.toggleGenreSelection.bind(this);
    this.focusOnGridMenu = this.focusOnGridMenu.bind(this);
    this.focusOnSaveButton = this.focusOnSaveButton.bind(this);
  }

  async componentDidMount() {
    const { session = {}, updateCardPiles } = this.props;

    if (!session.sessionId) {
      this.props.history.replace('/');
    }
    const genresData = await listGenres();
    updateCardPiles([{ key: 'genres', data: genresData.genres }]);
    if (!this.props.currentFocus.includes('genreListing')) {
      this.props.updateFocus('genreListing/gridMenus/genreListing');
    }
  }

  focusOnGridMenu() {
    this.props.updateFocus('genreListing/gridMenus/genreListing');
  }

  focusOnSaveButton() {
    this.props.updateFocus('genreListing/saveButton');
  }

  isSaveButtonFocused() {
    return this.props.currentFocus === 'genreListing/saveButton';
  }

  async onSave() {
    try {
      const { session, updateCardPiles, favorites = {} } = this.props;
      const favs = [];
      for (let fav in favorites) {
        favs.push(parseInt(fav, 10));
      }
      await saveGenreSelections(favs, session);
      const { values: data } = await forYou();
      await updateCardPiles([{ key: 'recommendations', data }]);
      this.props.history.goBack();
    } catch (err) {
      // TODO UI err feedback
    }
  }

  toggleGenreSelection(genre) {
    const { favorites = {}, setUserGenres } = this.props;
    const newFavorites = Object.assign({}, favorites);
    const { id } = genre;
    if (favorites[id]) delete newFavorites[id];
    else newFavorites[id] = true;
    setUserGenres(newFavorites);
  }

  render() {
    const { onUp, genres, favorites, currentScrollPosition } = this.props;

    if (!genres) {
      return null;
    }

    const isSaveButtonEnabled = favorites && Object.keys(favorites).length > 0;

    const data = genres.map(genre => {
      genre.kind = 'genreListing';
      return genre;
    });

    let menusData = [
      {
        constantWidth: true,
        onUp,
        onDown: isSaveButtonEnabled ? this.focusOnSaveButton : null,
        menuid: 'genreListing',
        data,
        dataContentType: 'GENRE',
        isSquare: true,
        favorites
      }
    ];

    return (
      <div className="genreListing">
        <div className="genreListing__logo" />
        <GridMenusWrapper
          className="genreListing__gridMenus"
          idForGrid={this.idForGrid}
          menusData={menusData}
          onEnter={this.toggleGenreSelection}
          AMOUNT_TO_MOVE_SCROLL_BY={this.AMOUNT_TO_MOVE_SCROLL_BY}
        />
        <Button
          className={cx('genreListing__button')}
          focused={this.isSaveButtonFocused()}
          onUp={this.focusOnGridMenu}
          onEnter={this.onSave}
          style={{ marginLeft: `${-currentScrollPosition}px` }}
          disabled={!isSaveButtonEnabled}
          label={'Save'}
        />
        {process.env.REACT_APP_TOUCH === 'true' && (
          <Button
            className={cx(
              'genreListing__button',
              'genreListing__button--cancel'
            )}
            onEnter={() => {
              this.props.history.goBack();
            }}
            label={'Cancel'}
          />
        )}
      </div>
    );
  }
}

const onSaveRequiredIfTaste = (props, onSave, componentName) => {
  if (
    props['mode'] === 'taste' &&
    (props[onSave] === undefined || typeof props[onSave] != 'function')
  ) {
    return new Error('onSave function is required is showing genre tastes');
  }
};

GenreListing.propTypes = {
  mode: PropTypes.oneOf(['catalog', 'taste']),
  headerLabel: PropTypes.string,
  onSave: onSaveRequiredIfTaste,
  onSaveCompleted: PropTypes.func
};

const mapStateToProps = state => {
  const { taste, session, cards, appNavigation } = state;
  return {
    session,
    genres: cards.genres,
    favorites: taste.favorites,
    currentFocus: appNavigation.currentFocus,
    currentScrollPosition: appNavigation.translateXMainContent
  };
};

export default connect(
  mapStateToProps,
  { setUserGenres, updateCardPiles, updateFocus }
)(GenreListing);
