import React from 'react';
import cx from 'classnames';
import AtoZMenu from '../../components/AtoZMenu';
import GridMenusWrapper from '../../components/GridMenusWrapper/GridMenusWrapper';
import OneItemMenu from '../../components/OneItemMenu/OneItemMenu';
import SearchIcon from '../../img/search/search.js';
import EditIcon from '../../img/search/edit.js';

const searchTypeLookup = {
  tracks: 'SONG',
  artists: 'ARTIST',
  stations: 'LIVE',
  playlists: 'PLAYLIST',
  podcasts: 'PODCAST',
  albums: 'ALBUM'
};

const searchLabelLookup = {
  tracks: 'Songs',
  artists: 'Artists',
  stations: 'Live',
  playlists: 'Playlists',
  podcasts: 'Podcasts',
  albums: 'Albums'
};

const isConstantWidth = type => type === 'albums' || type === 'tracks';

const Search = ({
  onSelection,
  appNavigation,
  trends,
  onUp,
  onDown,
  updateFocus,
  onLetter,
  search,
  idForGrid,
  isEmpty,
  resetSearch,
  updateIsTouchFieldFocused,
  isTouchFieldFocused
}) => {
  const currentFocus = appNavigation.currentFocus;
  const isAtoZMenuFocused = currentFocus === 'search/content';
  const { results, bestMatch, topResult, loading } = search;

  let menusData = [];
  let firstMenu;
  if (!results) {
    if (trends && trends.length) {
      firstMenu = 'search-popular';
      menusData.push({
        label: 'Popular on iHeartRadio',
        menuid: firstMenu,
        data: trends,
        xCards: 10,
        onUp,
        onDown
      });
    }
  } else {
    const keys = Object.keys(results).sort((a, b) => {
      if (a === 'stations') {
        return -1;
      } else if (b === 'stations') {
        return 1;
      } else if (a === 'artists') {
        return -1;
      } else if (b === 'artists') {
        return 1;
      } else if (a === 'podcasts') {
        return -1;
      } else if (b === 'podcasts') {
        return 1;
      } else if (a === 'playlists') {
        return -1;
      } else if (b === 'playlists') {
        return 1;
      } else if (a === 'tracks') {
        return -1;
      } else if (b === 'tracks') {
        return 1;
      } else if (a === 'tracks') {
        return -1;
      } else if (b === 'albums') {
        return 1;
      } else {
        return 0;
      }
    });

    keys.forEach(key => {
      const result = results[key];
      if (result.length) {
        menusData.push({
          constantWidth: isConstantWidth(key),
          isSquare: isConstantWidth(key),
          label: searchLabelLookup[key],
          menuid: `search-${key}`,
          data: result,
          dataContentType: searchTypeLookup[key],
          xCards: key === 'tracks' || key === 'albums' ? 4 : 3,
          onUp,
          onDown
        });
      }
    });
    firstMenu = menusData[0].menuid;
  }
  const hasBestMatch = bestMatch && topResult;
  const OFFSET_SCROLL = hasBestMatch ? 1300 : 500; // we'll need to add s func here
  const topMatch = topResult ? topResult.artist || topResult[0] : undefined;

  return (
    <div className="search">
      <div
        className={cx('search__leftWrapper', {
          'search__leftWrapper--touchField':
            process.env.REACT_APP_TOUCH === 'true'
        })}
      >
        {process.env.REACT_APP_TOUCH === 'true' ? (
          <form
            className="search__touchFieldWrapper"
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              document.querySelector('.search__touchField').blur();
            }}
          >
            <input
              className={cx('search__touchField', {
                'search__touchField--active': search.term
              })}
              id="touchField"
              placeholder="Tap to Enter Search"
              onFocus={() => {
                updateIsTouchFieldFocused(true);
              }}
              onBlur={() => {
                updateIsTouchFieldFocused(false);
              }}
            />
            <div
              className="search__touchFieldIcon"
              onClick={() => {
                resetSearch();
              }}
            >
              {search.term ? (
                <EditIcon />
              ) : (
                <SearchIcon
                  fill={isTouchFieldFocused ? '#ffffff' : '#848484'}
                />
              )}
            </div>
          </form>
        ) : (
          <React.Fragment>
            <div
              className={cx('search__field', {
                'search__field--active': search.term
              })}
            >
              {search.term || 'Enter Search'}
            </div>
            <AtoZMenu
              className="search__keyboard"
              mid="atoz"
              focused={isAtoZMenuFocused}
              onEnter={onLetter}
              onUp={onUp}
              onDown={onDown}
              onRight={() => {
                const toRight = hasBestMatch
                  ? 'search/bestMatch'
                  : `search/gridMenus/${firstMenu}`;
                if (!isEmpty) updateFocus(toRight);
              }}
            />
          </React.Fragment>
        )}
      </div>
      {isEmpty ? (
        loading ? (
          <div className="search__emptyResult">
            <div>{'Loading...'}</div>
          </div>
        ) : (
          <div className="search__emptyResult">
            <div>{`There are no results for ${search.term}`}</div>
            <div>{'Check your spelling or try another search.'}</div>
          </div>
        )
      ) : (
        <div className="search__contentWrapper">
          {hasBestMatch && (
            <OneItemMenu
              focused={currentFocus === 'search/bestMatch'}
              className="search__bestMatch"
              title="BEST MATCH"
              dataContentType={bestMatch.format}
              big
              onEnter={() => {
                onSelection(topMatch);
              }}
              onUp={onUp}
              onDown={onDown}
              onLeft={() => {
                updateFocus('search/content');
                // navigating leftward from Best Match
                let mainContainer = document.querySelector('.mainContainer');
                if (mainContainer.scrollLeft) {
                  // reset scroll, if needed, when navigating back to Hero
                  mainContainer.scrollTo(0, 0);
                }
              }}
              onRight={() => {
                if (!isEmpty) updateFocus(`search/gridMenus/${firstMenu}`);
              }}
              item={topMatch}
            />
          )}
          <GridMenusWrapper
            onLeft={() => {
              const toLeft = hasBestMatch
                ? 'search/bestMatch'
                : 'search/content';
              if (!hasBestMatch) {
                // navigating leftward from search results
                let mainContainer = document.querySelector('.mainContainer');
                if (mainContainer.scrollLeft) {
                  // reset scroll, if needed, when navigating back to Hero
                  mainContainer.scrollTo(0, 0);
                }
              }
              updateFocus(toLeft);
            }}
            className="search__gridMenus"
            idForGrid={idForGrid}
            menusData={menusData}
            onEnter={onSelection}
            AMOUNT_TO_MOVE_SCROLL_BY={250}
            OFFSET_SCROLL={OFFSET_SCROLL}
            showArtistNameForSong
          />
        </div>
      )}
    </div>
  );
};

export default Search;
