import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Touch } from '../../lib/reactv-touch';
import BaseButton from '../BaseButton/BaseButton.js';
import './MenuItem.scss';
import cx from 'classnames';

class MenuItem extends BaseButton {
  constructor(p) {
    super(p);

    this.itemRef = React.createRef();
  }

  componentDidMount() {
    if (!this.itemRef.current) return;

    this.touch = new Touch(this.itemRef.current, {
      ontap: e => {
        this.itemRef.current.classList.add('clicked');

        clearTimeout(this.emit);
        this.emit = setTimeout(() => {
          try {
            this.props.onEnter(this.props.item);
          } catch (error) {
            console.error(
              "Can't trigger button action: ",
              this.itemRef.current
            );
          }
        }, 0);

        setTimeout(() => {
          this.itemRef.current &&
            this.itemRef.current.classList.remove('clicked');
        }, 550);
      }
    });
  }
  readTTS() {
    return this.props.item.title;
  }

  render() {
    const {
      switcher,
      value,
      title,
      id,
      kind,
      path,
      fullPath,
      active
    } = this.props.item;
    const isSettings = kind === 'settingsItem';
    return isSettings ? (
      <div
        key={id}
        className={cx('menuItem menuItem--settingsItem', {
          'menuItem--focused': this.props.focused,
          'menuItem--switcher': switcher,
          'menuItem--active': active
        })}
        ref={this.itemRef}
      >
        <div className="menuItem__wrapper">{title}</div>
        {switcher && (
          <div className="menuItem__switcherValue">{value ? 'On' : 'Off'}</div>
        )}
      </div>
    ) : (
      <NavLink
        to={fullPath || path}
        key={id}
        exact
        activeClassName="menuItem--active"
        className={cx('menuItem', {
          'menuItem--focused': this.props.focused,
          'menuItem--switcher': switcher
        })}
      >
        <div className="menuItem__wrapper">{title}</div>
        {switcher && (
          <div className="menuItem__switcherValue">{value ? 'On' : 'Off'}</div>
        )}
      </NavLink>
    );
  }
}

MenuItem.propTypes = {
  item: PropTypes.object.isRequired
};

const menuItemComponent = props => {
  return <MenuItem {...props} />;
};

export default menuItemComponent;
