import React from 'react';
import { connect } from 'react-redux';
import ProfileView from '../../components/ProfileView';
import { getPodcastProfile, getPodcastEpisodes } from '../../api/podcast';
import {
  setPageTitle,
  showLoadingSpinner,
  hideLoadingSpinner
} from '../../reducers/view';
import { updateFocus } from '../../reducers/appNavigation';
import {
  utilForUpdatingLibrary,
  isItemSavedInApp,
  toggleFollowPodcast
} from '../../reducers/library';
import { s } from '../../lib/screen-size.js';
import ThreeRowCard from '../../components/ThreeRowCard';
import PlusIcon from '../../img/button/plusIcon.js';

class Podcast extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'SCREEN:LOADING'
    };

    this.getRouteData = this.getRouteData.bind(this);
    this.updateFocus = this.updateFocus.bind(this);
    this.getPodcast = this.getPodcast.bind(this);
    this.onToggleFollow = this.onToggleFollow.bind(this);
  }

  componentWillMount() {
    const {
      session: { sessionId }
    } = this.props;

    if (!sessionId) {
      this.props.history.replace('/');
    }

    this.getRouteData();
  }

  componentDidMount() {
    this.props.setPageTitle(' ');
    this.props.showLoadingSpinner();
    this.unlisten = this.props.history.listen(() => {
      this.props.showLoadingSpinner();

      this.setState(
        {
          view: 'SCREEN:LOADING',
          profile: null
        },
        () => this.getRouteData()
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      prevProps.station.currentSong &&
      this.props &&
      this.props.station.currentSong
    ) {
    }
    if (
      (prevProps &&
        prevProps.station.currentSong &&
        this.props &&
        this.props.station.nextSong &&
        prevProps.station.currentSong.content.podcastId !==
          this.props.station.nextSong.content.podcastId) ||
      (prevProps &&
        !prevProps.station.nextSong &&
        this.props.station.nextSong &&
        this.props.station.nextSong.content)
    ) {
      const { contentType } = this.props.station;
      const isCurrentPlayerStation =
        contentType === 'PODCAST_EPISODE' &&
        this.state.data &&
        this.props.station.nextSong.content.podcastId ===
          this.state.data.podcast.id;
      this.setState({
        isCurrentPlayerStation
      });
    }
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
  }

  prepareForProfileView(data, params) {
    const {
      podcast: { id: contentId, description, title },
      episodes
    } = data;
    const menusData = [];

    if (Array.isArray(episodes) === true && episodes.length > 0) {
      episodes.forEach(item => {
        item.podcastTitle = title;
        return item;
      });
      menusData.push({
        content: episodes,
        details: {
          label: 'Episodes',
          menuid: `${params.idForGrid}-podcast-episodes`,
          dataContentType: 'PODCAST_EPISODE',
          renderItem: ThreeRowCard,
          threeRows: true,
          constantWidth: true,
          hideViewAllCard: true
        },
        xCards: 15
      });
    }

    const infoCard = (
      <div className="profileView__infoSectionDescr">
        {description.length > 148
          ? `${description.slice(0, 145).trim()}...`
          : description}
      </div>
    );

    const view = {
      contentId,
      thumbnail: `http://i.iheart.com/v3/catalog/podcast/${contentId}?ops=fit(214, 214)`,
      menusData,
      showDotsButton: process.env.REACT_APP_TOUCH !== 'true',
      infoCard,
      OFFSET_SCROLL: s(900),
      AMOUNT_TO_MOVE_SCROLL_BY: 560,
      AMOUNT_TO_MOVE_SCROLL_BETWEEN_MENUS: 150
    };
    let itemStateInApp = isItemSavedInApp(
      this.props.library,
      'podcast',
      contentId
    );
    utilForUpdatingLibrary(
      data.podcast.follow,
      itemStateInApp,
      'podcast',
      contentId
    );
    this.setState(
      {
        view: 'SCREEN:PROFILEVIEW',
        profile: { ...view, ...params },
        data
      },
      () => {
        this.props.setPageTitle(title);
        this.props.hideLoadingSpinner();
      }
    );
  }

  onToggleFollow() {
    const {
      match: {
        params: { podcastId }
      }
    } = this.props;
    toggleFollowPodcast(podcastId);
  }

  getRouteData() {
    const {
      match: {
        params: { podcastId }
      }
    } = this.props;

    return this.getPodcast(podcastId);
  }

  updateFocus(idForGrid) {
    this.props.updateFocus(`${idForGrid}/content`);
  }

  async getPodcast(podcastId) {
    const idForGrid = 'podcast';
    if (this.props.podcast === undefined) {
      const podcast = await getPodcastProfile(podcastId);
      if (podcast === false) {
        this.props.hideLoadingSpinner();
        return this.setState({
          view: 'SCREEN:ERROR'
        });
      }

      const episodes = await getPodcastEpisodes(podcastId);
      if (episodes === false) {
        this.props.hideLoadingSpinner();
        return this.setState({
          view: 'SCREEN:ERROR'
        });
      }

      episodes.xCards = 10;
      episodes.podcast = podcast;

      this.prepareForProfileView(episodes, {
        idForGrid
      });
    }
  }

  render() {
    const { view, profile } = this.state;

    if (view === 'SCREEN:LOADING') {
      return null;
    }

    if (view === 'SCREEN:MAINTENANCE') {
      return <div className="under-maintenance">Under maintenance!</div>;
    }

    if (view === 'SCREEN:ERROR' || profile === undefined) {
      return (
        <div className="error">An error occured, please try again later.</div>
      );
    }

    let isFollowed = isItemSavedInApp(
      this.props.library,
      'podcast',
      profile.contentId
    );

    return (
      <ProfileView
        onDown={this.props.onDown}
        buttonsData={[
          {
            id: 'saveBtn',
            icon: PlusIcon,
            text: isFollowed ? 'Remove' : 'Follow',
            isFollowed
          }
        ]}
        background={profile.thumbnail}
        onToggleSave={this.onToggleFollow}
        {...profile}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    session,
    appNavigation: { currentFocus },
    library,
    station
  } = state;

  return {
    session,
    currentFocus,
    library,
    station
  };
};

export default connect(
  mapStateToProps,
  {
    setPageTitle,
    updateFocus,
    showLoadingSpinner,
    hideLoadingSpinner
  }
)(Podcast);
