import ReactvPlatforms from '../reactv-platforms';

const defaultMapping = {
  touchend: 'mouseup',
  touchmove: 'mousemove',
  touchstart: 'mousedown'
};

const vizioMapping = {
  touchend: 'touchend',
  touchmove: 'touchmove',
  touchstart: 'touchstart'
};

const Events = ReactvPlatforms.isVizio ? vizioMapping : defaultMapping;

export default Events;
