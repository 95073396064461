import store from '../store';
import {
  getPodcastProfile,
  followPodcast,
  removePodcast
} from '../api/podcast';
import {
  getPlaylist,
  getPlaylistInfo,
  checkIfArtistRadioExists,
  saveRadioStation,
  unsaveRadioStation,
  followPlaylist,
  removePlaylist
} from '../api/playlists';
import {
  saveTracksToLibrary,
  saveAlbumToLibrary,
  deleteAlbumFromLibrary
} from '../api/collection';
import { checkAlbumIsInLibraryCollection } from '../api/collection';

export const toggleSaveSong = songId => {
  saveTracksToLibrary([songId]);
};

export const toggleSaveAlbum = async album => {
  const { library } = store.getState();
  let itemStateInApp = isItemSavedInApp(library, 'album', album.albumId);
  if (itemStateInApp) {
    deleteAlbumFromLibrary(album)
      .then(result => {
        utilForUpdatingLibrary(false, itemStateInApp, 'album', album.albumId);
      })
      .catch(err => console.log(err));
  } else {
    saveAlbumToLibrary(album)
      .then(result => {
        utilForUpdatingLibrary(true, itemStateInApp, 'album', album.albumId);
      })
      .catch(err => console.log(err));
  }
};

export const toggleSaveStation = async (contentType, id) => {
  const { library } = store.getState();
  const contentTypeToUse = contentType.toLowerCase();

  let itemStateInApp;
  switch (contentTypeToUse) {
    case 'playlist':
      itemStateInApp = isItemSavedInApp(library, 'playlist', id);
      if (itemStateInApp) {
        removePlaylist(id)
          .then(result => {
            utilForUpdatingLibrary(false, itemStateInApp, 'playlist', id);
          })
          .catch(err => console.log(err));
      } else {
        followPlaylist(id)
          .then(result => {
            utilForUpdatingLibrary(true, itemStateInApp, 'playlist', id);
          })
          .catch(err => console.log(err));
      }
      break;

    case 'live':
      itemStateInApp = isItemSavedInApp(library, 'live', id);
      if (itemStateInApp) {
        unsaveRadioStation('LR', id)
          .then(result => {
            utilForUpdatingLibrary(false, itemStateInApp, 'live', id);
          })
          .catch(err => console.log(err));
      } else {
        saveRadioStation('LIVE', id)
          .then(result => {
            utilForUpdatingLibrary(true, itemStateInApp, 'live', id);
          })
          .catch(err => console.log(err));
      }
      break;

    case 'artist':
      itemStateInApp = isItemSavedInApp(library, 'artist', id);
      if (itemStateInApp) {
        const artistRadioStation = await checkIfArtistRadioExists(id);
        unsaveRadioStation('CR', artistRadioStation.id)
          .then(result => {
            utilForUpdatingLibrary(false, itemStateInApp, 'artist', id);
          })
          .catch(err => console.log(err));
      } else {
        saveRadioStation('ARTIST', id)
          .then(result => {
            utilForUpdatingLibrary(true, itemStateInApp, 'artist', id);
          })
          .catch(err => console.log(err));
      }
      break;

    default:
  }
};

export const toggleFollowPodcast = async podcastId => {
  const { library } = store.getState();
  let itemStateInApp = isItemSavedInApp(library, 'podcast', podcastId);

  if (itemStateInApp) {
    removePodcast(podcastId)
      .then(result => {
        utilForUpdatingLibrary(false, itemStateInApp, 'podcast', podcastId);
      })
      .catch(err => console.log(err));
  } else {
    followPodcast(podcastId)
      .then(result => {
        utilForUpdatingLibrary(true, itemStateInApp, 'podcast', podcastId);
      })
      .catch(err => console.log(err));
  }
};

export const updateItemInLibrary = (contentType, id, value) => {
  const type = 'UPDATE_ITEM_IN_LIBRARY';
  return { type, contentType, id, value };
};

export const setLibraryStatus = payload => {
  const type = 'SET_LIBRARY_STATUS';
  return { type, ...payload };
};

export const setLibraryLiveStations = liveStations => {
  const type = 'SET_LIBRARY_LIVE_STATIONS';
  return { type, liveStations };
};

export const setLibraryArtistStations = artistStations => {
  const type = 'SET_LIBRARY_ARTIST_STATIONS';
  return { type, artistStations };
};

export const setLibraryPlaylists = playlists => {
  const type = 'SET_LIBRARY_PLAYLISTS';
  return { type, playlists };
};

export const setLibraryPodcasts = podcasts => {
  const type = 'SET_LIBRARY_PODCASTS';
  return { type, podcasts };
};

export const setLibraryAlbums = albums => {
  const type = 'SET_LIBRARY_ALBUMS';
  return { type, albums };
};

export const setLibrarySongs = songs => {
  const type = 'SET_LIBRARY_SONGS';
  return { type, songs };
};

const initialState = {
  isMenuVisible: false,
  libraryIsEmpty: false,
  songs: []
};

export default function(state = initialState, action) {
  const {
    contentType,
    id,
    value,
    firstMenu,
    isMenuVisible,
    libraryIsEmpty,
    type,
    liveStations,
    artistStations,
    playlists,
    podcasts,
    albums,
    songs
  } = action;
  switch (type) {
    case 'UPDATE_ITEM_IN_LIBRARY':
      let updatedState = JSON.parse(JSON.stringify(state));
      if (!state[`${contentType}Saved`]) {
        updatedState[`${contentType}Saved`] = {};
      }

      updatedState[`${contentType}Saved`][id] = value;
      return updatedState;
    case 'SET_LIBRARY_STATUS':
      return { ...state, libraryIsEmpty, firstMenu, isMenuVisible };
    case 'SET_LIBRARY_LIVE_STATIONS':
      return { ...state, liveStations };
    case 'SET_LIBRARY_ARTIST_STATIONS':
      return { ...state, artistStations };
    case 'SET_LIBRARY_PLAYLISTS':
      return { ...state, playlists };
    case 'SET_LIBRARY_PODCASTS':
      return { ...state, podcasts };
    case 'SET_LIBRARY_ALBUMS':
      return { ...state, albums };
    case 'SET_LIBRARY_SONGS':
      return { ...state, songs };
    default:
      return state;
  }
}

export function isItemSavedInApp(library, type, id) {
  if (!library || !type || !id) return null;

  let switchType = type.toLowerCase();
  switch (
    switchType // hack for inconsistent names
  ) {
    case 'albums':
      switchType = 'album';
      break;
    case 'podcast_episode':
      switchType = 'podcast';
      break;
    default:
  }

  if (
    library &&
    library[`${switchType}Saved`] &&
    library[`${switchType}Saved`][id]
  ) {
    return true;
  } else if (
    library &&
    library[`${switchType}Saved`] &&
    library[`${switchType}Saved`][id] === false
  ) {
    return false;
  } else {
    return undefined;
  }
}

export const utilForUpdatingLibrary = (
  itemIsInLibrary,
  itemStateInApp,
  contentType,
  id
) => {
  if (itemIsInLibrary !== 'err') {
    if (itemIsInLibrary) {
      if (!itemStateInApp) {
        store.dispatch(updateItemInLibrary(contentType, id, true));
      }
    } else {
      if (itemStateInApp) {
        store.dispatch(updateItemInLibrary(contentType, id, false));
      }
    }
  }
};

export async function checkItemIsInLibrary(item) {
  const { library } = store.getState();
  if (!library) {
    return false;
  }
  let itemIsInLibrary = false;
  let itemStateInApp;

  switch (item.contentType) {
    case 'LIVE':
      let stationId = item.stationId ? item.stationId : item.contentId;
      itemStateInApp = isItemSavedInApp(library, 'live', stationId);
      const stationData = await getPlaylist('LIVE', stationId);
      itemIsInLibrary =
        stationData && stationData.hits && stationData.hits.length
          ? stationData.hits[0].favorite
          : 'err';
      utilForUpdatingLibrary(
        itemIsInLibrary,
        itemStateInApp,
        'live',
        stationId
      );
      break;
    case 'ARTIST':
      const artistId = item.artistId || item.sourceId;
      itemStateInApp = isItemSavedInApp(library, 'artist', artistId);
      const artistRadioStation = await checkIfArtistRadioExists(artistId);
      if (!artistRadioStation) {
        break;
      }
      const artistData = await getPlaylist('ARTIST', artistRadioStation.id);
      itemIsInLibrary =
        artistData && artistData.hits && artistData.hits.length
          ? artistData.hits[0].favorite
          : 'err';

      utilForUpdatingLibrary(
        itemIsInLibrary,
        itemStateInApp,
        'artist',
        artistId
      );
      break;
    case 'PLAYLIST':
      let playlistId = item.playlistId ? item.playlistId : item.contentId;
      itemStateInApp = isItemSavedInApp(library, 'playlist', playlistId);
      const playlistData = await getPlaylistInfo({
        userId: item.userId,
        collectionId: playlistId
      });
      itemIsInLibrary = playlistData ? playlistData.followed : 'err';
      utilForUpdatingLibrary(
        itemIsInLibrary,
        itemStateInApp,
        'playlist',
        `${item.userId}-${playlistId}`
      );
      break;
    case 'PODCAST':
    case 'PODCAST_EPISODE':
      let podcastId = item.podcastId
        ? item.podcastId
        : item.currentSong && item.currentSong.content
          ? item.currentSong.content.podcastId
          : null;
      itemStateInApp = isItemSavedInApp(library, 'podcast', podcastId);
      const podcastData = await getPodcastProfile(podcastId);

      itemIsInLibrary = podcastData ? podcastData.follow : 'err';
      utilForUpdatingLibrary(
        itemIsInLibrary,
        itemStateInApp,
        'podcast',
        podcastId
      );
      break;
    case 'ALBUM':
      const albumId = item.albumId ? item.albumId : item.contentId;
      itemStateInApp = isItemSavedInApp(library, 'album', albumId);
      const albumData = await checkAlbumIsInLibraryCollection(albumId);
      if (albumData && albumData.length) {
        itemIsInLibrary = true;
      } else {
        itemIsInLibrary = 'err';
      }
      utilForUpdatingLibrary(itemIsInLibrary, itemStateInApp, 'album', albumId);
      break;
    default:
      break;
  }
  return itemIsInLibrary;
}
