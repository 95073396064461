import React from 'react';
import './OneItemMenu.scss';
import cx from 'classnames';
import buttonComposer from '../../lib/reactv-navigation/components/Buttonizer/Buttonizer';
import MenuCard from '../MenuCard';

const OneItemMenu = ({ title, className, ...rest }) => {
  return (
    <div className={cx('oneItemMenu', { [className]: className })}>
      <div className="oneItemMenu__title">{title}</div>
      <MenuCard {...rest} key={`OneItemMenu`} />
    </div>
  );
};

export default buttonComposer(OneItemMenu);
