import { getV3APIRoute, putV3APIRoute, deleteV3APIRoute } from './utils';
import { setLibraryPodcasts } from '../reducers/library';
import store from '../store';
import { setIsPageLoaded, PAGINATION_PAGE_SIZE } from '../reducers/view';

const PODCAST_PROFILE = '/api/v3/podcast/podcasts/{podcastId}';
const PODCAST_CATEGORIES = '/api/v3/podcast/categories';
const PODCASTS_BY_CATEGORY_ID = '/api/v3/podcast/categories/{categoryId}';
const PODCAST_EPISODE_BY_EPISODE_ID = '/api/v3/podcast/episodes/{episodeId}';
const PODCAST_EPISODES_BY_PODCAST_ID =
  '/api/v3/podcast/podcasts/{podcastId}/episodes';
const PODCAST_DIRECTORY = '/api/v3/podcast/directory';
const PODCAST_FOLLOWS = '/api/v3/podcast/follows';
const PODCAST_FOLLOW = '/api/v3/podcast/follows/{podcastId}';
const PODCAST_EPISODE_PROGRESS_UPDATE =
  '/api/v3/podcast/podcasts/{podcastId}/progress/{episodeId}';

const getPodcastProfile = async podcastId => {
  const podcastProfileRoute = PODCAST_PROFILE.replace('{podcastId}', podcastId);
  try {
    return getV3APIRoute(podcastProfileRoute);
  } catch (err) {
    console.log('error calling getPodcastProfile', err);
    return false;
  }
};

const getPodcastCategories = async (params = {}) => {
  return getV3APIRoute(PODCAST_CATEGORIES, params);
};

const getPodcastsByCategoryId = async categoryId => {
  const podcastsByCategoryIdRoute = PODCASTS_BY_CATEGORY_ID.replace(
    '{categoryId}',
    categoryId
  );
  return getV3APIRoute(podcastsByCategoryIdRoute);
};

const getPodcastEpisode = async episodeId => {
  const podcastEpisodeRoute = PODCAST_EPISODE_BY_EPISODE_ID.replace(
    '{episodeId}',
    episodeId
  );
  return getV3APIRoute(podcastEpisodeRoute);
};

// Pass pageKey in params if response has a next value
const getPodcastEpisodes = async (podcastId, params = {}) => {
  const podcastEpisodesRoute = PODCAST_EPISODES_BY_PODCAST_ID.replace(
    '{podcastId}',
    podcastId
  );
  try {
    const { data: episodes, links = {} } = await getV3APIRoute(
      podcastEpisodesRoute,
      params
    );
    const { next } = links;

    const episodesToSet = episodes.map(episode =>
      Object.assign(episode, { playlist: episodes })
    );
    return next
      ? { episodes: episodesToSet, next }
      : { episodes: episodesToSet };
  } catch (err) {
    console.log('error calling podcastEpisodes', err);
    return false;
  }
};

const getPodcastDirectory = async () => {
  return getV3APIRoute(PODCAST_DIRECTORY);
};

// Pass pageKey in params if response has a next value
export const getFollowingPodcasts = async (
  limit = PAGINATION_PAGE_SIZE,
  pageNumber,
  pageKey
) => {
  const params = { limit, pageKey };
  const podcasts = await getV3APIRoute(PODCAST_FOLLOWS, params);
  const data = (podcasts && podcasts.data) || [];
  store.dispatch(setLibraryPodcasts(data));
  store.dispatch(
    setIsPageLoaded(
      'library-podcasts',
      pageKey ? pageNumber : 1,
      true,
      podcasts.links && podcasts.links.next ? podcasts.links.next : false
    )
  );
  return data;
};

const followPodcast = async podcastId => {
  const podcastFollowRoute = PODCAST_FOLLOW.replace('{podcastId}', podcastId);
  const res = await putV3APIRoute(podcastFollowRoute);
  if (res.status === 204) {
    getFollowingPodcasts();
  }
};

const removePodcast = async podcastId => {
  const podcastRemoveRoute = PODCAST_FOLLOW.replace('{podcastId}', podcastId);
  const res = await deleteV3APIRoute(podcastRemoveRoute);
  if (res.status === 204) {
    getFollowingPodcasts();
  }
};

const updatePodcastProgress = async (podcastId, episodeId, secondsPlayed) => {
  const podcastRemoveRoute = PODCAST_EPISODE_PROGRESS_UPDATE.replace(
    '{podcastId}',
    podcastId
  ).replace('{episodeId}', episodeId);
  const res = await putV3APIRoute(podcastRemoveRoute, { secondsPlayed });
  return res;
};

export {
  getPodcastProfile,
  getPodcastCategories,
  getPodcastsByCategoryId,
  updatePodcastProgress,
  getPodcastEpisodes,
  getPodcastEpisode,
  getPodcastDirectory,
  followPodcast,
  removePodcast
};
