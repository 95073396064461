import React from 'react';
import { s } from '../../lib/screen-size';

const Icon = ({ fill }) => (
  <svg
    width={s(21)}
    height={s(21)}
    viewBox="0 0 21 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Button/Player/Icon/Stop/Focus"
        transform="translate(-26.000000, -26.000000)"
        fill={fill ? fill : '#FFFFFF'}
      >
        <g id="square" transform="translate(26.000000, 26.000000)">
          <path
            d="M3.5,0 L17.5,0 C19.425,0 21,1.575 21,3.5 L21,17.5 C21,19.425 19.425,21 17.5,21 L3.5,21 C1.575,21 0,19.425 0,17.5 L0,3.5 C0,1.575 1.575,0 3.5,0 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
