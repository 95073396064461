import { getV3APIRoute } from './utils';

const LIST_GENRES = '/api/v3/catalog/genres';
const ALBUM_INFO = '/api/v3/catalog/album/{albumId}';

const listGenres = async (params = {}) => {
  const { genreType = 'custom', includeEmptyGenre = false } = false;
  params = { ...params, genreType, includeEmptyGenre };
  return getV3APIRoute(LIST_GENRES, params);
};

const getAlbumInfo = async albumId => {
  const url = ALBUM_INFO.replace('{albumId}', albumId);
  const data = await getV3APIRoute(url);
  // Give all the track cards the album art
  if (data && data.tracks)
    data.tracks.forEach(track => {
      track.image = data.image;
    });
  return data;
};

const getTracksInfo = async trackIds => {
  if (!trackIds) {
    return [];
  }
  const url = '/api/v3/catalog/tracks/{trackId}'.replace('{trackId}', trackIds);
  const data = await getV3APIRoute(url);
  return data.tracks;
};

export { listGenres, getAlbumInfo, getTracksInfo };
