import ReactvPlatforms from '../reactv-platforms/index';

export function getKeyMap() {
  const defaultMapping = {
    36: 'Return',
    38: 'Up',
    40: 'Down',
    37: 'Left',
    39: 'Right',
    65: 'A',
    66: 'B',
    67: 'C',
    68: 'D'
    // 179: 'Play',
    // 227: 'FastForward',
    // 228: 'Rewind',
    // 219: 'PlayPause',
    // 221: 'FastForwardSkipForward',
    // 80: 'RewindSkipBack'
  };

  const enseoMapping = {
    36: 'Return',
    38: 'Up',
    40: 'Down',
    37: 'Left',
    39: 'Right',
    65: 'A',
    66: 'B',
    67: 'C',
    68: 'D',
    179: 'Play',
    227: 'FastForward',
    228: 'Rewind',
    1000: 'PlayPause',
    1001: 'FastForwardSkipForward',
    1002: 'RewindSkipBack'
  };

  const vizioMapping = {
    27: 'Exit',
    37: 'Left',
    38: 'Up',
    39: 'Right',
    40: 'Down',
    19: 'Pause',
    412: 'Rewind',
    413: 'Stop',
    415: 'Play',
    417: 'FastForward',
    418: 'MediaTrackNext',
    419: 'MediaTrackPrevious'
  };

  var mapping = ReactvPlatforms.isVizio
    ? vizioMapping
    : ReactvPlatforms.isEnseo
      ? enseoMapping
      : defaultMapping;

  if (process.env.REACT_APP_TOUCH !== 'true') {
    mapping['8'] = 'Back';
    mapping['13'] = 'Enter';
  }
  return mapping;
}

const keymap = getKeyMap();

const mapFunction = code => keymap[code];
export default mapFunction;
