import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ListMenu from '../../lib/reactv-navigation/components/ListMenu';
import GridMenusWrapper from '../../components/GridMenusWrapper';
import {
  getLibraryCollectionTracks,
  getLibraryCollectionAlbums,
  getLibraryCollectionPlaylists
} from '../../api/collection.js';
import { getFollowingPodcasts } from '../../api/podcast.js';
import {
  getLibraryLiveStations,
  getLibraryArtistStations
} from '../../api/profile.js';
import { ButtonWithoutComposer } from '../../components/Button/Button';
import { updateFocus } from '../../reducers/appNavigation';
import { addToast } from '../../reducers/toasts';
import {
  setPageTitle,
  setPageData,
  storeViewAll,
  showLoadingSpinner,
  hideLoadingSpinner,
  setViewAllStatus
} from '../../reducers/view';
import AddIcon from '../../img/player/add.js';
import { handleCardSelection } from '../../lib/utils';
import {
  setLibraryStatus,
  setLibraryLiveStations,
  setLibraryArtistStations,
  setLibraryPlaylists,
  setLibraryPodcasts,
  setLibraryAlbums,
  setLibrarySongs
} from '../../reducers/library';
import { s } from '../../lib/screen-size.js';
import { playRadio, playSong, playTracks } from '../../reducers/station';
import { updateLocationState } from '../../lib/reactv-redux/ReacTVReduxReducer';

import './YourLibrary.scss';

class YourLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.idForGrid = 'library'; // should match with path page

    this.AMOUNT_TO_MOVE_SCROLL_BY = 250;

    this.fetchAndSetData = this.fetchAndSetData.bind(this);
    this.handleMenuEnter = this.handleMenuEnter.bind(this);
    this.focusOnGridMenus = this.focusOnGridMenus.bind(this);
    this.focusOnYourLibraryMenu = this.focusOnYourLibraryMenu.bind(this);
  }

  componentDidMount() {
    this.fetchAndSetData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      ((prevProps.session.userProfile &&
        prevProps.session.userProfile.accountType === 'ANONYMOUS') ||
        !prevProps.session.userProfile) &&
      this.props.session.userProfile &&
      this.props.session.userProfile.accountType !== 'ANONYMOUS'
    ) {
      this.fetchAndSetData();
    }
  }

  async fetchAndSetData() {
    const { onUp, onDown, session } = this.props;
    this.props.showLoadingSpinner();

    const itemsWhichMayBeRestricred = [
      {
        id: 'library-albums',
        title: 'All Albums',
        className: 'yourLibrary__button',
        isEmpty: true
      },
      {
        id: 'library-tracks',
        title: 'All Songs',
        className: 'yourLibrary__button',
        isEmpty: true
      }
    ];

    this.menuItems = [
      {
        id: 'library-live-radio',
        title: 'All Live Radio',
        className: 'yourLibrary__button',
        isEmpty: true
      },
      {
        id: 'library-artists',
        title: 'All Artist Radio',
        className: 'yourLibrary__button',
        isEmpty: true
      },
      {
        id: 'library-playlists',
        title: 'All Playlists',
        className: 'yourLibrary__button',
        isEmpty: true
      },
      {
        id: 'library-podcasts',
        title: 'All Podcasts',
        className: 'yourLibrary__button',
        isEmpty: true
      }
    ];

    if (session.isPremiumUser) {
      this.menuItems.push(...itemsWhichMayBeRestricred);
    } else {
      let items = itemsWhichMayBeRestricred.map(item => {
        item.disabled = true;
        return item;
      });
      this.menuItems.push(...items);
    }

    this.props.setPageTitle();
    const isPremiumUser =
      session.userConfiguration.subscription.subscriptionType === 'PREMIUM';

    let yourLibraryLiveStations,
      yourLibraryArtistStations,
      yourLibraryPlaylists,
      yourLibraryPodcasts,
      yourLibraryAlbums,
      yourLibrarySongs;
    if (isPremiumUser) {
      [
        yourLibraryLiveStations,
        yourLibraryArtistStations,
        yourLibraryPlaylists,
        yourLibraryPodcasts,
        yourLibraryAlbums,
        yourLibrarySongs
      ] = await Promise.all([
        getLibraryLiveStations(),
        getLibraryArtistStations(),
        getLibraryCollectionPlaylists({ limit: 999999 }),
        getFollowingPodcasts(),
        getLibraryCollectionAlbums(),
        getLibraryCollectionTracks(null, null, null, true)
      ]);
    } else {
      [
        yourLibraryLiveStations,
        yourLibraryArtistStations,
        yourLibraryPlaylists,
        yourLibraryPodcasts
      ] = await Promise.all([
        getLibraryLiveStations(),
        getLibraryArtistStations(),
        getLibraryCollectionPlaylists({ limit: 999999 }),
        getFollowingPodcasts()
      ]);
    }

    this.props.setLibraryLiveStations(yourLibraryLiveStations);
    this.props.setLibraryArtistStations(yourLibraryArtistStations);
    this.props.setLibraryPlaylists(yourLibraryPlaylists);
    this.props.setLibraryPodcasts(yourLibraryPodcasts);
    this.props.setLibraryAlbums(yourLibraryAlbums);
    this.props.setLibrarySongs(yourLibrarySongs);

    let menusData = [];
    if (yourLibraryLiveStations && yourLibraryLiveStations.length) {
      this.menuItems[0].isEmpty = false;
      menusData.push({
        label: 'Live Radio',
        menuid: 'library-live-radio',
        data: yourLibraryLiveStations,
        dataContentType: 'LIVE',
        xCards: 5
      });
    }
    if (yourLibraryArtistStations && yourLibraryArtistStations.length) {
      this.menuItems[1].isEmpty = false;
      menusData.push({
        label: 'Artist Radio',
        menuid: 'library-artists',
        data: yourLibraryArtistStations,
        dataContentType: 'ARTIST',
        xCards: 5
      });
    }
    if (yourLibraryPlaylists && yourLibraryPlaylists.length) {
      this.menuItems[2].isEmpty = false;
      menusData.push({
        label: 'Playlists',
        menuid: 'library-playlists',
        data: yourLibraryPlaylists,
        dataContentType: 'PLAYLIST',
        xCards: 5
      });
    }
    if (yourLibraryPodcasts && yourLibraryPodcasts.length) {
      this.menuItems[3].isEmpty = false;
      menusData.push({
        label: 'Podcasts',
        menuid: 'library-podcasts',
        data: yourLibraryPodcasts,
        dataContentType: 'PODCAST',
        xCards: 5
      });
    }
    if (yourLibraryAlbums && yourLibraryAlbums.length) {
      this.menuItems[4].isEmpty = false;
      menusData.push({
        constantWidth: true,
        isSquare: true,
        label: 'Albums',
        menuid: 'library-albums',
        data: yourLibraryAlbums,
        dataContentType: 'ALBUM',
        xCards: 6
      });
    }
    if (yourLibrarySongs && yourLibrarySongs.length) {
      this.menuItems[5].isEmpty = false;
      menusData.push({
        constantWidth: true,
        isSquare: true,
        label: 'Songs',
        menuid: 'library-tracks',
        data: yourLibrarySongs,
        dataContentType: 'SONG',
        xCards: 6
      });
    }

    let libraryIsEmpty = 'favorites';
    let isMenuVisible = false;

    menusData.forEach(menu => {
      menu.onUp = onUp;
      menu.onDown = onDown;

      if (menu && menu.data && menu.data.length > 0) {
        libraryIsEmpty = false;
        if (menu.data.length >= 5) {
          // when there are at least 5 cards of one type, display the menu
          isMenuVisible = true;
        }
      }
    });

    const firstMenu = menusData[0];
    this.props.setLibraryStatus({
      libraryIsEmpty,
      isMenuVisible,
      firstMenu: firstMenu ? `library/gridMenus/${firstMenu.menuid}` : null
    });

    this.props.hideLoadingSpinner();
    const {
      appNavigation: { currentFocus }
    } = this.props;
    if (!currentFocus || currentFocus === 'loadingSpinner')
      this.props.updateFocus('container/mainMenu');
    this.setState({ menusData });
    if (this.props.menu && this.props.menu.appNavigation) {
      document
        .querySelector('.mainContainer')
        .scrollTo(this.props.menu.appNavigation.lastScrollValue || 0, 0);
    }
  }

  handleMenuEnter(item) {
    const {
      addToast,
      setPageData,
      storeViewAll,
      setPageTitle,
      library,
      history,
      setViewAllStatus
    } = this.props;
    let title;
    if (item.id === 'library-live-radio') {
      title = 'Live Radio';
      if (item.isEmpty) {
        setViewAllStatus({ isEmpty: 'radio stations' });
      } else {
        setPageData(
          library.liveStations,
          'LIVE',
          '/viewAllPage/Live%20Radio/library?menuid=library-live-radio',
          'library',
          true
        );
      }
    } else if (item.id === 'library-artists') {
      title = 'Artist Radio';
      if (item.isEmpty) {
        setViewAllStatus({ isEmpty: 'artists' });
      } else {
        setPageData(
          library.artistStations,
          'ARTIST',
          '/viewAllPage/Artist%20Radio/library?menuid=library-artists',
          'library',
          true
        );
      }
    } else if (item.id === 'library-playlists') {
      title = 'Playlists';
      if (item.isEmpty) {
        setViewAllStatus({ isEmpty: 'playlists' });
      } else {
        storeViewAll('library-playlists', library.playlists);
        setPageData(
          library.playlists,
          'PLAYLIST',
          '/viewAllPage/Playlists/library?menuid=library-playlists',
          'library',
          true
        );
      }
    } else if (item.id === 'library-podcasts') {
      title = 'Podcasts';
      if (item.isEmpty) {
        setViewAllStatus({ isEmpty: 'podcasts' });
      } else {
        setPageData(
          library.podcasts,
          'PODCAST',
          '/viewAllPage/Podcasts/library?menuid=library-podcasts',
          'library',
          true
        );
      }
    } else if (item.id === 'library-albums') {
      title = 'Albums';
      if (item.disabled) {
        addToast({ type: 'library-album-restricted' });
        return;
      } else if (item.isEmpty) {
        setViewAllStatus({ isEmpty: 'albums' });
      } else {
        storeViewAll('library-albums', library.albums);
        setPageData(
          library.albums,
          'ALBUM',
          '/viewAllPage/Albums/library?menuid=library-albums',
          'library',
          true
        );
      }
    } else if (item.id === 'library-tracks') {
      title = 'Songs';
      if (item.disabled) {
        addToast({ type: 'library-songs-restricted' });
        return;
      } else if (item.isEmpty) {
        setViewAllStatus({ isEmpty: 'songs' });
      } else {
        const loaded = this.props['library-tracks']['1'].loaded;
        const data = library.songs.slice(0, loaded);
        setPageData(
          data,
          'SONG',
          '/viewAllPage/Songs/library?menuid=library-tracks',
          'library',
          true
        );
      }
    }
    setPageTitle(item.title);
    const page =
      this.props.location.pathname &&
      this.props.location.pathname.split('/')[1];
    history.push(
      `/viewAllPage/${title}${page ? '/' + page : ''}?menuid=${item.id}`
    );
  }

  focusOnGridMenus() {
    const firstMenu = this.state.menusData && this.state.menusData[0];
    if (!firstMenu) {
      console.warn('No menu found to focus');
      return;
    }
    this.props.updateFocus('library/gridMenus/' + firstMenu.menuid);
  }

  focusOnYourLibraryMenu() {
    this.props.updateFocus('library/content');
  }

  isGridMenuAdditionalFocused() {
    return (
      !this.props.library.isMenuVisible &&
      this.props.appNavigation.currentFocus === 'library/content'
    );
  }

  isYourLibraryMenuFocused() {
    return (
      this.props.library.isMenuVisible &&
      this.props.appNavigation.currentFocus === 'library/content'
    );
  }

  showEmptyLibraryScreen(string) {
    return (
      <div className="yourLibrary yourLibrary--noData">
        You have no saved {string}. In the player, press the{' '}
        <ButtonWithoutComposer className="yourLibrary__addButton">
          <AddIcon />
        </ButtonWithoutComposer>{' '}
        icon to add a station to your library
      </div>
    );
  }

  render() {
    const {
      onUp,
      onDown,
      appNavigation: { lastFocus, currentFocus },
      library
    } = this.props;
    const { menusData } = this.state;
    const { libraryIsEmpty, isMenuVisible } = library;

    if (libraryIsEmpty) {
      // TODO: move these state updates out of render()
      if (
        currentFocus === 'library/content' &&
        lastFocus === 'container/mainMenu'
      ) {
        // library is empty, and need to navigate down from Main Menu:
        // focus on miniPlayer
        this.props.updateFocus('container/miniPlayer');
      } else if (
        currentFocus === 'library/content' &&
        lastFocus === 'container/miniPlayer'
      ) {
        // library is empty, and need to navigate up from miniPlayer:
        // focus on Main Menu
        this.props.updateFocus('container/mainMenu');
      }
      return this.showEmptyLibraryScreen(libraryIsEmpty);
    }

    const OFFSET_SCROLL = isMenuVisible ? s(600) : 0;

    return (
      <div className="yourLibrary">
        {isMenuVisible ? (
          <React.Fragment>
            <ListMenu
              data={this.menuItems}
              renderItem={ButtonWithoutComposer}
              className="yourLibrary__menu"
              vertical
              focused={this.isYourLibraryMenuFocused()}
              onUp={onUp}
              onRight={this.focusOnGridMenus}
              onEnter={this.handleMenuEnter}
              onDown={
                this.isAllAccess
                  ? onDown
                  : () => {
                      console.log('you dont have access');
                    }
              }
            />
          </React.Fragment>
        ) : null}
        <div
          className={cx('yourLibrary__gridMenusWrapper', {
            'yourLibrary__gridMenusWrapper--showMenu': isMenuVisible
          })}
        >
          <GridMenusWrapper
            idForGrid={this.idForGrid}
            onLeft={isMenuVisible ? this.focusOnYourLibraryMenu : null}
            onUp={onUp}
            menusData={menusData}
            onEnter={card => {
              handleCardSelection.call(this, card);
            }}
            OFFSET_SCROLL={OFFSET_SCROLL}
            AMOUNT_TO_MOVE_SCROLL_BY={this.AMOUNT_TO_MOVE_SCROLL_BY}
          />
        </div>
      </div>
    );
  }
}

YourLibrary.propTypes = {
  onUp: PropTypes.func
};

const mapStateToProps = state => {
  const { appNavigation, session, library, view, locationStateHistory } = state;

  return {
    menu: locationStateHistory['/library'],
    session,
    appNavigation,
    library,
    'library-tracks': view['library-tracks']
  };
};

export default connect(
  mapStateToProps,
  {
    addToast,
    setPageTitle,
    setPageData,
    storeViewAll,
    updateFocus,
    updateLocationState,
    showLoadingSpinner,
    hideLoadingSpinner,
    setLibraryStatus,
    setLibraryLiveStations,
    setLibraryArtistStations,
    setLibraryPlaylists,
    setLibraryPodcasts,
    setLibraryAlbums,
    setLibrarySongs,
    setViewAllStatus,
    playRadio,
    playSong,
    playTracks
  }
)(withRouter(YourLibrary));
