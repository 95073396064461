import axios from 'axios';
import ReactvPlatforms from '../lib/reactv-platforms/index';
import store from '../store';

export function trackPageView(pageName) {
  const deviceId = ReactvPlatforms.platform.getDeviceId();
  const { profileId } = store.getState().session;

  const adobeVisitorId = deviceId + profileId;
  const appName = ReactvPlatforms.platform.getAdobeAnaliticsName();
  const ihrAccountName = 'cccorporate55dev';

  const url = `https://smy.iheart.com/b/ss/${ihrAccountName}/10/JS-2.3.0-D7QN/${adobeVisitorId}`;

  const params = {};
  params['s.account'] = ihrAccountName;
  params['s.visitorNamespace'] = appName;
  params['s.visitor'] = adobeVisitorId;
  params['pageName'] = pageName;
  params['Device.host'] = appName;
  params['user.profileId'] = profileId;

  params['v1'] = profileId;
  params['v6'] = appName;
  params['v88'] = 'page-view';

  console.info('Sending Adobe event', params, url);

  axios.get(url, { params });
}
