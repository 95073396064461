import React from 'react';

const Icon = ({ fill }) => (
  <svg
    width="18px"
    height="24px"
    viewBox="0 0 18 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Keyboard" transform="translate(-123.000000, -592.000000)">
        <g id="Buttons" transform="translate(108.000000, 580.000000)">
          <g id="Button/Clear">
            <rect id="Rectangle-3" x="0" y="0" width="48" height="48" />
            <path
              d="M16.2857143,33.3333333 C16.2857143,34.8 17.4428571,36 18.8571429,36 L29.1428571,36 C30.5571429,36 31.7142857,34.8 31.7142857,33.3333333 L31.7142857,20 C31.7142857,18.5333333 30.5571429,17.3333333 29.1428571,17.3333333 L18.8571429,17.3333333 C17.4428571,17.3333333 16.2857143,18.5333333 16.2857143,20 L16.2857143,33.3333333 Z M31.7142857,13.3333333 L28.5,13.3333333 L27.5871429,12.3866667 C27.3557143,12.1466667 27.0214286,12 26.6871429,12 L21.3128571,12 C20.9785714,12 20.6442857,12.1466667 20.4128571,12.3866667 L19.5,13.3333333 L16.2857143,13.3333333 C15.5785714,13.3333333 15,13.9333333 15,14.6666667 C15,15.4 15.5785714,16 16.2857143,16 L31.7142857,16 C32.4214286,16 33,15.4 33,14.6666667 C33,13.9333333 32.4214286,13.3333333 31.7142857,13.3333333 Z"
              id="Shape"
              fill={fill ? fill : '#FFFFFF'}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
