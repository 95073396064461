import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from '../../utils';
import { updateMenu } from '../../../reactv-redux/MenusReducer';
import MenuComposer from '../Menu/MenuComposer';
import { connect } from 'react-redux';
import KeyEvent from '../../KeyEvents';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { s } from '../../../screen-size.js';
import { calculatePaginateProps } from '../../../utils';
import {
  loadCollectionPage,
  PAGINATION_PAGE_SIZE
} from '../../../../reducers/view';

const KeyEvents = new KeyEvent();

const GridComposer = InnerComponent => {
  class ComposedGrid extends React.Component {
    constructor(p) {
      super(p);
      this._bound = false;
    }

    static contextTypes = {
      mid: PropTypes.string,
      getMenuId: PropTypes.func
    };

    static propTypes = {
      focused: PropTypes.bool,
      mid: PropTypes.string,
      menus: PropTypes.object,
      updateMenu: PropTypes.func,
      onUpdate: PropTypes.func,
      menuItems: PropTypes.array
    };

    componentDidUpdate(prevProps) {
      if (!prevProps.focused && this.props.focused) this.bind();
      else if (prevProps.focused && !this.props.focused) this.unbind();
      const menu = this.props.menus[this.props.mid];
      const oldMenu = prevProps.menus[this.props.mid];
      if (
        menu &&
        oldMenu &&
        menu.index !== oldMenu.index &&
        isFunction(this.props.onUpdate)
      ) {
        this.props.onUpdate(this.props.mid, menu);
      }
      if (
        prevProps.contentKey &&
        this.props.contentKey &&
        prevProps.contentKey !== this.props.contentKey
      ) {
        console.info(
          'new menu items based on contentKey, resetting index for',
          this.props.mid
        );
        this.props.updateMenu(this.props.mid, { index: 0 });
      }
    }

    componentDidMount() {
      setTimeout(() => {
        if (this.props.focused && !this._bound && !this._unmounted) {
          this.bind();
        }
      }, 100);
    }

    componentWillMount() {
      this.checkMenuAndMax();
    }

    componentWillUpdate(nextProps) {
      this.checkMenuAndMax(nextProps);
    }

    componentWillUnmount() {
      this._unmounted = true;
      this.unbind();
    }

    checkMenuAndMax(props) {
      props = props || this.props;
      const { mid, updateMenu } = props;
      const menu = props.menus[mid];
      if (!menu) {
        updateMenu(mid, { index: 0, max: this.getMax() });
      } else {
        const max = this.getMax(props);
        if (menu.max !== max) {
          if (menu.index > max) menu.index = max;
          menu.max = max;
          updateMenu(mid, menu);
        }
      }
    }

    getMax(props) {
      props = props || this.props;
      let max = 0;
      let childCount = props.children
        ? React.Children.count(props.children)
        : null;
      if (this.props.menuItems && Array.isArray(props.menuItems)) {
        max = props.menuItems.length - 1;
      } else if (Number.isInteger(this.props.menuItems)) {
        max = props.menuItems - 1;
      } else if (childCount && childCount > 0) {
        max = childCount - 1;
      }
      return max;
    }

    bind() {
      const handlers = {
        Enter: () => {
          this.handleOnClick();
        }
      };

      const incrementHandler = 'Right';
      const decrementHandler = 'Left';
      const upHandler = 'Up';
      const downHandler = 'Down';
      const topRowIdxs = [0, 3, 5, 6, 8]; // 6 may be bottom or top
      const botRowIdxs = [1, 2, 4, 7, 9];
      let maxIndex = this.getMax();
      const fullDeck = maxIndex >= 7; // 6 is to the right of 5, instead of underneath

      handlers[decrementHandler] = () => {
        const { menus, mid, constantWidth, threeRows } = this.props;
        const index = menus[mid].index;
        maxIndex = this.getMax();
        const handler = `on${decrementHandler}`;
        const moveScroll = () => {
          // moving leftward to a new card within same gridMenu
          let mainContainer = document.querySelector('.mainContainer');
          let moveFor = s(this.props.AMOUNT_TO_MOVE_SCROLL_BY);
          if (mainContainer.scrollWidth - mainContainer.scrollLeft < 1350) {
            moveFor = Math.floor(moveFor / 10);
          }

          mainContainer.scrollTo(mainContainer.scrollLeft - moveFor, 0);
        };
        const runHandle = () => {
          if (isFunction(this.props[handler])) this.props[handler]();
        };

        if (this.props.focused && index > (threeRows ? 2 : 1)) {
          if (constantWidth) {
            if (threeRows) {
              this.props.updateMenu(mid, { index: index - 3 });
              moveScroll();
            } else {
              this.props.updateMenu(mid, { index: index - 2 });
              moveScroll();
            }
            return;
          }

          // when in ViewAllPage
          if (this.props.first4Wide) {
            if (index % 10 === 7 || (index > 10 && index % 10 === 1)) {
              // cards that naturally skip left by three
              this.props.updateMenu(mid, { index: index - 3 });
              moveScroll();
              return;
            }
            if (
              index % 10 === 4 ||
              index % 10 === 5 ||
              index % 10 === 8 ||
              index % 10 === 9 ||
              (index % 10 === 0 && !fullDeck) ||
              index % 10 === 2 ||
              index % 10 === 3
            ) {
              // cards that naturally skip left by two
              this.props.updateMenu(mid, { index: index - 2 });
              moveScroll();
              return;
            }
          } else {
            // when not in ViewAllPage
            if (index % 10 === 3 || index % 10 === 7) {
              // cards that naturally skip left by three
              this.props.updateMenu(mid, { index: index - 3 });
              moveScroll();
              return;
            }
            if (
              index % 10 === 0 ||
              index % 10 === 1 ||
              index % 10 === 4 ||
              index % 10 === 5 ||
              (index % 10 === 6 && !fullDeck) ||
              index % 10 === 8 ||
              index % 10 === 9
            ) {
              // cards that naturally skip left by two
              this.props.updateMenu(mid, { index: index - 2 });
              moveScroll();
              return;
            }
          }
          // cards that naturally skip left by one
          this.props.updateMenu(mid, { index: index - 1 });
          moveScroll();
          return;
        }
        runHandle();
      };

      handlers[incrementHandler] = () => {
        maxIndex = this.getMax();
        const { menus, mid, constantWidth, threeRows } = this.props;
        const menu = menus[mid];
        const index = menu.index;
        const handler = `on${incrementHandler}`;
        this.paginationCheck(menu, this.props.menuItems);
        const moveScroll = () => {
          // moving rightward to a new card within same gridMenu
          let mainContainer = document.querySelector('.mainContainer');
          mainContainer.scrollTo(
            mainContainer.scrollLeft + s(this.props.AMOUNT_TO_MOVE_SCROLL_BY),
            0
          );
        };

        const runHandle = () => {
          if (isFunction(this.props[handler])) this.props[handler]();
        };

        if (this.props.focused) {
          if (threeRows) {
            if (constantWidth && maxIndex >= index + 3) {
              this.props.updateMenu(mid, { index: index + 3 });
              moveScroll();
            }
            return;
          }

          if (!threeRows && constantWidth && maxIndex >= index + 2) {
            this.props.updateMenu(mid, { index: index + 2 });
            moveScroll();
            return;
          } else if (!threeRows && index % 10 <= 9) {
            if (this.props.first4Wide) {
              // when in ViewAllPage
              if (index % 10 === 4 || index % 10 === 8) {
                // cards that naturally skip right by three
                if (maxIndex >= index + 3) {
                  this.props.updateMenu(mid, { index: index + 3 });
                  moveScroll();
                  return;
                } else if (index % 10 === 8 && maxIndex === index + 2) {
                  // special case when 10 is under 9
                  this.props.updateMenu(mid, { index: index + 2 });
                  moveScroll();
                  return;
                }
              }
              if (
                index % 10 === 0 ||
                index % 10 === 1 ||
                index % 10 === 2 ||
                index % 10 === 3 ||
                index % 10 === 6 ||
                index % 10 === 7
              ) {
                // cards that naturally skip right by two
                if (maxIndex >= index + 2) {
                  this.props.updateMenu(mid, { index: index + 2 });
                  moveScroll();
                  return;
                }
              }
              if (
                (index % 10 === 5 && maxIndex >= index + 1) ||
                (index % 10 === 9 && fullDeck && maxIndex >= index + 1)
              ) {
                // cards that naturally skip right by one
                this.props.updateMenu(mid, { index: index + 1 });
                moveScroll();
                return;
              }
            } else {
              // when not in ViewAllPage
              if (index % 10 === 0 || index % 10 === 4) {
                // cards that naturally skip right by three
                if (maxIndex >= index + 3) {
                  this.props.updateMenu(mid, { index: index + 3 });
                  moveScroll();
                  return;
                } else if (index % 10 === 4 && maxIndex === index + 2) {
                  // special case when 6 is under 5
                  this.props.updateMenu(mid, { index: index + 2 });
                  moveScroll();
                  return;
                }
              }
              if (
                index % 10 === 2 ||
                index % 10 === 3 ||
                index % 10 === 6 ||
                index % 10 === 7 ||
                index % 10 === 8 ||
                index % 10 === 9
              ) {
                // cards that naturally skip right by two
                if (maxIndex >= index + 2) {
                  this.props.updateMenu(mid, { index: index + 2 });
                  moveScroll();
                  return;
                }
              }
              if (
                (index % 10 === 1 && maxIndex >= index + 1) ||
                (index % 10 === 5 && fullDeck)
              ) {
                // cards that naturally skip right by one
                this.props.updateMenu(mid, { index: index + 1 });
                moveScroll();
                return;
              }
            }
          }
        }
        runHandle();
      };

      handlers[upHandler] = () => {
        const { menus, mid, constantWidth, threeRows } = this.props;
        const index = menus[mid].index;
        if (constantWidth) {
          if (threeRows) {
            if (index % 3 === 1 || index % 3 === 2) {
              this.props.updateMenu(mid, { index: index - 1 });
              return;
            }
          } else {
            if (index % 2 === 1) {
              this.props.updateMenu(mid, { index: index - 1 });
              return;
            }
          }
        } else {
          if (this.props.first4Wide) {
            // when in ViewAllPage
            if (index >= 10 && index % 10 === 0 && !fullDeck) {
              // special case when 6 is under 5
              this.props.updateMenu(mid, { index: index - 1 });
              return;
            }

            if (index % 10 === 6 || (index >= 10 && index % 10 === 1)) {
              // special cases
              this.props.updateMenu(mid, { index: index - 2 });
              return;
            }

            if (
              index === 1 ||
              index === 3 ||
              botRowIdxs.indexOf((index - 4) % 10) !== -1
            ) {
              // bottom rows
              this.props.updateMenu(mid, { index: index - 1 });
              return;
            }
          } else {
            // when not in ViewAllPage
            if (index % 10 === 6 && !fullDeck) {
              // special case when 6 is under 5
              this.props.updateMenu(mid, { index: index - 1 });
              return;
            }

            if (index % 10 === 2 || index % 10 === 7) {
              // special cases
              this.props.updateMenu(mid, { index: index - 2 });
              return;
            }

            if (botRowIdxs.indexOf(index % 10) !== -1) {
              // bottom rows
              this.props.updateMenu(mid, { index: index - 1 });
              return;
            }
          }
        }
        if (typeof this.props.onUp === 'function') this.props.onUp();
      };

      handlers[downHandler] = () => {
        const { menus, mid, constantWidth, threeRows } = this.props;
        const index = menus[mid].index;
        if (constantWidth) {
          if (threeRows) {
            if (index + 1 <= maxIndex && (index % 3 === 0 || index % 3 === 1)) {
              this.props.updateMenu(mid, { index: index + 1 });
              return;
            }
          } else {
            if (index + 1 <= maxIndex && index % 2 === 0) {
              this.props.updateMenu(mid, { index: index + 1 });
              return;
            }
          }
        } else {
          if (this.props.first4Wide) {
            // when in ViewAllPage
            if (index % 10 === 9 && fullDeck && maxIndex >= index + 1) {
              // special case when 7 is under 5, and 6 is to the right of 5
              this.props.updateMenu(mid, { index: index + 2 });
              return;
            }

            if (
              index === 0 ||
              index === 2 ||
              (topRowIdxs.indexOf((index - 4) % 10) !== -1 && maxIndex > index)
            ) {
              // top row
              this.props.updateMenu(mid, { index: index + 1 });
              return;
            }
          } else {
            // when not in ViewAllPage
            if (index % 10 === 5 && fullDeck) {
              // special case when 7 is under 5, and 6 is to the right of 5
              this.props.updateMenu(mid, { index: index + 2 });
              return;
            }

            if (topRowIdxs.indexOf(index % 10) !== -1 && maxIndex > index) {
              // top row
              this.props.updateMenu(mid, { index: index + 1 });
              return;
            }
          }
        }

        // all other
        if (typeof this.props.onDown === 'function') this.props.onDown();
      };

      this.unbind();
      this.bindings = map(handlers, (binding, event) => {
        return KeyEvents.subscribeTo(event, evt => {
          binding(evt);
        });
      });
      this._bound = true;
    }

    paginationCheck(menu, menuItems) {
      const { CUSTOM_PAGINATION_PAGE_SIZE, view } = this.props;
      const { index } = menu;

      const id = view.viewAllMenuId;
      let indexOfId = id && id.includes('menuid=') && id.indexOf('=');
      let pagesId = indexOfId && indexOfId > 0 && id.slice(indexOfId + 1);

      const fetchedCollectionPages = pagesId && view[pagesId];
      if (!fetchedCollectionPages) return;

      const {
        overThreshold,
        isPageNotLoaded,
        currentPage
      } = calculatePaginateProps({
        fetchedCollectionPages,
        index,
        PAGINATION_PAGE_SIZE
      });

      if (overThreshold && isPageNotLoaded) {
        // Not all collections use the pageKey method for pagination
        const nextPageKey =
          fetchedCollectionPages &&
          fetchedCollectionPages[currentPage] &&
          fetchedCollectionPages[currentPage].nextPageKey;
        if (nextPageKey === false) {
          return;
        }
        this.props.loadCollectionPage({
          id,
          pagesId,
          pageSize: CUSTOM_PAGINATION_PAGE_SIZE || PAGINATION_PAGE_SIZE,
          pageNumber: currentPage + 1,
          menu,
          menuItems,
          nextPageKey:
            fetchedCollectionPages &&
            fetchedCollectionPages[currentPage] &&
            fetchedCollectionPages[currentPage].nextPageKey
        });
      }
    }

    handleOnClick() {
      const { menus, mid, onClick, menuItems, onEnter } = this.props;
      if (!isFunction(onClick) && !isFunction(onEnter)) return;
      const menu = menus[mid];
      let { index } = menu;
      let payload = [];
      if (Array.isArray(menuItems)) {
        payload = [menuItems[index], index];
      } else {
        payload.push(index);
      }
      if (isFunction(onEnter)) onEnter.apply(this, payload);
      if (isFunction(onClick)) onClick.apply(this, payload);
    }

    unbind() {
      const bindings = this.bindings || [];
      bindings.forEach(binding => binding.unsubscribe());
      this._bound = false;
    }

    isFocused(idx, rowInd) {
      const { mid, menus, focused } = this.props;
      const menu = menus[mid];
      return !!(menu && focused && menu.index === idx);
    }

    claimFocus(idx) {
      const { mid, updateMenu } = this.props;
      updateMenu(mid, { index: idx });
    }

    mergeProps(idx, props = {}) {
      const newProps = Object.assign({}, props);
      newProps.onMouseOver = () => {
        if (isFunction(props.onMouseOver)) props.onMouseOver();
        this.claimFocus(idx);
      };
      newProps.focused = this.isFocused(idx);
      return newProps;
    }

    getPassedProps() {
      let props = Object.assign(
        {},
        {
          menu: this.props.menus[this.props.mid],
          isFocused: this.isFocused.bind(this),
          mergeProps: this.mergeProps.bind(this)
        },
        this.props
      );

      props.children = React.Children.map(props.children, (child, idx) => {
        const props = this.mergeProps(idx, child.props);
        return React.cloneElement(child, props);
      });

      return omit(props, ['menus']);
    }

    render() {
      const props = this.getPassedProps();
      if (!props.menu) return null;
      return <InnerComponent {...props} {...this.state} />;
    }
  }

  return MenuComposer(
    connect(
      state => {
        return { menus: state.navigation.menus, view: state.view };
      },
      { updateMenu, loadCollectionPage }
    )(ComposedGrid)
  );
};
export default GridComposer;
