import React from 'react';
import Button from '../Button/Button';
import PropTypes from 'prop-types';
import PlayIcon from '../../img/button/playIcon';
import StopIcon from '../../img/button/stop';
import './Hero.scss';

const Hero = ({
  hero,
  focused,
  isFirstTimeVisiting,
  onRight,
  onUp,
  onDown,
  onEnter,
  isPlaying
}) => (
  <div className="hero">
    {hero.responseType !== 'ARTIST' &&
      hero.logo && <img className="hero__image" alt="hero" src={hero.logo} />}
    <div className="hero__infoWrapper">
      <div className="hero__title">
        {isFirstTimeVisiting ? 'Welcome' : 'Welcome Back!'}
      </div>
      <div className="hero__message">
        {hero.responseType === 'ARTIST'
          ? 'We have your favorites lined up or you can try a new artist:'
          : 'We have your favorites lined up or you can try a new station:'}
      </div>
      <Button
        className="hero__button"
        dataTest="heroButton"
        focused={focused}
        onRight={onRight}
        onUp={onUp}
        onDown={onDown}
        onEnter={onEnter}
        label={hero.name}
      >
        {hero.responseType === 'LIVE' ? (
          isPlaying ? (
            <StopIcon fill={focused ? '#000' : null} />
          ) : (
            <PlayIcon hidden fill={focused ? '#000' : null} />
          )
        ) : null}
      </Button>
    </div>
  </div>
);

Hero.propTypes = {
  hero: PropTypes.object.isRequired
};

export default Hero;
