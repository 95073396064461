import { Events } from '.';

/**
 * Detect touch events and trigger
 */
export default (node, options = {}) => {
  // -> not a touch build -> ignore
  if (process.env.REACT_APP_TOUCH !== 'true') {
    return {
      unsubscribe: () => {}
    };
  }

  let status = {
    // distance: (Integer) swipe length
    // endX: (Integer) touch end X axis
    // endY: (Integer) touch end Y axis
    // event: (String) enum['tab', 'longpress', 'swipeup', 'swiperight', 'swipebottom', 'swipeleft']
    // startX: (Integer) touch start X axis
    // startY: (Integer) touch start Y axis
    // target: (DOM) touch start target
    // velocity: (Integer) swipe speed
  };
  let touch = {
    // longPressEmit: (Integer) timeout ID
    // move: (Boolean) !!touchstart && !!touchmove
    // start: (Boolean) !!touchstart
    // timestamp: (Integer) touch started timestamp
  };
  // toggle swipe on touchstart and touchmove greater than (x) px
  const RADIUS = options.RADIUS || 30;
  // toggle long press in (x) millisecond
  const LONG_PRESS_TIMER = options.LONG_PRESS_TIMER || 1000;

  // dispatch toggle event callback
  const dispatch = () => {
    const cb = options[`on${status.event}`];
    if (typeof cb !== 'function') {
      return;
    }

    try {
      cb.call(null, status);
    } catch (err) {
      console.error(err);
    }
    console.log(
      `%cdispatch on${status.event}: `,
      'color:deeppink',
      status,
      node
    );
  };

  const resetTouchEvents = () => {
    // rest long press emitter
    clearTimeout(touch.longPressEmit);
    // reset status n touch
    status = {};
    touch = {};
  };

  const getCoordinatesLength = (x, y) => ({
    x: status.startX - x,
    y: status.startY - y
  });

  const now = () => Date.now();

  const onTouchEnd = ({ clientX, clientY }) => {
    // -> is longpress or miss touch start-> ignore
    if (status.event !== 'longpress' && status.target !== undefined) {
      // default touch event
      status.event = 'tap';

      // -> touchmove -> swipe
      if (touch.move === true) {
        // detect swipe up/right/bottom/left
        let event, distance;
        let diff = getCoordinatesLength(clientX, clientY);
        // most significant movment
        if (Math.abs(diff.x) > Math.abs(diff.y)) {
          event = diff.x > 0 ? 'swipeleft' : 'swiperight';
          distance = Math.abs(diff.x);
        } else {
          event = diff.y > 0 ? 'swipeup' : 'swipedown';
          distance = Math.abs(diff.y);
        }
        // swipe movement speed
        let velocity = distance / (now() - touch.timestamp);

        status = {
          ...status,
          distance,
          event,
          velocity
        };
      }

      status = {
        ...status,
        endX: clientX,
        endY: clientY
      };
      dispatch();
    }
    resetTouchEvents();
  };

  const onTouchStart = ({ clientX, clientY, target }) => {
    touch.timestamp = now();
    touch.start = true;
    status = {
      target,
      startX: clientX,
      startY: clientY,
      // will pass on `longpress` overwirte on rest of touch events
      endX: clientX,
      endY: clientY
    };
    touch.longPressEmit = setTimeout(() => {
      // -> not swipe -> long press
      if (touch.move === true) {
        return;
      }

      status.event = 'longpress';
      dispatch();
    }, LONG_PRESS_TIMER);
  };

  const onTouchMove = ({ clientX, clientY }) => {
    // -> no touchstart -> ignore
    if (touch.start !== true) {
      return;
    }

    let diff = getCoordinatesLength(clientX, clientY);
    // -> within the safe radius -> ignore
    if (Math.abs(diff.x) < RADIUS && Math.abs(diff.y) < RADIUS) {
      return;
    }

    touch.move = true;
  };

  // bind events
  node.addEventListener(Events.touchstart, onTouchStart);
  node.addEventListener(Events.touchmove, onTouchMove);
  node.addEventListener(Events.touchend, onTouchEnd);

  return {
    unsubscribe: () => {
      node.removeEventListener(Events.touchend, onTouchEnd);
      node.removeEventListener(Events.touchmove, onTouchMove);
      node.removeEventListener(Events.touchstart, onTouchStart);
    }
  };
};
