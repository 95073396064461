import React from 'react';
import { connect } from 'react-redux';
import ProfileView from '../../components/ProfileView';
import { getPlaylistInfo } from '../../api/playlists';
import { pause, play } from '../../reducers/audioPlayer';
import {
  setPageTitle,
  setPageData,
  showLoadingSpinner,
  hideLoadingSpinner
} from '../../reducers/view';
import { addToast } from '../../reducers/toasts';
import { playPlaylist } from '../../reducers/station';
import {
  toggleSaveStation,
  isItemSavedInApp,
  utilForUpdatingLibrary
} from '../../reducers/library';
import { s } from '../../lib/screen-size.js';
import PauseIcon from '../../img/button/pauseIcon.js';
import PlayIcon from '../../img/button/playIcon.js';
import PlusIcon from '../../img/button/plusIcon.js';
import { handleCardSelection } from '../../lib/utils';
import { updateLocationState } from '../../lib/reactv-redux/ReacTVReduxReducer';

class Playlist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'SCREEN:LOADING'
    };

    this.getRouteData = this.getRouteData.bind(this);
    this.getCollections = this.getCollections.bind(this);
    this.getPlaylists = this.getPlaylists.bind(this);
    this.getPlaylistTracks = this.getPlaylistTracks.bind(this);
    this.onPlayPause = this.onPlayPause.bind(this);
    this.onToggleFollow = this.onToggleFollow.bind(this);
    this.playlistCardOnEnter = this.playlistCardOnEnter.bind(this);
  }

  componentWillMount() {
    const {
      session: { sessionId }
    } = this.props;

    if (!sessionId) {
      this.props.history.replace('/');
    }

    this.getRouteData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.station.contentId !== this.props.station.contentId) {
      const { contentType, contentId } = this.props.station;
      this.setState({
        isCurrentPlayerStation:
          contentType === 'PLAYLIST' &&
          this.state.playlistData &&
          contentId === this.state.playlistData.id
      });
    }
  }

  componentDidMount() {
    this.props.showLoadingSpinner();
    this.unlisten = this.props.history.listen(() => {
      // NOTE: hide main navigation text, ' ' can be replaced with 'Loading...'
      this.props.setPageTitle(' ');
      this.props.showLoadingSpinner();

      this.setState(
        {
          view: 'SCREEN:LOADING',
          playlistData: null
        },
        () => this.getRouteData()
      );
    });
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
  }

  playlistCardOnEnter(card) {
    card._model.playlistData = this.state.playlistData;
    handleCardSelection.call(this, card);
  }

  prepareForProfileView(data, params) {
    const { artist, urls, name } = data;
    let thumbnail = (artist && artist.image) || (urls && urls.image);
    const description =
      (artist && artist.description) || data.description || name;
    const { userId: contentId, tracks } = data;
    const menusData = [];

    if (Array.isArray(tracks) === true && tracks.length > 0) {
      menusData.push({
        content: tracks,
        details: {
          label: 'Songs',
          menuid: `${params.idForGrid}-playlist-tracks`,
          dataContentType: 'SONG',
          constantWidth: true,
          isSquare: true
        },
        xCards: 10
      });
    }

    const infoCard = (
      <div className="profileView__infoSectionDescr">
        {description.length > 148
          ? `${description.slice(0, 145).trim()}...`
          : description}
      </div>
    );

    let imageParts = thumbnail && thumbnail.split('?');
    if (!imageParts || !imageParts.length) {
      thumbnail = '';
    }
    if (thumbnail.indexOf('content.iheart.com') === -1) {
      thumbnail = imageParts[0];
    } else {
      thumbnail = `https://i.iheart.com/v3/url/${btoa(imageParts[0])}`;
    }
    thumbnail = thumbnail + `?ops=gravity("center"),fit(214, 214)`;

    const view = {
      contentId: parseInt(contentId, 10),
      thumbnail,
      menusData,
      showDotsButton: false,
      infoCard,
      OFFSET_SCROLL: s(650),
      AMOUNT_TO_MOVE_SCROLL_BY: 560,
      AMOUNT_TO_MOVE_SCROLL_BETWEEN_MENUS: 150
    };

    const playlistData = { ...view, ...data, ...params };
    let itemStateInApp = isItemSavedInApp(
      this.props.library,
      'playlist',
      `${playlistData.userId}-${playlistData.id}`
    );
    utilForUpdatingLibrary(
      playlistData.followed,
      itemStateInApp,
      'playlist',
      `${playlistData.userId}-${playlistData.id}`
    );

    this.setState(
      {
        view: 'SCREEN:PROFILEVIEW',
        playlistData,
        isCurrentPlayerStation:
          this.props.station.contentType === 'PLAYLIST' &&
          playlistData &&
          this.props.station.contentId === playlistData.id
      },
      () => {
        if (this.props.menu && this.props.menu.appNavigation) {
          document
            .querySelector('.mainContainer')
            .scrollTo(this.props.menu.appNavigation.lastScrollValue || 0, 0);
        }
        this.props.setPageTitle(name);
        this.props.hideLoadingSpinner();
      }
    );
  }

  getRouteData() {
    const {
      match: {
        params: { playlistId, campaign, type, category }
      }
    } = this.props;

    // all collections view
    if (!playlistId) {
      return this.getCollections();
    }

    // collection view
    if (playlistId === 'collections') {
      return this.getPlaylists(campaign, type, category);
    }

    // playlist view
    return this.getPlaylistTracks(playlistId);
  }

  async getCollections() {
    // TODO: get all collections
    console.log('%cgetCollections', 'color:deeppink');
    this.setState({ view: 'SCREEN:MAINTENANCE' });
    this.props.hideLoadingSpinner();
  }

  async getPlaylists(campaign, type, category) {
    // TODO: get all collection playlists
    console.log('%cgetPlaylists', 'color:deeppink', {
      campaign,
      type,
      category
    });
    this.setState({ view: 'SCREEN:MAINTENANCE' });
    this.props.hideLoadingSpinner();
  }

  async getPlaylistTracks(playlistId) {
    const idForGrid = 'playlist';
    const { playlist } = this.props;
    let playlistData;
    if (
      playlist === undefined ||
      playlistId !== `${playlist.userId}-${playlist.id}`
    ) {
      const stationProfile = {
        userId: playlistId.split('-')[0],
        collectionId: playlistId.split('-')[1]
      };
      playlistData = await getPlaylistInfo(stationProfile);
      if (playlistData === false) {
        this.props.hideLoadingSpinner();
        return this.setState({
          view: 'SCREEN:ERROR'
        });
      }

      this.prepareForProfileView(playlistData, {
        idForGrid
      });
    }
  }

  onToggleFollow() {
    const { playlistData } = this.state;
    toggleSaveStation('playlist', `${playlistData.userId}-${playlistData.id}`);
  }

  async onPlayPause() {
    const { isCurrentPlayerStation } = this.state;
    const { DO_PLAY } = this.props.audioPlayer;
    if (isCurrentPlayerStation) {
      if (DO_PLAY) {
        this.props.pause();
      } else {
        this.props.play();
      }
    } else {
      this.props.playPlaylist(this.state.playlistData, 0);
    }
  }

  render() {
    const { view, playlistData, isCurrentPlayerStation } = this.state;
    const {
      library,
      audioPlayer: { DO_PLAY }
    } = this.props;
    if (view === 'SCREEN:LOADING') {
      return null;
    }

    if (!playlistData || !playlistData.menusData.length) {
      this.props.addToast({ type: 'empty-playlist' });
      return null;
    }

    if (view === 'SCREEN:MAINTENANCE') {
      return <div className="under-maintenance">Under maintenance!</div>;
    }

    if (view === 'SCREEN:ERROR' || playlistData === undefined) {
      return (
        <div className="error">An error occured, please try again later.</div>
      );
    }

    let isFollowed = isItemSavedInApp(
      library,
      'playlist',
      `${playlistData.userId}-${playlistData.id}`
    );

    let buttonsData = [
      {
        id: 'playBtn',
        icon: isCurrentPlayerStation && DO_PLAY ? PauseIcon : PlayIcon,
        text: isCurrentPlayerStation && DO_PLAY ? 'Pause' : 'Play'
      }
    ];

    if (playlistData.name !== 'Your Weekly Mixtape') {
      buttonsData.push({
        id: 'saveBtn',
        icon: PlusIcon,
        text: isFollowed ? 'Remove' : 'Follow',
        isFollowed
      });
    }

    return (
      <ProfileView
        onPlay={this.onPlayPause}
        onEnter={this.playlistCardOnEnter}
        onDown={this.props.onDown}
        onToggleSave={this.onToggleFollow}
        buttonsData={buttonsData}
        {...playlistData}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    locationStateHistory,
    session,
    appNavigation,
    library,
    station,
    audioPlayer
  } = state;

  return {
    menu: locationStateHistory[appNavigation.page],
    session,
    appNavigation,
    // currentFocus,
    library,
    station,
    audioPlayer
  };
};

export default connect(
  mapStateToProps,
  {
    updateLocationState,
    playPlaylist,
    setPageTitle,
    setPageData,
    showLoadingSpinner,
    hideLoadingSpinner,
    addToast,
    pause,
    play
  }
)(Playlist);
