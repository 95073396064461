import React from 'react';
import { s } from '../../lib/screen-size';

const DotIcon = ({ fill }) => (
  <svg
    width={s(8)}
    height={s(8)}
    viewBox="0 0 8 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Button/More/Default"
        transform="translate(-47.000000, -13.000000)"
        fill={fill ? fill : '#FFFFFF'}
      >
        <circle id="Oval-Copy-2" cx="51" cy="17" r="4" />
      </g>
    </g>
  </svg>
);

export default DotIcon;
