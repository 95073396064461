import React from 'react';
import { s } from '../../lib/screen-size';

const Icon = ({ fill }) => (
  <svg
    width={s(72)}
    height={s(72)}
    viewBox="0 0 72 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mini-Player/Podcast"
        transform="translate(-1133.000000, -60.000000)"
      >
        <g
          id="Button/Player/Default"
          transform="translate(1133.000000, 60.000000)"
        >
          <g
            id="Button/Player/Icon/Pause/Default"
            fill={fill ? fill : '#FFFFFF'}
            fillRule="nonzero"
          >
            <path
              d="M27.1076386,50 C29.3668398,50 31.2152772,48.2 31.2152772,46 L31.2152772,26 C31.2152772,23.8 29.3668398,22 27.1076386,22 C24.8484374,22 23,23.8 23,26 L23,46 C23,48.2 24.8484374,50 27.1076386,50 Z"
              id="Path"
            />
            <path
              d="M40,26 L40,46 C40,48.2 41.8484374,50 44.1076386,50 C46.3668398,50 48.2152772,48.2 48.2152772,46 L48.2152772,26 C48.2152772,23.8 46.3668398,22 44.1076386,22 C41.8484374,22 40,23.8 40,26 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
