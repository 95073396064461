import React from 'react';
import { getItemModel } from '../../lib/utils';
import ThreeRowCard from './ThreeRowCard';

export default props => {
  const newProps = Object.assign({}, props);
  if (!props.item.model) {
    newProps.item._model = getItemModel(props);
  }

  return <ThreeRowCard {...newProps} />;
};
