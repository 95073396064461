import axios from 'axios';
import { BASE_URL, routes } from '../constants';
import store from '../store';

const getRecentlyPlayed = async () => {
  const { sessionId, profileId } = store.getState().session;

  const recentlyPlayedPlaylistRoute = routes[
    'RECENTLY_PLAYED_PLAYLIST'
  ].replace('{profileId}', profileId);

  const recentlyPlayedPlaylistUrl = BASE_URL + recentlyPlayedPlaylistRoute;

  const recentlyPlayedPlaylistResponse = await axios({
    url: recentlyPlayedPlaylistUrl,
    params: {
      campaignId: 'playlist_collections',
      sortBy: 'LAST_PLAYED'
    },
    headers: { 'X-User-Id': profileId, 'X-Session-Id': sessionId }
  });

  return recentlyPlayedPlaylistResponse;
};

export { getRecentlyPlayed };
