import React, { Component } from 'react';
import cx from 'classnames';
import { getArtistProfile } from '../../api/artists';
import { getPodcastProfile } from '../../api/podcast';
import { connect } from 'react-redux';
import { updateFocus } from '../../reducers/appNavigation';
import { setPageTitle } from '../../reducers/view';
import { resetScrollablePanel } from '../../reducers/scrollablePanel';
import ScrollableTextPanel from '../../components/ScrollableTextPanel/ScrollableTextPanel';
import Button from '../../components/Button/Button';
import PlusIcon from '../../img/button/plusIcon.js';
import {
  checkItemIsInLibrary,
  isItemSavedInApp,
  toggleFollowPodcast,
  toggleSaveStation
} from '../../reducers/library.js';
import { trickPlayPause } from '../../reducers/audioPlayer.js';
import ReactvPlatforms from '../../lib/reactv-platforms/index';

import './ProfileDetails.scss';

class ProfileDetails extends Component {
  constructor(p) {
    super(p);
    this.state = {};
    this.focusOnSaveButton = this.focusOnSaveButton.bind(this);
    this.focusOnScrollableText = this.focusOnScrollableText.bind(this);
    this.onToggleSave = this.onToggleSave.bind(this);
  }

  async componentDidMount() {
    const { session, currentFocus, match, setPageTitle } = this.props;
    if (!session.sessionId) {
      this.props.history.replace('/');
    }

    setPageTitle(' '); // todo use value from artist/podcast page

    if (
      !currentFocus.includes('profileDetails') &&
      currentFocus !== 'container/miniPlayer'
    ) {
      this.props.updateFocus('profileDetails/content');
    }
    const { contentType, contentId } = match.params;
    const stationContentType = this.props.station.contentType;
    const stationSourceId = this.props.station.sourceId;
    const stationPodcastId =
      this.props.station.currentSong && this.props.station.currentSong.content
        ? this.props.station.currentSong.content.podcastId
        : null;
    let fetchedProfileData, isCurrentPlayerStation;

    if (contentType === 'podcast') {
      fetchedProfileData = await getPodcastProfile(contentId);
      fetchedProfileData.contentType = 'PODCAST';
      setPageTitle(fetchedProfileData.title);
      checkItemIsInLibrary({
        contentType: 'PODCAST',
        podcastId: fetchedProfileData.id
      });

      isCurrentPlayerStation =
        (stationContentType === 'PODCAST' ||
          stationContentType === 'PODCAST_EPISODE') &&
        fetchedProfileData.id === stationPodcastId;
    } else if (contentType === 'artist') {
      fetchedProfileData = await getArtistProfile(contentId);

      fetchedProfileData.contentType = 'ARTIST';
      setPageTitle(fetchedProfileData.artist.name);
      checkItemIsInLibrary({
        contentType: 'ARTIST',
        artistId: fetchedProfileData.artist.artistId
      });

      isCurrentPlayerStation =
        stationContentType === 'ARTIST' &&
        fetchedProfileData.artist.artistId === stationSourceId;
    }
    ReactvPlatforms.platform.tts(
      fetchedProfileData.bio || fetchedProfileData.description
    );

    this.setState({
      profileData: fetchedProfileData,
      isCurrentPlayerStation
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: { contentId, contentType }
      }
    } = this.props;

    if (
      prevProps &&
      this.props.station &&
      prevProps.station.sourceId !== this.props.station.sourceId
    ) {
      const { sourceId } = this.props.station;
      const stationContentType = this.props.station.contentType;
      const stationContentId = this.props.station.contentId;
      if (contentType === 'artist') {
        this.setState({
          isCurrentPlayerStation:
            stationContentType === 'ARTIST' && sourceId === contentId
        });
      } else if (contentType === 'podcast') {
        this.setState({
          isCurrentPlayerStation:
            (stationContentType === 'PODCAST' ||
              stationContentType === 'PODCAST_EPISODE') &&
            contentId === stationContentId
        });
      }
    }
  }

  async onToggleSave() {
    const { match } = this.props;
    const { contentType, contentId } = match.params;

    if (contentType === 'podcast') {
      toggleFollowPodcast(contentId);
    } else if (contentType === 'artist') {
      toggleSaveStation('artist', contentId);
    }
  }

  componentWillUnmount() {
    this.props.resetScrollablePanel();
    this.props.setPageTitle();
  }

  focusOnSaveButton() {
    const { updateFocus, scrollablePanel, resetScrollablePanel } = this.props;
    if (scrollablePanel.currentPage !== 0) {
      resetScrollablePanel();
    }
    updateFocus('profileDetails/content');
  }

  focusOnScrollableText() {
    const { scrollablePanel } = this.props;

    if (scrollablePanel.numberOfScrollablePages > 1) {
      this.props.updateFocus('profileDetails/scrollableText');
    }
  }

  isSaveButtonFocused() {
    return this.props.currentFocus === 'profileDetails/content';
  }

  isScrollableTextFocused() {
    return this.props.currentFocus === 'profileDetails/scrollableText';
  }

  render() {
    const { onDown, library, match } = this.props;
    const { profileData } = this.state;
    let isSaved = isItemSavedInApp(
      library,
      match.params.contentType,
      match.params.contentId
    );
    if (!profileData) {
      return null;
    }
    const removeText = 'Remove';
    const saveText = profileData.contentType === 'PODCAST' ? 'Follow' : 'Save';

    const { id, artist, bio, imageUrl, description } = profileData;
    const backgroundImage = imageUrl || artist.image;
    const thumbnail = /^http/.test(backgroundImage)
      ? backgroundImage
      : `http://i.iheart.com/v3/catalog/show/${id}`;
    const details = bio || description;

    return (
      <div className="profileDetails">
        <div
          className="profileDetails__background"
          style={{ backgroundImage: `url(${thumbnail})` }}
        />
        <div
          className="profileDetails__image"
          style={{ backgroundImage: `url(${thumbnail})` }}
        />
        <ScrollableTextPanel
          focused={this.isScrollableTextFocused()}
          hideGradient
          className="profileDetails__details"
          onEnter={this.props.trickPlayPause}
          onLeft={this.focusOnSaveButton}
          onDown={onDown}
        >
          <Button
            className={cx('profileDetails__button', {
              'profileDetails__button--isSaved': isSaved
            })}
            onEnter={this.onToggleSave}
            focused={this.isSaveButtonFocused()}
            onRight={this.focusOnScrollableText}
            onDown={onDown}
            label={isSaved ? removeText : saveText}
          >
            <PlusIcon fill={this.isSaveButtonFocused() ? '#000' : null} />
          </Button>
          <div className="profileDetails__detailsText">{details}</div>
        </ScrollableTextPanel>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { session, appNavigation, scrollablePanel, library, station } = state;
  return {
    session,
    currentFocus: appNavigation.currentFocus,
    scrollablePanel,
    library,
    station
  };
};

export default connect(
  mapStateToProps,
  {
    updateFocus,
    resetScrollablePanel,
    setPageTitle,
    trickPlayPause
  }
)(ProfileDetails);
