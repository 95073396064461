import React from 'react';

const Icon = ({ fill }) => (
  <svg
    width="24px"
    height="18px"
    viewBox="0 0 24 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Keyboard" transform="translate(-264.000000, -595.000000)">
        <g id="Buttons" transform="translate(108.000000, 580.000000)">
          <g id="Button/Backspace" transform="translate(144.000000, 0.000000)">
            <rect id="Rectangle-3" x="0" y="0" width="48" height="48" />
            <path
              d="M33.9827695,15 L18.8535407,15 C18.1575961,15 17.6129439,15.35 17.2498424,15.88 L12.1664215,23.45 C11.9445262,23.79 11.9445262,24.22 12.1664215,24.56 L17.2498424,32.12 C17.6129439,32.64 18.1575961,33 18.8535407,33 L33.9827695,33 C35.0922463,33 36,32.1 36,31 L36,17 C36,15.9 35.0922463,15 33.9827695,15 Z M30.250893,28.3 C29.8575331,28.69 29.2221055,28.69 28.8287455,28.3 L25.9138474,25.41 L22.9989494,28.3 C22.6055894,28.69 21.9701618,28.69 21.5768018,28.3 C21.1834419,27.91 21.1834419,27.28 21.5768018,26.89 L24.4916999,24 L21.5768018,21.11 C21.1834419,20.72 21.1834419,20.09 21.5768018,19.7 C21.9701618,19.31 22.6055894,19.31 22.9989494,19.7 L25.9138474,22.59 L28.8287455,19.7 C29.2221055,19.31 29.8575331,19.31 30.250893,19.7 C30.644253,20.09 30.644253,20.72 30.250893,21.11 L27.335995,24 L30.250893,26.89 C30.6341668,27.27 30.6341668,27.91 30.250893,28.3 Z"
              id="Shape"
              fill={fill ? fill : '#FFFFFF'}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
