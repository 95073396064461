import { combineReducers } from 'redux';
import cards from './cards';
import session from './session';
import audioPlayer from './audioPlayer';
import scrollablePanel from './scrollablePanel';
import appNavigation from './appNavigation';
import settings from './settings';
import view from './view';
import search from './search';
import taste from './taste';
import library from './library';
import station from './station';
import { ReactTVReduxReducer, MenusReducer } from '../lib/reactv-redux';
import toasts from './toasts';

export default combineReducers({
  locationStateHistory: ReactTVReduxReducer,
  navigation: MenusReducer,
  cards,
  session,
  audioPlayer,
  view,
  appNavigation,
  taste,
  settings,
  search,
  scrollablePanel,
  station,
  library,
  toasts
});
