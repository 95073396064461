export const UPDATE_SCROLL = 'UPDATE_SCROLL';

export function updateScrollablePanel(data) {
  return {
    type: UPDATE_SCROLL,
    data
  };
}

export function resetScrollablePanel() {
  return {
    type: UPDATE_SCROLL,
    data: {
      currentPage: 0,
      atBottomOfPanel: false
    }
  };
}

const initialState = {
  currentPage: 0,
  atBottomOfPanel: false,
  numberOfScrollablePages: 1
};

export default function scrollablePanelReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SCROLL:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
}
