import { forYou } from '../api/recommendations';
import {
  getLibraryCollectionTracks,
  getLibraryCollectionAlbums
} from '../api/collection';
import { getFollowingPodcasts } from '../api/podcast';
import {
  getLibraryLiveStations,
  getLibraryArtistStations
} from '../api/profile';
import { updateFocus } from './appNavigation';
import { setPlaylist } from './station';
import store from '../store';
import { search } from '../api/search';
import { prepareForSearch } from '../lib/utils';

export const PAGINATION_PAGE_SIZE = 20;

export const storeViewAll = (mid, data) => {
  const type = 'VIEW/STORE_VIEW_ALL_DATA';
  return { type, mid, data };
};

export const clearViewAll = mid => {
  const type = 'VIEW/CLEAR_VIEW_ALL_DATA';
  return { type, mid };
};

export const setPageTitle = title => {
  const type = 'SET_PAGE_TITLE';
  return { type, title };
};

export const setViewAllStatus = payload => {
  const type = 'SET_VIEW_ALL_STATUS';
  return { type, ...payload };
};

export const setPageData = (
  viewAllData,
  viewAllContentType,
  viewAllMenuId,
  viewAllSource,
  clearPaginationHistory
) => {
  const type = 'SET_PAGE_DATA';
  return {
    type,
    viewAllData,
    viewAllSource,
    viewAllContentType,
    viewAllMenuId,
    clearPaginationHistory
  };
};

export const setPageMenuData = viewAllData => {
  const type = 'SET_PAGE_MENU_DATA';
  return { type, viewAllData };
};

export const showLoadingSpinner = () => {
  return async function(dispatch, getState) {
    const type = 'SHOW_LOADING_SPINNER';
    dispatch({ type, showLoadingSpinner: true });
    dispatch(updateFocus('loadingSpinner'));
  };
};

export const hideLoadingSpinner = () => {
  return async function(dispatch, getState) {
    const type = 'HIDE_LOADING_SPINNER';
    dispatch({ type, showLoadingSpinner: false });
  };
};

export const setIsPageLoaded = (
  viewAllMenuId,
  pageNumber,
  pageValue,
  nextPageKey,
  loaded,
  isPlayerPagination
) => {
  const type = 'SET_IS_PAGE_LOADED';
  return {
    type,
    paginationPage: {
      viewAllMenuId,
      pageNumber,
      pageValue,
      nextPageKey,
      loaded,
      isPlayerPagination
    }
  };
};

export const setLoadedPageToDefault = page => {
  const type = 'SET_LOADED_PAGE_TO_DEFAULT';
  return {
    type,
    page
  };
};

const getPaginationAPICallForMenu = (
  id,
  pageSize,
  pageNumber,
  nextPageKey,
  isPlayerPagination
) => {
  if (!id) {
    console.warn('No id provided', id, pageSize, pageNumber, nextPageKey);
    return;
  }
  id = id.toLowerCase();
  switch (id) {
    case 'home-main-recommendations':
      return forYou(pageSize * (pageNumber - 1), PAGINATION_PAGE_SIZE).then(
        data => {
          return data.values;
        }
      );
    case 'library-tracks':
      return getLibraryCollectionTracks(
        PAGINATION_PAGE_SIZE,
        pageNumber,
        nextPageKey
      );
    case 'library-albums':
      return getLibraryCollectionAlbums(
        PAGINATION_PAGE_SIZE,
        pageNumber,
        nextPageKey
      );
    case 'library-podcasts':
      return getFollowingPodcasts(
        PAGINATION_PAGE_SIZE,
        pageNumber,
        nextPageKey
      );
    case 'library-live-radio':
      return getLibraryLiveStations(
        PAGINATION_PAGE_SIZE,
        pageNumber,
        nextPageKey
      );
    case 'library-artists':
      return getLibraryArtistStations(
        PAGINATION_PAGE_SIZE,
        pageNumber,
        nextPageKey
      );
    case 'search-tracks':
    case 'search-artists':
    case 'search-albums':
    case 'search-stations':
    case 'search-playlists':
    case 'search-podcasts':
      const state = store.getState();
      const searchType = id.split('-')[1];
      const { term } = state.search;
      const params = {
        maxRows: 20,
        startIndex: pageSize * (pageNumber - 1),
        track: searchType === 'tracks',
        artist: searchType === 'artists',
        bundle: searchType === 'albums',
        station: searchType === 'stations',
        playlist: searchType === 'playlists',
        podcast: searchType === 'podcasts'
      };
      return search(term, params).then(data => {
        const _results = prepareForSearch(data.results);
        return _results[searchType];
      });
    default:
      return false;
  }
};

export const loadCollectionPage = ({
  id,
  pagesId,
  pageSize,
  pageNumber,
  menu,
  menuItems,
  nextPageKey,
  shouldSetPlaylist
}) => {
  return async function(dispatch, getState) {
    dispatch(setIsPageLoaded(pagesId, pageNumber, true)); // immediately set to prevent repeated calls
    const apiPromise = getPaginationAPICallForMenu(
      pagesId,
      pageSize,
      pageNumber,
      nextPageKey,
      shouldSetPlaylist
    );
    if (!apiPromise) {
      return;
    } else {
      apiPromise
        .then(data => {
          if (!data) {
            console.warn('No page data received for', id, pageNumber);
            return;
          }
          if (pagesId === 'library-tracks') {
            let loaded = menuItems.length;
            data.forEach(item => {
              menuItems.push({ ...item, startFrom: loaded + item.startFrom });
            });
          } else {
            data.forEach(item => {
              menuItems.push(item);
            });
          }
          dispatch(storeViewAll(id, menuItems));
          dispatch(setPageMenuData(menuItems));
          if (shouldSetPlaylist) {
            dispatch(setPlaylist(menuItems));
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
};

export default function(state = {}, action) {
  const {
    mid,
    page,
    data,
    type,
    title,
    viewAllData,
    viewAllSource,
    viewAllContentType,
    viewAllMenuId,
    showLoadingSpinner,
    paginationPage,
    isEmpty,
    clearPaginationHistory
  } = action;
  let allData, newState, existingPages;
  switch (type) {
    case 'VIEW/STORE_VIEW_ALL_DATA':
      allData = Object.assign({}, state.all);
      allData[mid] = data;
      return Object.assign({}, state, { all: allData });
    case 'VIEW/CLEAR_VIEW_ALL_DATA':
      allData = Object.assign({}, state.all);
      if (mid) {
        allData[mid] = null;
      }
      return Object.assign({}, state, {
        all: allData,
        viewAllData: null,
        title: '',
        viewAllSource: null,
        viewAllContentType: null,
        viewAllMenuId: null
      });
    case 'SET_PAGE_TITLE':
      return { ...state, title };
    case 'SET_VIEW_ALL_STATUS':
      return { ...state, isEmpty };
    case 'SET_PAGE_DATA':
      const pagesFromPreviousLoad = state[viewAllMenuId];
      let trimmedPages;
      if (pagesFromPreviousLoad) {
        if (clearPaginationHistory) {
          trimmedPages = { '1': pagesFromPreviousLoad['1'] };
        } else {
          trimmedPages = { ...pagesFromPreviousLoad };
        }
      } else {
        trimmedPages = null;
      }
      return {
        ...state,
        viewAllData,
        viewAllSource,
        viewAllContentType,
        viewAllMenuId,
        [viewAllMenuId]: trimmedPages
      };
    case 'SET_PAGE_MENU_DATA':
      return { ...state, viewAllData };
    case 'SHOW_LOADING_SPINNER':
      return { ...state, showLoadingSpinner };
    case 'HIDE_LOADING_SPINNER':
      return { ...state, showLoadingSpinner };
    case 'SET_IS_PAGE_LOADED':
      existingPages = state[paginationPage.viewAllMenuId];
      if (existingPages) {
        newState = existingPages;
      } else {
        newState = {};
      }
      newState[paginationPage.pageNumber] = {
        value: paginationPage.pageValue,
        nextPageKey: paginationPage.nextPageKey,
        loaded: paginationPage.loaded,
        isPlayerPagination: paginationPage.isPlayerPagination
      };
      return {
        ...state,
        [paginationPage.viewAllMenuId]: newState
      };
    case 'SET_LOADED_PAGE_TO_DEFAULT':
      newState = { ...state };
      existingPages = state[page];
      if (existingPages) {
        Object.keys(existingPages).forEach((item, idx) => {
          if (idx < 1) {
            newState[page] = {
              '1': existingPages[item]
            };
          }
        });
      }

      return newState;
    default:
      return state;
  }
}
