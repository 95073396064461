import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MenuItem from '../../components/MenuItem/MenuItem';
import ScrollableTextPanel from '../../components/ScrollableTextPanel/ScrollableTextPanel';
import ListMenu from '../../lib/reactv-navigation/components/ListMenu';
import SettingsAccountPanel from './SettingsAccountPanel';
import { termsHTML } from './termsHTMLSnippet.js';
import { privacyHTML } from './privacyHTMLSnippet.js';
import { aboutHTML } from './aboutHTMLSnippet.js';
import { updateFocus } from '../../reducers/appNavigation';
import { resetSearch } from '../../reducers/search';
import { setUserGenres } from '../../reducers/taste';
import { addToast } from '../../reducers/toasts';
import { updateSettings } from '../../reducers/settings';
import { updateCardPiles, clearCardPiles } from '../../reducers/cards';
import { setUserProfile } from '../../reducers/session';
import { resetPlayer, pause, trickPlayPause } from '../../reducers/audioPlayer';
import { savePreferences } from '../../api/profile';
import { forYou } from '../../api/recommendations';
import { resetScrollablePanel } from '../../reducers/scrollablePanel';
import { done, clearStationData } from '../../reducers/station';
import { getItemModel } from '../../lib/utils';

import './Settings.scss';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderCurrentPage = this.renderCurrentPage.bind(this);
    this.selectSettingsMenuItem = this.selectSettingsMenuItem.bind(this);
    this.focusOnSettingsMenu = this.focusOnSettingsMenu.bind(this);
    this.focusOnSettingsDetails = this.focusOnSettingsDetails.bind(this);
    this.props.updateFocus('settings/content');
  }

  async componentDidMount() {
    const { hero, updateCardPiles } = this.props;

    const { values: recommendations } = await forYou();

    const cardPiles = [];

    if (!hero) {
      cardPiles.push({
        key: 'hero',
        data: this.getRandomHero(recommendations)
      });
      updateCardPiles(cardPiles);
    }
  }

  getRandomHero(recommendations = []) {
    const lastHeroId = localStorage.getItem('ade:ihr:last-hero-id');
    const possibleHeros =
      recommendations.filter(
        rec => rec.subType === 'ARTIST' || rec.subType === 'LIVE'
      ) || [];

    const getRandomInt = max => {
      return Math.floor(Math.random() * Math.floor(max));
    };

    let indForHero = getRandomInt(possibleHeros.length - 1);
    let newHero = possibleHeros[indForHero];

    if (!newHero) return;

    if (newHero.content.id === Number(lastHeroId)) {
      indForHero = getRandomInt(possibleHeros.length - 1);
      newHero = possibleHeros[indForHero];
    }
    localStorage.setItem('ade:ihr:last-hero-id', newHero.content.id);

    if (newHero && newHero.subType !== 'ARTIST' && possibleHeros.length > 0) {
      let possibleArtists = possibleHeros.filter(
        rec => rec.subType === 'ARTIST'
      );

      let indForRandomImage = getRandomInt(possibleArtists.length - 1);

      newHero.randomArtistImage =
        possibleArtists[indForRandomImage] &&
        possibleArtists[indForRandomImage].imagePath;
    }

    if (newHero) {
      newHero.content._model = getItemModel({ item: newHero.content });
    }

    return newHero;
  }

  renderCurrentPage() {
    const {
      page,
      onDown,
      onUp,
      resetPlayer,
      done,
      pause,
      resetSearch,
      clearCardPiles,
      clearStationData,
      setUserGenres
    } = this.props;
    const isAnonymousUser =
      this.props.session.userProfile.accountType === 'ANONYMOUS';

    switch (page) {
      case 'terms-of-use':
        return (
          <ScrollableTextPanel
            focused={this.isSettingsDetailsFocused()}
            onLeft={this.focusOnSettingsMenu}
            onEnter={this.props.trickPlayPause}
            onUp={onUp}
            onDown={onDown}
            innerHTML={termsHTML}
          />
        );

      case 'privacy-policy':
        return (
          <ScrollableTextPanel
            focused={this.isSettingsDetailsFocused()}
            onLeft={this.focusOnSettingsMenu}
            onEnter={this.props.trickPlayPause}
            onUp={onUp}
            onDown={onDown}
            innerHTML={privacyHTML}
          />
        );

      case 'about':
        return (
          <ScrollableTextPanel
            focused={this.isSettingsDetailsFocused()}
            onLeft={this.focusOnSettingsMenu}
            onEnter={this.props.trickPlayPause}
            onUp={onUp}
            onDown={onDown}
            innerHTML={
              aboutHTML +
              'App version: ' +
              process.env.REACT_APP_VERSION +
              (process.env.REACT_APP_COMMIT_REF
                ? '.' + process.env.REACT_APP_COMMIT_REF.substr(0, 7)
                : '') +
              '<br/><br/><br/><br/>'
            }
          />
        );

      default:
        return (
          <SettingsAccountPanel
            setUserGenres={setUserGenres}
            clearStationData={clearStationData}
            resetSearch={resetSearch}
            clearCardPiles={clearCardPiles}
            setUserProfile={setUserProfile}
            resetPlayer={resetPlayer}
            pause={pause}
            done={done}
            isAnonymousUser={isAnonymousUser}
            userSession={this.props.session}
            focused={this.isSettingsDetailsFocused()}
            onLeft={this.focusOnSettingsMenu}
            onUp={onUp}
            onDown={onDown}
            addToast={this.props.addToast}
          />
        );
    }
  }

  selectSettingsMenuItem(item) {
    const {
      updateSettings,
      resetScrollablePanel,
      scrollablePanel
    } = this.props;
    if (item.id === 'autoplay') {
      savePreferences('play.on.startup', item.value === true ? '0' : '1');
    } else {
      updateSettings({ currentListFocus: item.ind, page: item.id });

      if (scrollablePanel.currentPage !== 0) {
        resetScrollablePanel();
      }
    }
  }

  focusOnSettingsDetails() {
    this.props.updateFocus('settings/details');
  }

  focusOnSettingsMenu() {
    this.props.updateFocus('settings/content');
  }

  isSettingsDetailsFocused() {
    return this.props.currentFocus === 'settings/details';
  }

  isSettingsMenuFocused() {
    return this.props.currentFocus === 'settings/content';
  }

  render() {
    const { currentListFocus, page, session, hero } = this.props;
    const isAnonymousUser = session.userProfile.accountType === 'ANONYMOUS';
    const { preferences } = session.userProfile;
    let menuItems = [
      {
        id: 'manage-account',
        title: 'Manage Account',
        kind: 'settingsItem',
        ind: 0,
        active: page === 'manage-account'
      },
      {
        id: 'autoplay',
        title: 'Auto Play',
        switcher: true,
        value: preferences && preferences['play.on.startup'] === '1',
        kind: 'settingsItem',
        ind: 2,
        active: page === 'autoplay'
      }
    ];

    if (process.env.REACT_APP_TOUCH !== 'true') {
      menuItems = [
        ...menuItems,
        {
          id: 'terms-of-use',
          title: 'Terms of Use',
          kind: 'settingsItem',
          ind: 3,
          active: page === 'terms-of-use'
        },
        {
          id: 'privacy-policy',
          title: 'Privacy Policy',
          kind: 'settingsItem',
          ind: 4,
          active: page === 'privacy-policy'
        },
        {
          id: 'about',
          title: 'About',
          kind: 'settingsItem',
          ind: 5,
          active: page === 'about'
        }
      ];
    }

    let heroImage = hero && hero.subType === 'ARTIST' && hero.imagePath;

    if (!heroImage && hero && hero.randomArtistImage) {
      heroImage = hero.randomArtistImage;
    }

    return (
      <div className="settings">
        {isAnonymousUser && page === 'manage-account' && (
          <div
            className="settings__background"
            style={{
              backgroundImage: `url(${heroImage})`
            }}
          >
            <div className="settings__gradient settings__gradient--left" />
          </div>
        )}
        <ListMenu
          data={menuItems}
          renderItem={MenuItem}
          className="settings__leftMenu"
          vertical
          focused={this.isSettingsMenuFocused()}
          onUp={this.props.onUp}
          onRight={this.focusOnSettingsDetails}
          onEnter={this.selectSettingsMenuItem}
          onDown={this.props.onDown}
          onFocusIndex={currentListFocus}
        />
        <div className="settings__details">{this.renderCurrentPage()}</div>
        {process.env.REACT_APP_TOUCH === 'true' && (
          <div className="settings__version">
            App Version {process.env.REACT_APP_VERSION}
          </div>
        )}
      </div>
    );
  }
}

Settings.propTypes = {
  onUp: PropTypes.func
};

const mapStateToProps = state => {
  const { appNavigation, settings, scrollablePanel } = state;

  return {
    currentFocus: appNavigation.currentFocus,
    currentListFocus: settings.currentListFocus,
    page: settings.page,
    scrollablePanel,
    session: state.session,
    hero: state.cards.hero
  };
};

export default connect(
  mapStateToProps,
  {
    resetSearch,
    updateFocus,
    updateSettings,
    resetScrollablePanel,
    setUserProfile,
    resetPlayer,
    done,
    clearStationData,
    trickPlayPause,
    pause,
    addToast,
    updateCardPiles,
    clearCardPiles,
    setUserGenres
  }
)(Settings);
